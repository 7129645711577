import { useEffect, useState } from 'react';

import WMSLayer from '@arcgis/core/layers/WMSLayer';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';

import { NotificationInstance } from 'antd/lib/notification/interface';
import { useTranslation } from 'react-i18next';

import {
    addLayerSettings,
    getAllGeoserver,
    getOWSData,
    getSettings,
    removeAllLayerSettings,
} from '../../services';
import { GeoserverType, LayerGroupsType, LayerItemType } from '../../types';
import { goToLayer, noficationStatusLoadLayer } from '../../utils/WebMapUtils';

export const useLayers = (
    map: ArcGISMap,
    view: MapView,
    notificationInstance: NotificationInstance,
) => {
    const { t } = useTranslation();
    const [layers, setLayers] = useState<LayerGroupsType[]>([]);
    const [geoServers, setGeoServers] = useState<GeoserverType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [checkedLayers, setCheckedLayers] = useState<Set<string>>(new Set());

    const changeLayer = (item: LayerItemType) => {
        if (item.checked) {
            addLayer(item);
            return;
        }
        removeLayer(item);
    };

    const addLayer = (
        item: LayerItemType,
        config?: {
            style?: string;
            dimension?: string;
            notification?: boolean;
            onLoad?: () => void;
        },
    ): WMSLayer | undefined => {
        const dimension =
            config?.dimension ??
            item.dimension?.values[item.dimension.values.length - 1];
        const layerID = dimension ? `${item.layer}_${dimension}` : item.layer;

        const wmsLayer = new WMSLayer({
            url: item.url,
            id: layerID,
            title: item.title,
            imageFormat: 'image/png',
            sublayers: [
                {
                    name: item.layer,
                },
            ],
            customParameters: {
                styles: config?.style,
                time: dimension,
                TILED: true,
            },
        });

        wmsLayer.load().then(function () {
            setTimeout(() => {
                goToLayer(view, item.boundingBoxes);
                config?.onLoad?.();
            }, 3000);
        });

        if (config?.notification ?? true) {
            noficationStatusLoadLayer(wmsLayer, notificationInstance, t);
        }

        map.add(wmsLayer);
        checkedLayers.add(item.title);
        setCheckedLayers(new Set(checkedLayers));
        addLayerSettings({ ...item, checked: true });
        return wmsLayer;
    };

    const removeLayersByBaseID = (
        baseLayerID: string,
        excludeLayerID?: string,
    ) => {
        const layerIDsToRemove = new Set<string>();
        map.layers.forEach((layer) => {
            if (
                layer.id.startsWith(`${baseLayerID}_`) ||
                layer.id === baseLayerID
            ) {
                if (layer.id !== excludeLayerID) {
                    layerIDsToRemove.add(layer.id);
                }
            }
        });

        layerIDsToRemove.forEach((layerID) => {
            const layer = map.layers?.find((layer) => layer.id === layerID);
            if (layer) {
                map.layers
                    .filter((layer) => layer.id == layerID)
                    .forEach((layer) => map.layers.remove(layer));
                if (excludeLayerID == undefined) {
                    checkedLayers.delete(layer.title);
                }
            }
        });

        setCheckedLayers(new Set(checkedLayers));
    };

    const removeLayer = (
        item: LayerItemType,
        config?: { notification?: boolean; dimension?: string },
    ) => {
        const dimension =
            config?.dimension ??
            item.dimension?.values[item.dimension.values.length - 1];
        const layerID = dimension ? `${item.layer}_${dimension}` : item.layer;

        removeLayersByBaseID(
            item.layer,
            config?.dimension ? layerID : undefined,
        );

        if (config?.notification ?? true) {
            notificationInstance.success({
                message: t('layerWidget.notifications.deleteLayer.title'),
                description: t(
                    'layerWidget.notifications.deleteLayer.description',
                ),
            });
        }

        addLayerSettings({ ...item, checked: false });
    };

    const loadSettings = () => {
        const settings = getSettings();
        const layersSettings = settings?.layer_map?.items_selected;
        if (layersSettings) {
            layersSettings.forEach((layerSettings) => {
                changeLayer(layerSettings);
            });
        }
    };

    const loadOWS = (urlSource: string) => {
        setLoading(true);
        setLayers([]);
        getOWSData({ url: urlSource })
            .then((data) => {
                setLayers(data);
            })
            .catch(() => {
                notificationInstance.error({
                    message: t(
                        'layerWidget.notifications.failedLoadGeoServer.title',
                    ),
                    description: t(
                        'layerWidget.notifications.failedLoadGeoServer.description',
                    ),
                });
            })
            .finally(() => setLoading(false));
    };

    const loadGeoServers = () => {
        setLoading(true);
        getAllGeoserver()
            .then((data) => setGeoServers(data))
            .catch(() =>
                notificationInstance.error({
                    message: t(
                        'layerWidget.notifications.failedLoadGeoServer.title',
                    ),
                    description: t(
                        'layerWidget.notifications.failedLoadGeoServer.description',
                    ),
                }),
            )
            .finally(() => setLoading(false));
    };

    const changeStyleLayer = (item: LayerItemType, style: string) => {
        const layer = map.layers?.find((layer) => layer.id === item.layer);
        if (layer) {
            removeLayer(item, { notification: false });
            addLayer(item, {
                style: style,
                notification: false,
            });
        }
    };

    const changeDimensionLayer = (item: LayerItemType, dimension: string) => {
        addLayer(item, {
            dimension: dimension,
            notification: false,
            onLoad: () => {
                removeLayer(item, {
                    notification: false,
                    dimension: dimension,
                });
            },
        });
    };

    const clearAllLayers = () => {
        if (map) {
            removeAllLayerSettings();
            map.layers.removeAll();
            setCheckedLayers(new Set());
        }
    };

    useEffect(() => {
        if (map) {
            loadSettings();
        }
    }, [map]);

    useEffect(() => {
        loadGeoServers();
    }, []);

    return {
        layers,
        setLayers,
        geoServers,
        loading,
        checkedLayers,
        loadOWS,
        addLayer,
        removeLayer,
        clearAllLayers,
        changeStyleLayer,
        changeDimensionLayer,
        changeLayer,
        removeLayersByBaseID,
    };
};
