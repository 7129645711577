import { createSlice } from '@reduxjs/toolkit';

interface InitialInterface {
    area_coordinates: number[][] | null;
    area_geojson: string[] | null;
    area_img: string | null;
    area_stage: number;
    area_option: number;
    area_drawing: boolean;
    area_updating: boolean;
    area_reseted: boolean;
}

const initialState: InitialInterface = {
    area_coordinates: null,
    area_geojson: null,
    area_img: null,
    area_stage: 0,
    area_option: 0,
    area_drawing: false,
    area_updating: false,
    area_reseted: false,
};

const areaSlice = createSlice({
    name: 'areas',
    initialState,
    reducers: {
        addArea: (state, action) => {
            state.area_coordinates = action.payload.area_coordinates;
        },
        addImg: (state, action) => {
            state.area_img = action.payload.area_img;
        },
        increaseStage: (state, action) => {
            state.area_stage = state.area_stage + action.payload.area_stage;
        },
        decreaseStage: (state, action) => {
            if (!state.area_stage) return;

            const result = state.area_stage - action.payload.area_stage;

            if (result < 0) return;

            state.area_stage = result;
        },
        resetAreaCreation: (state) => {
            state.area_stage = 0;
            state.area_option = 0;
            state.area_geojson = null;
        },
        changeOption: (state, action) => {
            state.area_option = action.payload.area_option;
        },
        changeDrawing: (state, action) => {
            state.area_drawing = action.payload.area_drawing;
        },
        changeUpdating: (state, action) => {
            state.area_updating = action.payload.area_updating;
        },
        addGeoJson: (state, action) => {
            state.area_geojson = action.payload.area_geojson;
        },
        resetDraw: (state) => {
            state.area_geojson = null;
            state.area_drawing = false;
            state.area_updating = false;
            state.area_reseted = true;
            state.area_coordinates = null;
        },
        changeResetState: (state) => {
            state.area_reseted = false;
        },
        clearArea: (state) => {
            state.area_coordinates = null;
            state.area_geojson = null;
            state.area_img = null;
            state.area_stage = 0;
            state.area_option = 0;
            state.area_drawing = false;
            state.area_updating = false;
            state.area_reseted = true;
        },
    },
});

export const {
    addArea,
    increaseStage,
    decreaseStage,
    resetAreaCreation,
    changeOption,
    changeDrawing,
    changeUpdating,
    addGeoJson,
    resetDraw,
    changeResetState,
    addImg,
    clearArea,
} = areaSlice.actions;

export default areaSlice.reducer;
