import { createSlice } from '@reduxjs/toolkit';

interface InitialInterface {
    reportBase64: string | null;
    reportId: number | null;
}

const initialState: InitialInterface = {
    reportBase64: null,
    reportId: null,
};

const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        addReport: (state, action) => {
            state.reportBase64 = action.payload.reportBase64;
        },
        changeReportId: (state, action) => {
            state.reportId = action.payload.id;
        },
    },
});

export const { addReport, changeReportId } = reportSlice.actions;

export default reportSlice.reducer;
