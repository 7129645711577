import React, { useEffect, useState } from 'react';

import Measurement from '@arcgis/core/widgets/Measurement';

import {
    ClearOutlined,
    ColumnWidthOutlined,
    GatewayOutlined,
} from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { SystemOrLengthUnit, SystemOrAreaUnit } from '@arcgis/core/geometry/support';


//
// import SystemOrLengthUnit = __esri.SystemOrLengthUnit;
// import SystemOrAreaUnit = __esri.SystemOrAreaUnit;

interface IProps {
    measurement: Measurement;
}

interface IMeasurementUnit {
    label: string;
    value: string;
}

type MeasurementType = 'distance' | 'area';

const MeasurementWidget = (props: IProps) => {
    const { t } = useTranslation();
    const [type, setType] = useState<MeasurementType>();
    const [measurementUnit, setMeasurementUnit] = useState<IMeasurementUnit>();

    const measurementUnitsDistance: IMeasurementUnit[] = [
        {
            value: 'metric',
            label: 'measurementWidget.unitsDistance.metric',
        },
        {
            value: 'imperial',
            label: 'measurementWidget.unitsDistance.imperial',
        },
        {
            value: 'inches',
            label: 'measurementWidget.unitsDistance.inches',
        },
        {
            value: 'feet',
            label: 'measurementWidget.unitsDistance.feet',
        },
        {
            value: 'yards',
            label: 'measurementWidget.unitsDistance.yards',
        },
        {
            value: 'miles',
            label: 'measurementWidget.unitsDistance.miles',
        },
        {
            value: 'nautical-miles',
            label: 'measurementWidget.unitsDistance.nauticalMiles',
        },
        {
            value: 'us-feet',
            label: 'measurementWidget.unitsDistance.usFeet',
        },
        {
            value: 'meters',
            label: 'measurementWidget.unitsDistance.meters',
        },
        {
            value: 'kilometers',
            label: 'measurementWidget.unitsDistance.kilometers',
        },
    ];

    const measurementUnitsArea: IMeasurementUnit[] = [
        {
            value: 'metric',
            label: 'measurementWidget.unitsArea.metric',
        },
        {
            value: 'imperial',
            label: 'measurementWidget.unitsArea.imperial',
        },
        {
            value: 'square-inches',
            label: 'measurementWidget.unitsArea.squareInches',
        },
        {
            value: 'square-feet',
            label: 'measurementWidget.unitsArea.squareFeet',
        },
        {
            value: 'square-yards',
            label: 'measurementWidget.unitsArea.squareYards',
        },
        {
            value: 'square-miles',
            label: 'measurementWidget.unitsArea.squareMiles',
        },
        {
            value: 'square-us-feet',
            label: 'measurementWidget.unitsArea.squareUsFeet',
        },
        {
            value: 'square-meters',
            label: 'measurementWidget.unitsArea.squareMeters',
        },
        {
            value: 'square-kilometers',
            label: 'measurementWidget.unitsArea.squareKilometers',
        },
        {
            value: 'acres',
            label: 'measurementWidget.unitsArea.acres',
        },
        {
            value: 'ares',
            label: 'measurementWidget.unitsArea.ares',
        },
        {
            value: 'hectares',
            label: 'measurementWidget.unitsArea.hectares',
        },
    ];

    const handleDistance = () => {
        setType('distance');
        props.measurement.activeTool = 'distance';
    };

    const handleArea = () => {
        setType('area');
        props.measurement.activeTool = 'area';
    };

    const handleMeasurementUnit = (value?: string) => {
        const unit = (
            type === 'distance'
                ? measurementUnitsDistance
                : measurementUnitsArea
        ).find((item) => item.value === value);
        setMeasurementUnit(unit);
        if (unit) {
            if (type === 'area') {
                props.measurement.areaUnit = unit.value as SystemOrAreaUnit;
            } else {
                props.measurement.linearUnit = unit.value as SystemOrLengthUnit;
            }
        }
    };

    const handleClear = () => {
        setMeasurementUnit(undefined);
        setType(undefined);
        props.measurement.clear();
    };

    useEffect(() => {
        setMeasurementUnit(undefined);
    }, [type]);

    return (
        <section id="measurementWidget">
            <Col style={{ padding: '24px' }}>
                <Col span={24}>
                    <span>{t('measurementWidget.title')}</span>
                </Col>
                <br />
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <button
                            className={
                                type === 'distance' ? 'button-green' : 'button'
                            }
                            style={{ width: '100%' }}
                            onClick={handleDistance}
                        >
                            <ColumnWidthOutlined
                                style={{ marginRight: '8px' }}
                            />
                            {t('measurementWidget.measureDistance')}
                        </button>
                    </Col>
                    <Col span={12}>
                        <button
                            className={
                                type === 'area' ? 'button-green' : 'button'
                            }
                            style={{ width: '100%' }}
                            onClick={handleArea}
                        >
                            <GatewayOutlined style={{ marginRight: '8px' }} />
                            {t('measurementWidget.measureArea')}
                        </button>
                    </Col>
                </Row>
            </Col>
            <Divider style={{ padding: 0, margin: 0 }} />
            {type && (
                <>
                    <Col style={{ padding: 24 }}>
                        <Col>
                            <span>
                                {t('measurementWidget.unitSelection.title')}
                            </span>
                        </Col>
                        <br />
                        <Col>
                            <select
                                style={{ width: '100%' }}
                                // TODO: Add placeholder
                                // placeholder={t(
                                //     'measurementWidget.unitSelection.placeholder',
                                // )}
                                value={measurementUnit?.value}
                                onChange={({ target }) =>
                                    handleMeasurementUnit(target.value)
                                }
                            >
                                {(type === 'distance'
                                    ? measurementUnitsDistance
                                    : measurementUnitsArea
                                ).map((unit) => (
                                    <option value={unit.value} key={unit.value}>
                                        {t(unit.label)}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Col>
                    <Divider style={{ padding: 0, margin: 0 }} />
                </>
            )}
            <Col span={24} style={{ padding: 24 }}>
                <button
                    style={{ width: '100%' }}
                    className="button"
                    onClick={handleClear}
                >
                    <ClearOutlined style={{ marginRight: '8px' }} />{' '}
                    {t('measurementWidget.clear')}
                </button>
            </Col>
        </section>
    );
};

export default MeasurementWidget;
