import './CreateAreaComponent.scss';

import { useEffect } from 'react';

import { RootState } from '../../config/redux/store';
import { useDispatch, useSelector } from 'react-redux';

import MapMiddleware from '../../middleware/MapMiddleware';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    resetDraw,
    resetAreaCreation,
    clearArea,
} from '../../config/redux/area/slice';
import { clearMission } from '../../config/redux/mission/slice';
import WebMap from '../WebMapComponent/WebMapComponent';
import AreaComponent from './AreaComponent/AreaComponent';
import ConfigComponent from './ConfigComponent/ConfigComponent';
import ParamsComponent from './ParamsComponent/ParamsComponent';

const CreateArea = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { area_stage } = useSelector((state: RootState) => state.area);

    const handleResetDraw = () => {
        dispatch(resetDraw());
    };

    useEffect(() => {
        dispatch(clearArea());
        dispatch(clearMission());
    }, []);

    return (
        <section id="createAreaComponent">
            <div id="createAreaComponent__Menu" className="scroll">
                <div id="createAreaComponent__Content">
                    <header>
                        <h1>
                            <Link
                                onClick={() => {
                                    handleResetDraw();
                                    dispatch(resetAreaCreation());
                                }}
                                to="/"
                            >
                                <ArrowLeftOutlined />
                            </Link>
                            {t('createAreaComponent.title')}
                        </h1>
                    </header>

                    <br className="space" />

                    <main>
                        <Steps
                            current={area_stage || 0}
                            labelPlacement="vertical"
                            items={[
                                {
                                    title: t(
                                        'createAreaComponent.steps.generalInformation',
                                    ),
                                    description: '',
                                },
                                {
                                    title: t(
                                        'createAreaComponent.steps.areaLocation',
                                    ),
                                    description: '',
                                },
                                {
                                    title: t(
                                        'createAreaComponent.steps.settings',
                                    ),
                                    description: '',
                                },
                            ]}
                        />

                        <br className="space" />

                        {area_stage == 0 && <AreaComponent />}
                        {area_stage == 1 && <ParamsComponent />}
                        {area_stage == 2 && <ConfigComponent />}
                    </main>
                </div>
            </div>
            <WebMap toolbar={false} />
        </section>
    );
};

export default CreateArea;
