import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLanguage } from '../services/localStorageService';
import translaions from './locales';

const i18nConfig = {
    resources: translaions,
    lng: getLanguage(),
    fallbackLng: 'pt-BR',
    debug: true,
    defaultNS: 'translation',
};

export default i18n.use(initReactI18next).init(i18nConfig);
