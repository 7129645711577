import { configureStore } from '@reduxjs/toolkit';

import areaReducer from './area/slice';
import missionReducer from './mission/slice';
import modalReducer from './modal/slice';
import projectReducer from './project/slice';
import reportReducer from './report/slice';
import userReducer from './user/slice';

// @ts-ignore
export const store = configureStore({
    reducer: {
        user: userReducer,
        area: areaReducer,
        report: reportReducer,
        modal: modalReducer,
        mission: missionReducer,
        project: projectReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
