import './Menu.scss';

import { RootState } from '../../config/redux/store';
import { useSelector } from 'react-redux';

import {
    AppstoreFilled,
    BellFilled,
    EnvironmentFilled,
} from '@ant-design/icons';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getVersion } from '../../utils/Utils';
import Vector from '../HeaderComponent/VectorComponent/Vector';

import { TbDrone } from 'react-icons/tb';

const Menu = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleRedirectTo = (goTo: string) => {
        navigate(goTo);
    };

    const [api, contextHolder] = notification.useNotification();

    const frontendVersion = getVersion();
    const { version } = useSelector((state: RootState) => state.project);

    const handleMessage = (
        message: string,
        description: string,
        type: 'info' | 'success' | 'error',
    ): void => {
        api[type]({
            message: message,
            description: description,
        });
    };

    return window.location.pathname == '/not-found' ? (
        <></>
    ) : (
        <div id="menuComponent">
            {contextHolder}
            <button
                onClick={() => {
                    handleRedirectTo('/');
                }}
                className="no-hover"
            >
                <AppstoreFilled />
                <p>{t('menuComponent.menu')}</p>
            </button>

            <button
                onClick={() => {
                    handleRedirectTo('/map');
                }}
                className="no-hover"
            >
                <EnvironmentFilled />
                <p>{t('menuComponent.map')}</p>
            </button>

            <button
                onClick={() => {
                    handleMessage(
                        'Notificações',
                        t('menuComponent.functionNotAvailable'),
                        'info',
                    );
                }}
                className="no-hover"
            >
                <BellFilled />
                <p>{t('menuComponent.notifications')}</p>
            </button>

            <button
                onClick={() => {
                    handleRedirectTo('/history');
                }}
                className="no-hover"
            >
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <mask
                            id="mask0_78_3696"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                        >
                            <rect width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_78_3696)">
                            <path
                                d="M19 21C18.35 21 17.7667 20.8125 17.25 20.4375C16.7333 20.0625 16.375 19.5833 16.175 19H11C9.9 19 8.95833 18.6083 8.175 17.825C7.39167 17.0417 7 16.1 7 15C7 13.9 7.39167 12.9583 8.175 12.175C8.95833 11.3917 9.9 11 11 11H13C13.55 11 14.0208 10.8042 14.4125 10.4125C14.8042 10.0208 15 9.55 15 9C15 8.45 14.8042 7.97917 14.4125 7.5875C14.0208 7.19583 13.55 7 13 7H7.825C7.60833 7.58333 7.24583 8.0625 6.7375 8.4375C6.22917 8.8125 5.65 9 5 9C4.16667 9 3.45833 8.70833 2.875 8.125C2.29167 7.54167 2 6.83333 2 6C2 5.16667 2.29167 4.45833 2.875 3.875C3.45833 3.29167 4.16667 3 5 3C5.65 3 6.22917 3.1875 6.7375 3.5625C7.24583 3.9375 7.60833 4.41667 7.825 5H13C14.1 5 15.0417 5.39167 15.825 6.175C16.6083 6.95833 17 7.9 17 9C17 10.1 16.6083 11.0417 15.825 11.825C15.0417 12.6083 14.1 13 13 13H11C10.45 13 9.97917 13.1958 9.5875 13.5875C9.19583 13.9792 9 14.45 9 15C9 15.55 9.19583 16.0208 9.5875 16.4125C9.97917 16.8042 10.45 17 11 17H16.175C16.3917 16.4167 16.7542 15.9375 17.2625 15.5625C17.7708 15.1875 18.35 15 19 15C19.8333 15 20.5417 15.2917 21.125 15.875C21.7083 16.4583 22 17.1667 22 18C22 18.8333 21.7083 19.5417 21.125 20.125C20.5417 20.7083 19.8333 21 19 21ZM5 7C5.28333 7 5.52083 6.90417 5.7125 6.7125C5.90417 6.52083 6 6.28333 6 6C6 5.71667 5.90417 5.47917 5.7125 5.2875C5.52083 5.09583 5.28333 5 5 5C4.71667 5 4.47917 5.09583 4.2875 5.2875C4.09583 5.47917 4 5.71667 4 6C4 6.28333 4.09583 6.52083 4.2875 6.7125C4.47917 6.90417 4.71667 7 5 7Z"
                                fill="white"
                            />
                        </g>
                    </svg>
                </span>
                <p>{t('menuComponent.history')}</p>
            </button>

            <button
                onClick={() => {
                    handleMessage(
                        'Drones',
                        t('menuComponent.functionNotAvailable'),
                        'info',
                    );
                }}
                className="no-hover"
            >
                <span>
                    <TbDrone />
                </span>
                <p>{t('menuComponent.drones')}</p>
            </button>

            <button
                onClick={() => {
                    handleRedirectTo('/history?reports=true');
                }}
                className="no-hover"
            >
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                    >
                        <mask
                            id="mask0_78_3706"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="25"
                        >
                            <rect
                                y="0.314453"
                                width="24"
                                height="24"
                                fill="#D9D9D9"
                            />
                        </mask>
                        <g mask="url(#mask0_78_3706)">
                            <path
                                d="M3 21.3145C2.71667 21.3145 2.47917 21.2186 2.2875 21.027C2.09583 20.8353 2 20.5978 2 20.3145C2 20.0311 2.09583 19.7936 2.2875 19.602C2.47917 19.4103 2.71667 19.3145 3 19.3145H21C21.2833 19.3145 21.5208 19.4103 21.7125 19.602C21.9042 19.7936 22 20.0311 22 20.3145C22 20.5978 21.9042 20.8353 21.7125 21.027C21.5208 21.2186 21.2833 21.3145 21 21.3145H3ZM4.5 18.3145C4.08333 18.3145 3.72917 18.1686 3.4375 17.877C3.14583 17.5853 3 17.2311 3 16.8145V12.8145C3 12.3978 3.14583 12.0436 3.4375 11.752C3.72917 11.4603 4.08333 11.3145 4.5 11.3145C4.91667 11.3145 5.27083 11.4603 5.5625 11.752C5.85417 12.0436 6 12.3978 6 12.8145V16.8145C6 17.2311 5.85417 17.5853 5.5625 17.877C5.27083 18.1686 4.91667 18.3145 4.5 18.3145ZM9.5 18.3145C9.08333 18.3145 8.72917 18.1686 8.4375 17.877C8.14583 17.5853 8 17.2311 8 16.8145V7.81445C8 7.39779 8.14583 7.04362 8.4375 6.75195C8.72917 6.46029 9.08333 6.31445 9.5 6.31445C9.91667 6.31445 10.2708 6.46029 10.5625 6.75195C10.8542 7.04362 11 7.39779 11 7.81445V16.8145C11 17.2311 10.8542 17.5853 10.5625 17.877C10.2708 18.1686 9.91667 18.3145 9.5 18.3145ZM14.5 18.3145C14.0833 18.3145 13.7292 18.1686 13.4375 17.877C13.1458 17.5853 13 17.2311 13 16.8145V10.8145C13 10.3978 13.1458 10.0436 13.4375 9.75195C13.7292 9.46029 14.0833 9.31445 14.5 9.31445C14.9167 9.31445 15.2708 9.46029 15.5625 9.75195C15.8542 10.0436 16 10.3978 16 10.8145V16.8145C16 17.2311 15.8542 17.5853 15.5625 17.877C15.2708 18.1686 14.9167 18.3145 14.5 18.3145ZM19.5 18.3145C19.0833 18.3145 18.7292 18.1686 18.4375 17.877C18.1458 17.5853 18 17.2311 18 16.8145V4.81445C18 4.39779 18.1458 4.04362 18.4375 3.75195C18.7292 3.46029 19.0833 3.31445 19.5 3.31445C19.9167 3.31445 20.2708 3.46029 20.5625 3.75195C20.8542 4.04362 21 4.39779 21 4.81445V16.8145C21 17.2311 20.8542 17.5853 20.5625 17.877C20.2708 18.1686 19.9167 18.3145 19.5 18.3145Z"
                                fill="white"
                            />
                        </g>
                    </svg>
                </span>
                <p>{t('menuComponent.reports')}</p>
            </button>

            <Vector />

            <button
                onClick={() => {
                    navigate('/create-area');
                }}
                className="button-green--bullet"
            >
                +
            </button>
            <p>{t('menuComponent.newArea')}</p>

            <div className="version">
                {frontendVersion} | {version} <br /> &copy; XMobots
            </div>
        </div>
    );
};

export default Menu;
