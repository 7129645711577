import { GeoserverType } from '../types/';
import { backendAPI } from './backendAPI';

const BASE_URL = '/geoservers';

export async function getAllGeoserver(): Promise<GeoserverType[]> {
    const response = await backendAPI.get<GeoserverType[]>(BASE_URL);
    const geoservers = response.data;
    geoservers.sort((a, b) => {
        if (a.name.startsWith('XPESS') && !b.name.startsWith('XPESS')) {
            return -1;
        }
        if (!a.name.startsWith('XPESS') && b.name.startsWith('XPESS')) {
            return 1;
        }
        return a.name.localeCompare(b.name);
    });

    return geoservers;
}
