import React, { useEffect, useState } from 'react';

import ElevationLayer from '@arcgis/core/layers/ElevationLayer';
import IntegratedMesh3DTilesLayer from '@arcgis/core/layers/IntegratedMesh3DTilesLayer';
import SceneView from '@arcgis/core/views/SceneView';
import WebScene from '@arcgis/core/WebScene';

import { Button, Col, List, notification, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { getAllGeo3DTiles, getURLGeo3DTilesById } from '../../../services';
import { Geo3DTilesType, PageType } from '../../../types';

interface IProps {
    setView3D: (view: SceneView) => void;
    setMap3D: (map: WebScene) => void;
}

const Geo3DTilesWidget = (props: IProps) => {
    const { t } = useTranslation();

    const [notificationInstance, contextHolder] =
        notification.useNotification();

    const [geo3DTiles, setGeo3DTiles] = useState<PageType<Geo3DTilesType>>();
    const [loading, setLoading] = useState(false);

    const map3D = new WebScene({
        basemap: 'hybrid',
        ground: {
            layers: [
                new ElevationLayer({
                    url: 'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer',
                }),
            ],
        },
    });

    const view3D = new SceneView({
        map: map3D,
        container: 'viewDiv',
        zoom: 15,
        center: [-47.85605, -22.053135],
        ui: {
            components: [],
        },
    });

    props.setView3D(view3D);
    props.setMap3D(map3D);

    const handleOpen3DTiles = async (geo3Dtiles: Geo3DTilesType) => {
        try {
            const { url } = await getURLGeo3DTilesById(geo3Dtiles.id);
            if (!url) return;

            const existLayer = map3D.layers.find(
                (layer) => layer.id === geo3Dtiles.id.toString(),
            );

            if (existLayer) {
                await existLayer.when(() => {
                    if (existLayer.fullExtent) {
                        view3D.goTo(existLayer.fullExtent);
                    }
                });
                return;
            }

            const layer = new IntegratedMesh3DTilesLayer({
                url,
                id: geo3Dtiles.id.toString(),
            });
            map3D.add(layer);

            await layer.when(() => {
                if (layer.fullExtent) {
                    view3D.goTo(layer.fullExtent);
                }
            });
        } catch (_) {
            notificationInstance.error({
                message: t('geo3DTilesWidget.notFound'),
            });
        }
    };

    const loadData = () => {
        getAllGeo3DTiles({}).then(setGeo3DTiles);
    };

    const loadMore = () => {
        if (!geo3DTiles) return;
        if (loading) return;
        setLoading(true);
        getAllGeo3DTiles({
            page: geo3DTiles?.number + 1,
            size: 5,
        })
            .then((response) => {
                setGeo3DTiles((prevState) => {
                    return {
                        ...response,
                        page: geo3DTiles?.number + 1,
                        content: [
                            ...(prevState?.content ?? []),
                            ...response.content,
                        ],
                    };
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <section
            id="geo3DTilesWidget"
            className="scroll"
            style={{
                overflowY: 'auto',
                height: 'calc(100vh - 139px)',
            }}
        >
            {contextHolder}
            <Col span={24} style={{ padding: 24 }}>
                <List
                    dataSource={geo3DTiles?.content}
                    grid={{ gutter: 16, column: 1 }}
                    loading={loading}
                    loadMore={
                        !geo3DTiles?.last && (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: 12,
                                    height: 32,
                                    lineHeight: '32px',
                                }}
                            >
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <Button onClick={loadMore}>
                                        {t('geo3DTilesWidget.loadMore')}
                                    </Button>
                                )}
                            </div>
                        )
                    }
                    renderItem={(item) => (
                        <List.Item>
                            <Button
                                className="input"
                                onClick={() => handleOpen3DTiles(item)}
                            >
                                <Row>
                                    <Col span={22}>
                                        <span>{item.name}</span>
                                    </Col>
                                </Row>
                            </Button>
                        </List.Item>
                    )}
                />
            </Col>
        </section>
    );
};

export default Geo3DTilesWidget;
