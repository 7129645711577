import axios from 'axios';

import {
    LayerItemType,
    LayerStyleType,
    BoundingBoxType,
    DimensionType,
    LayerGroupsType,
} from '../types';
import { getLanguage } from './localStorageService';

export async function getOWSData(params: {
    url: string;
}): Promise<LayerGroupsType[]> {
    const { url } = params;
    const language = getLanguage();

    try {
        const response = await axios.get(url, {
            params: {
                service: 'WMS',
                version: '1.3.0',
                request: 'GetCapabilities',
            },
            headers: { 'Accept-Language': language },
        });

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, 'text/xml');

        const layers = Array.from(
            xmlDoc.querySelectorAll('Layer[queryable="1"]'),
        );

        const layerGroups: LayerGroupsType[] = [];

        layers.forEach((layer) => {
            const layerItem = parseLayer(layer, url);

            const group = layerGroups.find(
                (group) => group.workspace === layerItem.workspace,
            );

            if (!group) {
                layerGroups.push({
                    workspace: layerItem.workspace,
                    layers: [layerItem],
                });
            } else {
                group.layers.push(layerItem);
            }
        });

        for (const group of layerGroups) {
            group.layers.sort((a, b) => a.title.localeCompare(b.title));
        }

        return layerGroups;
    } catch (error) {
        console.error('Error fetching OWS data:', error);
        throw error;
    }
}

const parseLayer = (layer: Element, url: string): LayerItemType => {
    const name = layer.querySelector('Name')?.textContent ?? '';
    const title = layer.querySelector('Title')?.textContent ?? '';
    const description = layer.querySelector('Abstract')?.textContent ?? '';
    const keywords = Array.from(layer.querySelectorAll('Keyword'))
        .map((keyword) => keyword.textContent ?? '')
        .filter((keyword) => keyword);

    const styles = parseStyles(layer.querySelectorAll('Style'));
    const boundingBoxes = parseBoundingBoxes(
        layer.querySelectorAll('BoundingBox'),
    );

    const dimension = parseDimension(layer.querySelector('Dimension'));

    const workspace = name
        .split(':')[0]
        .split('_')
        .map(
            (word) =>
                word.charAt(0).toLocaleUpperCase('pt-BR') +
                word.slice(1).toLocaleLowerCase('pt-BR'),
        )
        .join(' ');

    return {
        layer: name,
        workspace: workspace,
        title: title,
        description: description,
        url: url,
        keywords: keywords,
        style: styles,
        boundingBoxes: boundingBoxes,
        dimension: dimension,
    };
};

const parseStyles = (styleElements: NodeListOf<Element>): LayerStyleType[] => {
    return Array.from(styleElements).map((styleElement) => {
        const name = styleElement.querySelector('Name')?.textContent ?? '';
        const description =
            styleElement.querySelector('Abstract')?.textContent ?? '';
        const legendURL =
            styleElement
                .querySelector('LegendURL > OnlineResource')
                ?.getAttribute('xlink:href') ?? '';
        const format =
            styleElement.querySelector('LegendURL > Format')?.textContent ?? '';

        return {
            name,
            description,
            legendURL: { url: legendURL, format },
        };
    });
};

const parseBoundingBoxes = (
    bboxElements: NodeListOf<Element>,
): BoundingBoxType[] => {
    return Array.from(bboxElements).map((bbox) => {
        const crs = bbox.getAttribute('CRS') ?? '';
        const minx = parseFloat(bbox.getAttribute('minx') ?? '0');
        const miny = parseFloat(bbox.getAttribute('miny') ?? '0');
        const maxx = parseFloat(bbox.getAttribute('maxx') ?? '0');
        const maxy = parseFloat(bbox.getAttribute('maxy') ?? '0');

        return { crs, minx, miny, maxx, maxy };
    });
};

const parseDimension = (
    dimensionElement: Element | null,
): DimensionType | null => {
    if (!dimensionElement) {
        return null;
    }

    const name = dimensionElement.getAttribute('name') ?? '';
    const defaultValue = dimensionElement.getAttribute('default') ?? '';
    const units = dimensionElement.getAttribute('units') ?? '';
    const values =
        dimensionElement.textContent?.split(',').map((value) => value.trim()) ??
        [];

    return {
        name: name,
        defaultValue: defaultValue,
        units: units,
        values: values,
    };
};
