import { RootState } from '../config/redux/store';
import { useDispatch, useSelector } from 'react-redux';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { changeReportModalState } from '../config/redux/modal/slice';
import { addReport } from '../config/redux/report/slice';

interface IPdfMiddlewareProps {
    onNext?: () => void;
    onPrevious?: () => void;
    OnClose?: () => void;
}

const PdfMiddleware = (props: IPdfMiddlewareProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { reportBase64 } = useSelector((state: RootState) => state.report);

    const { reportModal, titleModal } = useSelector(
        (state: RootState) => state.modal,
    );

    const handleReportModalState = () => {
        dispatch(
            changeReportModalState({
                reportModal: !reportModal,
            }),
        );

        dispatch(
            addReport({
                reportBase64: null,
            }),
        );
        props.OnClose && props.OnClose();
    };

    return (
        <>
            {reportModal ? (
                <Modal
                    title={`${t('pdf.title')}: ${titleModal}`}
                    open={reportModal}
                    onCancel={() => {
                        handleReportModalState();
                    }}
                    footer={null}
                    width={'50%'}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {props.onPrevious && (
                            <Button
                                type="text"
                                icon={<LeftOutlined />}
                                size="large"
                                style={{
                                    marginRight: '10px',
                                }}
                                onClick={props.onPrevious}
                            />
                        )}
                        <embed
                            className="scroll"
                            src={`data:application/pdf;base64,${reportBase64}`}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                        />
                        {props.onNext && (
                            <Button
                                type="text"
                                icon={<RightOutlined />}
                                size="large"
                                style={{
                                    marginLeft: '10px',
                                }}
                                onClick={props.onNext}
                            />
                        )}
                    </div>
                </Modal>
            ) : (
                <></>
            )}
        </>
    );
};

export default PdfMiddleware;
