import { Geo3DTilesType, PageFormType, PageType, UrlType } from '../types/';
import { backendAPI } from './backendAPI';

const BASE_URL = '/geo-3d-tiles';

export async function getAllGeo3DTiles(
    queryParams: PageFormType & {
        name?: string;
    },
): Promise<PageType<Geo3DTilesType>> {
    const response = await backendAPI.get<PageType<Geo3DTilesType>>(BASE_URL, {
        params: queryParams,
    });
    return response.data;
}

export async function getGeo3DTilesById(id: number): Promise<Geo3DTilesType> {
    const response = await backendAPI.get<Geo3DTilesType>(`${BASE_URL}/${id}`);
    return response.data;
}

export async function getURLGeo3DTilesById(id: number): Promise<UrlType> {
    const response = await backendAPI.get<UrlType>(`${BASE_URL}/${id}/url`);
    return response.data;
}
