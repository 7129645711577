import React, { useEffect, useState } from 'react';

import './PanelComponent.scss';

import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { getAllAreaOfInterest, getMissionInformation } from '../../services';
import { AreaOfInterestType, MissionInformationType } from '../../types';
import Boxes from '../BoxesComponent/Boxes';
import View from '../ViewComponent/ViewComponent';

const Panel = () => {
    const { t } = useTranslation();
    const [missions, setMissions] = useState<AreaOfInterestType[]>([]);
    const [missionsWaiting, setMissionsWaiting] = useState<
        AreaOfInterestType[]
    >([]);
    const [monthsBeforeNow, setMonthsBeforeNow] = useState<
        number | undefined
    >();
    const [missionInformation, setMissionInformation] =
        useState<MissionInformationType>();
    const navigate = useNavigate();

    enum SelectedClasses {
        LAST = 'last',
        LAST_THREE = 'last-three',
        LAST_SIX = 'last-six',
        ALL = 'all',
    }

    useEffect(() => {
        removeSelectedClassName();

        switch (monthsBeforeNow) {
            case undefined:
                addSelectedClassName(SelectedClasses.ALL);
                break;
            case 1:
                addSelectedClassName(SelectedClasses.LAST);
                break;
            case 3:
                addSelectedClassName(SelectedClasses.LAST_THREE);
                break;
            case 6:
                addSelectedClassName(SelectedClasses.LAST_SIX);
                break;
        }

        getAllAreaOfInterest({
            size: 4,
            flightApprovalStatus: 'APPROVED',
            isMission: true,
            monthsBeforeNow: monthsBeforeNow,
            sortList: 'areaDate',
            sortOrder: 'DESC',
        }).then((data) => {
            setMissions(data.content);
        });

        getAllAreaOfInterest({
            size: 4,
            flightApprovalStatus: 'WAITING',
            isMission: false,
            monthsBeforeNow: monthsBeforeNow,
            sortList: 'areaDate',
            sortOrder: 'DESC',
        }).then((data) => {
            setMissionsWaiting(data.content);
        });

        getMissionInformation({ monthsBeforeNow: monthsBeforeNow }).then(
            setMissionInformation,
        );
    }, [monthsBeforeNow]);

    const removeSelectedClassName = (): void => {
        const objects = ['last', 'last-three', 'last-six', 'all'];

        objects.forEach((id) => {
            const element = document.getElementById(id);
            if (element) {
                element.classList.remove('selected-filter');
            }
        });
    };

    const addSelectedClassName = (id: SelectedClasses): void => {
        const element = document.getElementById(id);
        if (element) {
            element.classList.add('selected-filter');
        }
    };

    return (
        <section id="panelComponent">
            <div className="panel-wrapper">
                <header>
                    <h1>{t('panelComponent.completedMissions.title')}</h1>
                    <button
                        onClick={() => {
                            navigate('/create-area');
                        }}
                        type="submit"
                        className="button-green"
                    >
                        {t('panelComponent.completedMissions.newAreaButton')}
                    </button>
                </header>

                <br className="space" />

                <div className="panel-wrapper--filters">
                    <button
                        onClick={() => setMonthsBeforeNow(undefined)}
                        className={`button ${
                            monthsBeforeNow != undefined && ' transparent'
                        }`}
                        id="all"
                    >
                        {t('panelComponent.completedMissions.all')}
                    </button>
                    <button
                        onClick={() => setMonthsBeforeNow(1)}
                        className={`button ${
                            monthsBeforeNow != 1 && ' transparent'
                        }`}
                        id="last"
                    >
                        {t('panelComponent.completedMissions.lastMonth')}
                    </button>
                    <button
                        onClick={() => setMonthsBeforeNow(3)}
                        className={`button ${
                            monthsBeforeNow != 3 && ' transparent'
                        }`}
                        id="last-three"
                    >
                        {t('panelComponent.completedMissions.lastThreeMonths')}
                    </button>
                    <button
                        onClick={() => setMonthsBeforeNow(6)}
                        className={`button ${
                            monthsBeforeNow != 6 && ' transparent'
                        }`}
                        id="last-six"
                    >
                        {t('panelComponent.completedMissions.lastSixMonths')}
                    </button>
                </div>

                <br className="space" />

                {missionInformation == null ? (
                    <p className="text-align-center">
                        {t('panelComponent.completedMissions.noDataMessage')}
                    </p>
                ) : (
                    <div className="panel_wrapper--content">
                        <Boxes
                            title={t(
                                'panelComponent.completedMissions.occurrences.title',
                            )}
                            doubt={t(
                                'panelComponent.completedMissions.occurrences.doubt',
                            )}
                            quantity={missionInformation.occurrencesQuantity}
                            diff={missionInformation.occurrencesDiff}
                            buttonType={false}
                        />
                        <Boxes
                            title={t(
                                'panelComponent.completedMissions.completedQuantity.title',
                            )}
                            doubt={t(
                                'panelComponent.completedMissions.completedQuantity.doubt',
                            )}
                            quantity={missionInformation.accomplishedQuantity}
                            diff={missionInformation.accomplishedDiff}
                            buttonType={true}
                        />
                        <Boxes
                            title={t(
                                'panelComponent.completedMissions.canceledQuantity.title',
                            )}
                            doubt={t(
                                'panelComponent.completedMissions.canceledQuantity.doubt',
                            )}
                            quantity={missionInformation.canceledQuantity}
                            diff={missionInformation.canceledDiff}
                            buttonType={false}
                        />
                        <Boxes
                            title={t(
                                'panelComponent.completedMissions.averageTime.title',
                            )}
                            doubt={t(
                                'panelComponent.completedMissions.averageTime.doubt',
                            )}
                            quantity={missionInformation.averageTimeQuantity}
                            diff={missionInformation.averageTimeDiff}
                            buttonType={true}
                        />
                    </div>
                )}
            </div>

            <br className="space" />

            <div className="panel-wrapper">
                <header>
                    <h1>{t('panelComponent.waitingAreas.title')}</h1>
                    <Link to="/history" className="inline-button">
                        {t('panelComponent.waitingAreas.viewAllButton')}
                    </Link>
                </header>

                <br className="space" />

                {missionsWaiting.length == 0 ? (
                    <p className="text-align-center">
                        {t('panelComponent.waitingAreas.noAreasMessage')}
                    </p>
                ) : (
                    <div className="panel_wrapper--content">
                        {missionsWaiting.map((areaOfInterest) => (
                            <View
                                key={areaOfInterest.id}
                                view={{
                                    tips: {
                                        active: true,
                                        text: areaOfInterest.flightApprovalStatus,
                                    },
                                    image: areaOfInterest.areaImgPath,
                                    missionName: areaOfInterest.name,
                                    missionType: areaOfInterest.payloadType,
                                    reportType:
                                        areaOfInterest.report.reportType,
                                    status: areaOfInterest.flightApprovalStatus,
                                    date: areaOfInterest.date,
                                    id: areaOfInterest.id,
                                }}
                            />
                        ))}
                        {[...Array(4 - missionsWaiting.length).keys()].map(
                            (key) => (
                                <View key={key} view={null} />
                            ),
                        )}
                    </div>
                )}
            </div>

            <br className="space" />

            <div className="panel-wrapper">
                <header>
                    <h1>{t('panelComponent.upcomingMissions.title')}</h1>
                    <Link to="/history" className="inline-button">
                        {t('panelComponent.upcomingMissions.viewAllButton')}
                    </Link>
                </header>

                <br className="space" />

                {missions.length <= 0 ? (
                    <p className="text-align-center">
                        {t('panelComponent.upcomingMissions.noMissionsMessage')}
                    </p>
                ) : (
                    <div className="panel_wrapper--content">
                        {missions.map((areaOfInterest) => (
                            <View
                                key={areaOfInterest.id + '-mission'}
                                view={{
                                    tips: {
                                        active: true,
                                        text: areaOfInterest.mission!.status,
                                    },
                                    image: areaOfInterest.areaImgPath,
                                    missionName: areaOfInterest.name,
                                    missionType: areaOfInterest.payloadType,
                                    reportType:
                                        areaOfInterest.report.reportType,
                                    status: areaOfInterest.mission!.status,
                                    date: areaOfInterest.date,
                                    id: areaOfInterest.id,
                                }}
                                approved={true}
                            />
                        ))}
                        {[...Array(4 - missions.length).keys()].map((key) => (
                            <View key={key} view={null} />
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default Panel;
