import React, { useCallback, useEffect, useState } from 'react';

import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Slider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { DimensionType, LayerItemType } from '../../../types';
import { convertDateFromISO8601 } from '../../../utils/Date';
import { debounce } from '../../../utils/Utils';

interface IProps {
    layer: LayerItemType;
    changeDimensionLayer: (_: LayerItemType, __: string) => void;
}

const SliderDimensionComponent = ({ layer, changeDimensionLayer }: IProps) => {
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState(
        layer.dimension?.values[layer.dimension?.values.length - 1] ?? '',
    );

    const [selectedValueIndex, setSelectedValueIndex] = useState(
        layer.dimension ? layer.dimension?.values.length - 1 : 0,
    );

    const [isPlaying, setIsPlaying] = useState(false);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const playSpeed = 5000;

    const debouncedChangeDimensionLayer = useCallback(
        debounce((value?: string) => {
            if (value) {
                changeDimensionLayer(layer, value);
                setSelectedValue(value);
            }
        }, 300),
        [layer, changeDimensionLayer],
    );

    const handleSliderChange = (index: number) => {
        const newValue = layer.dimension!.values[index];
        setSelectedValueIndex(index);
        debouncedChangeDimensionLayer(newValue);
    };

    useEffect(() => {
        if (isPlaying) {
            const id = setInterval(() => {
                setSelectedValue((prevValue) => {
                    const currentIndex =
                        layer.dimension!.values.indexOf(prevValue);
                    const nextIndex =
                        (currentIndex + 1) % layer.dimension!.values.length;
                    const newValue = layer.dimension!.values[nextIndex];
                    debouncedChangeDimensionLayer(newValue);
                    setSelectedValueIndex(nextIndex);
                    return newValue;
                });
            }, playSpeed);
            setIntervalId(id);
            return () => clearInterval(id);
        } else if (intervalId) {
            clearInterval(intervalId);
        }
    }, [isPlaying, debouncedChangeDimensionLayer, layer.dimension?.values]);

    const handlePlayPauseClick = () => {
        setIsPlaying((prev) => !prev);
    };

    const marks = (dimension: DimensionType) => {
        return dimension.values.reduce(
            (acc: Record<number, React.ReactNode>, value, index) => {
                acc[index] = (
                    <Tooltip title={convertDateFromISO8601(value)}>
                        <div
                            style={{
                                width: '5px',
                                height: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '1px',
                                    height: '5px',
                                    backgroundColor: '#d9d9d9',
                                }}
                            />
                        </div>
                    </Tooltip>
                );
                return acc;
            },
            {} as Record<number, React.ReactNode>,
        );
    };

    if (!layer.dimension) return null;
    return (
        <div style={{ marginBottom: '10px' }}>
            <span style={{ marginLeft: '8px' }}>
                {t('layerWidget.dimension')}
            </span>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    icon={
                        isPlaying ? (
                            <PauseCircleOutlined />
                        ) : (
                            <PlayCircleOutlined />
                        )
                    }
                    type="text"
                    onClick={handlePlayPauseClick}
                    size={'large'}
                    style={{
                        marginBottom: '10px',
                    }}
                />
                <Slider
                    style={{ width: '100%', marginBottom: '20px' }}
                    min={0}
                    max={layer.dimension.values.length - 1}
                    step={1}
                    marks={marks(layer.dimension)}
                    value={selectedValueIndex}
                    tooltip={{
                        formatter: (value) =>
                            convertDateFromISO8601(
                                layer.dimension!.values[value as number],
                            ),
                    }}
                    onChange={handleSliderChange}
                />
            </div>
            <div
                style={{
                    textAlign: 'center',
                    marginTop: '0px',
                    marginBottom: '20px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                }}
            >
                {convertDateFromISO8601(selectedValue)}
            </div>
        </div>
    );
};

export default SliderDimensionComponent;
