import { createSlice } from '@reduxjs/toolkit';

interface InitialInterface {
    reportModal: boolean;
    titleModal: string;
}

const initialState: InitialInterface = {
    reportModal: false,
    titleModal: '',
};

const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        changeReportModalState: (state, action) => {
            state.reportModal = action.payload.reportModal;
        },
        changeModalTitle: (state, action) => {
            state.titleModal = action.payload.title;
        },
    },
});

export const { changeReportModalState, changeModalTitle } = modalSlice.actions;

export default modalSlice.reducer;
