import React, { useEffect, useState } from 'react';

import './DetailsComponent.scss';

import { RootState } from '../../config/redux/store';
import { useDispatch, useSelector } from 'react-redux';

import MapMiddleware from '../../middleware/MapMiddleware';
import { getIconStatus } from '../../middleware/Status';

import { ArrowLeftOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { addArea } from '../../config/redux/area/slice';
import { userLocation } from '../../config/redux/user/types';
import { getByIdAreaOfInterest } from '../../services';
import { AreaOfInterestType } from '../../types';
import { convertDateFromString } from '../../utils/Date';
import calculateDistance, {
    Coordinate,
    calculatePolygonArea,
    calculatePolygonCenter,
    translateEnums,
    getLocationFromCoordinates,
} from '../../utils/Utils';

const DetailsComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { id } = useParams();
    const [mission, setMission] = useState<AreaOfInterestType>();
    const [centroid, setCentroid] = useState<Coordinate | null>();
    const [placeholderData, setPlaceholderData] = useState<number[][]>([]);
    const [location, setLocation] = useState<userLocation | null>(null);

    const { userLocation } = useSelector((state: RootState) => state.user);

    const loadData = () => {
        if (!id) return;
        getByIdAreaOfInterest(Number(id)).then(setMission);
    };

    const mapRender = () => {
        if (!centroid) return <></>;
        return <MapMiddleware location={[centroid.long, centroid.lat]} />;
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (mission) {
            const coordinates: Coordinate[] =
                mission.areaGeomHex.coordinates.map(
                    (data) =>
                        ({
                            lat: data[1],
                            long: data[0],
                        } as Coordinate),
                );
            setCentroid(calculatePolygonCenter(coordinates));
            setPlaceholderData(mission.areaGeomHex.coordinates);

            dispatch(
                addArea({
                    area_coordinates: mission.areaGeomHex.coordinates,
                }),
            );
        }
    }, [dispatch, mission]);

    useEffect(() => {
        if (centroid) {
            getLocationFromCoordinates({
                latitude: centroid.lat,
                longitude: centroid.long,
            }).then(setLocation);
        }
    }, [centroid]);

    const distanceNotAvailableTooltipText = (
        <p className="small">
            Para calcular sua distância do local indicado, altere as permissões
            de localização do seu navegador.
        </p>
    );

    const distanceNotAvailable = (
        <p className="high todo">
            <Tooltip
                title={distanceNotAvailableTooltipText}
                placement="bottom"
                color="var(--Colors-Fill-colorFill)"
            >
                <QuestionCircleFilled />{' '}
            </Tooltip>{' '}
            Não disponível
        </p>
    );

    return (
        <section id="detailsComponent">
            <div id="detailsComponent__Menu" className="scroll">
                <div id="detailsComponent__Content">
                    <header>
                        <h1>
                            <a onClick={() => navigate(-1)}>
                                <ArrowLeftOutlined />
                            </a>
                            {mission?.name}
                        </h1>
                    </header>

                    <br className="space" />

                    <main>
                        <div className="separator">
                            <h5>{t('detailsComponent.visualization.title')}</h5>

                            <br className="small-space" />

                            <div className="separator--box">
                                <div className="separator--box__object">
                                    <span className="small">
                                        {t(
                                            'detailsComponent.visualization.type',
                                        )}
                                    </span>
                                    <p className="high">
                                        {mission &&
                                            t(
                                                mission.mission
                                                    ? 'detailsComponent.visualization.mission'
                                                    : 'detailsComponent.visualization.areaOfInterest',
                                            )}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <br className="space" />
                        <div className="separator">
                            <h5>
                                {t('detailsComponent.generalInformation.title')}
                            </h5>

                            <br className="small-space" />

                            <div className="separator--box">
                                <div className="separator--box__object">
                                    <span className="small">
                                        {t(
                                            'detailsComponent.generalInformation.type',
                                        )}
                                    </span>
                                    <p className="high">
                                        {mission &&
                                            t(
                                                translateEnums(
                                                    mission.payloadType.toString(),
                                                ),
                                            )}
                                    </p>
                                </div>

                                <div className="separator--box__object">
                                    <span className="small">Relatório</span>
                                    <p className="high">Imagem</p>
                                </div>
                            </div>

                            <div className="separator--box">
                                <div className="separator--box__object">
                                    <span className="small">
                                        {t(
                                            'detailsComponent.generalInformation.status',
                                        )}
                                    </span>
                                    <p className="high todo">
                                        {mission &&
                                            getIconStatus(
                                                mission?.flightApprovalStatus.toString(),
                                                t,
                                            )}
                                    </p>
                                </div>

                                <div className="separator--box__object">
                                    <span className="small">
                                        {t(
                                            'detailsComponent.generalInformation.dateTime',
                                        )}
                                    </span>
                                    <p className="high">
                                        {mission &&
                                            convertDateFromString(mission.date)}
                                    </p>
                                </div>
                            </div>
                            {mission?.mission && (
                                <div className="separator--box">
                                    <div className="separator--box__object">
                                        <span className="small">
                                            {t(
                                                'detailsComponent.generalInformation.statusMission',
                                            )}
                                        </span>
                                        <p className="high todo">
                                            {mission &&
                                                getIconStatus(
                                                    mission.mission.status.toString(),
                                                    t,
                                                )}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>

                        <br className="space" />

                        <div className="separator">
                            <h5>{t('detailsComponent.locationData.title')}</h5>

                            <br className="small-space" />

                            <div className="separator--box">
                                <div className="separator--box__object">
                                    <span className="small">
                                        {t(
                                            'detailsComponent.locationData.city',
                                        )}
                                    </span>
                                    <p className="high">{location?.city}</p>
                                </div>

                                <div className="separator--box__object">
                                    <span className="small">
                                        {t(
                                            'detailsComponent.locationData.country',
                                        )}
                                    </span>
                                    <p className="high">{location?.country}</p>
                                </div>
                            </div>

                            <div className="separator--box">
                                <div className="separator--box__object">
                                    <span className="small">
                                        {t(
                                            'detailsComponent.locationData.totalArea',
                                        )}
                                    </span>
                                    <p className="high todo">
                                        {placeholderData &&
                                            calculatePolygonArea(
                                                placeholderData,
                                            ).toFixed(2)}
                                    </p>
                                </div>

                                <div className="separator--box__object">
                                    <span className="small">
                                        {t(
                                            'detailsComponent.locationData.totalDistance',
                                        )}
                                    </span>
                                    {(centroid && userLocation && (
                                        <p className="high">
                                            {calculateDistance(
                                                userLocation.lat,
                                                userLocation.long,
                                                centroid.lat,
                                                centroid.long,
                                            ).toFixed(2)}
                                        </p>
                                    )) ??
                                        distanceNotAvailable}
                                </div>
                            </div>
                        </div>

                        <br className="space" />

                        <div style={{ display: 'none' }} className="separator">
                            <h5>{t('detailsComponent.droneData.title')}</h5>

                            <br className="small-space" />

                            <p className="text-align-center">
                                {t('detailsComponent.droneData.noDataMessage')}
                            </p>
                        </div>
                    </main>
                </div>
            </div>

            {mapRender()}
        </section>
    );
};

export default DetailsComponent;
