import React, { useState } from 'react';

import {
    AimOutlined,
    ClearOutlined,
    HeartFilled,
    HeartOutlined,
    InfoCircleFilled,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Collapse,
    List,
    Row,
    Select,
    Slider,
    Switch,
    Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { getSettings } from '../../../services';
import { LayerGroupsType, LayerItemType } from '../../../types';
import SliderDimensionComponent from '../components/SliderDimensionComponent';
import LayerMetadataComponent from './LayerMetadataComponent';

import { PiListBullets, PiTreeStructure } from 'react-icons/pi';

const { Panel } = Collapse;

interface CollapseListViewProps {
    layerGroups: LayerGroupsType[];
    favorite: Set<string>;
    onFavorite: (item: LayerItemType) => void;
    changeLayer: (item: LayerItemType) => void;
    changeStyleLayer: (item: LayerItemType, style: string) => void;
    changeDimensionLayer: (item: LayerItemType, dimension: string) => void;
    getOpacity: (item: LayerItemType) => number;
    setOpacity: (item: LayerItemType, value: number) => void;
    filterSearch: string;
    checked: Set<string>;
    goToLayer: (layer: LayerItemType) => void;
    handleClearAllLayers: () => void;
}

const LayerCollapseListView: React.FC<CollapseListViewProps> = ({
    layerGroups,
    favorite,
    onFavorite,
    changeLayer,
    changeStyleLayer,
    changeDimensionLayer,
    goToLayer,
    getOpacity,
    setOpacity,
    filterSearch,
    checked,
    handleClearAllLayers,
}) => {
    const { t } = useTranslation();
    const [showGroup, setShowGroup] = useState<boolean>(true);
    const [selectedLayer, setSelectedLayer] = useState<LayerItemType>();
    const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);

    const handleInfoModal = (item: LayerItemType) => {
        setOpenInfoModal(!openInfoModal);
        setSelectedLayer(item);
    };

    const filter = (layers: LayerGroupsType[]): LayerGroupsType[] => {
        layers = layers.filter((layer) =>
            layer.layers.some((item) =>
                item.title.toLowerCase().includes(filterSearch.toLowerCase()),
            ),
        );

        layers.forEach((layer) => {
            layer.layers = layer.layers.filter((item) =>
                item.title.toLowerCase().includes(filterSearch.toLowerCase()),
            );
        });

        return layers;
    };

    const isSelectItem = (item: LayerItemType): boolean => {
        const select = checked.has(item.title);
        const settings = getSettings();
        const layerSettings = settings?.layer_map?.items_selected?.find(
            (layer) => layer.title === item.title && layer.url === item.url,
        );

        if (layerSettings?.checked != undefined) {
            return select || layerSettings?.checked;
        }

        return select;
    };

    const renderLayerItem = (item: LayerItemType) => (
        <div
            style={{
                padding: '5px',
                marginBottom: '5px',
                width: '100%',
                border: isSelectItem(item) ? '2px solid #3a3a3a' : 'none',
            }}
        >
            <List.Item
                key={item.layer}
                style={{ marginBottom: '10px', width: '100%' }}
            >
                <List.Item.Meta
                    avatar={
                        <Tooltip title="Favorite">
                            <Button
                                type="text"
                                icon={
                                    favorite.has(item.layer) ? (
                                        <HeartFilled />
                                    ) : (
                                        <HeartOutlined />
                                    )
                                }
                                onClick={() => onFavorite(item)}
                            />
                        </Tooltip>
                    }
                    title={
                        <h5
                            style={{
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word',
                            }}
                        >
                            {item.title}
                        </h5>
                    }
                    description={
                        <p
                            style={{
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word',
                            }}
                        >
                            {item.description}
                        </p>
                    }
                />
                <Switch
                    checked={isSelectItem(item)}
                    onChange={(active) =>
                        changeLayer({ ...item, checked: active })
                    }
                />
            </List.Item>
            {isSelectItem(item) && (
                <div style={{ padding: '8px' }}>
                    {item.style[0] && (
                        <div style={{ marginBottom: '10px' }}>
                            <span style={{ marginLeft: '8px' }}>Style</span>
                            <Select
                                className="select"
                                style={{ width: '100%' }}
                                options={item.style.map((style) => ({
                                    label: style.name,
                                    value: style.name,
                                }))}
                                defaultValue={item.style[0]?.name}
                                onChange={(value) =>
                                    changeStyleLayer(item, value)
                                }
                            />
                        </div>
                    )}

                    <SliderDimensionComponent
                        layer={item}
                        changeDimensionLayer={changeDimensionLayer}
                    />

                    <Row>
                        <Col span={20}>
                            <span style={{ marginLeft: '8px' }}>Opacity</span>
                        </Col>
                        <Col span={2}>
                            <Button
                                type="text"
                                icon={<AimOutlined />}
                                onClick={() => goToLayer(item)}
                            />
                        </Col>
                        <Col span={2}>
                            <Button
                                type="text"
                                icon={<InfoCircleFilled />}
                                onClick={() => handleInfoModal(item)}
                            />
                        </Col>
                    </Row>
                    <Slider
                        defaultValue={getOpacity(item)}
                        onChange={(value) => setOpacity(item, value)}
                    />
                </div>
            )}
        </div>
    );

    const renderList = () => {
        const layer = filter(layerGroups).flatMap((group) => group.layers);
        return (
            <List
                dataSource={layer}
                renderItem={(layer) => renderLayerItem(layer)}
            />
        );
    };

    const renderGroup = () => {
        return (
            <Collapse>
                {filter(layerGroups).map((group, index) => (
                    <Panel header={group.workspace} key={index}>
                        <List
                            dataSource={group.layers}
                            renderItem={(layer) => renderLayerItem(layer)}
                        />
                    </Panel>
                ))}
            </Collapse>
        );
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                }}
            >
                <Switch
                    size="default"
                    checkedChildren={<PiListBullets />}
                    unCheckedChildren={<PiTreeStructure />}
                    onChange={() => setShowGroup(!showGroup)}
                />
                <Tooltip title={t('subtitleWidget.clearAllLayers')}>
                    <Button
                        type="text"
                        icon={<ClearOutlined />}
                        onClick={handleClearAllLayers}
                    />
                </Tooltip>
            </div>
            {showGroup ? renderGroup() : renderList()}
            {selectedLayer && (
                <LayerMetadataComponent
                    layer={selectedLayer}
                    open={openInfoModal}
                    cancel={() => setOpenInfoModal(false)}
                />
            )}
        </div>
    );
};

export default LayerCollapseListView;
