import {
    PageFormType,
    PageType,
    SavedAreaFormType,
    SavedAreaType,
    SavedAreaTypeType,
} from '../types/';
import { backendAPI } from './backendAPI';

const BASE_URL = '/saved-area';

export async function getAllSavedArea(
    queryParams: PageFormType & {
        type?: SavedAreaTypeType;
    },
): Promise<PageType<SavedAreaType>> {
    const response = await backendAPI.get<PageType<SavedAreaType>>(BASE_URL, {
        params: queryParams,
    });
    return response.data;
}

export async function createSavedArea(form: SavedAreaFormType): Promise<void> {
    return backendAPI.post(BASE_URL, form);
}

export async function deleteSavedArea(id: number): Promise<void> {
    return backendAPI.delete(`${BASE_URL}/${id}`);
}

export async function updateSavedArea(
    id: number,
    form: SavedAreaFormType,
): Promise<void> {
    return backendAPI.put(`${BASE_URL}/${id}`, form);
}
