export default {
    translation: {
        panelComponent: {
            completedMissions: {
                title: 'Missões Concluídas',
                newAreaButton: '+ Nova Área',
                all: 'Todos',
                lastMonth: 'Último Mês',
                lastThreeMonths: 'Últimos 3 Meses',
                lastSixMonths: 'Últimos 6 Meses',
                noDataMessage:
                    'Você não possui dados suficientes para exibir neste painel.',
                occurrences: {
                    title: 'Número de Ocorrências',
                    doubt: 'Número de anomalias encontradas durante as missões.',
                },
                completedQuantity: {
                    title: 'Quantidade Concluídas',
                    doubt: 'Quantidade de missões concluídas durante todo o período.',
                },
                canceledQuantity: {
                    title: 'Quantidade Canceladas',
                    doubt: 'Quantidade de missões canceladas durante todo o período.',
                },
                averageTime: {
                    title: 'Tempo Médio',
                    doubt: 'Duração total de tempo de voo das missões realizadas durante todo o período.',
                },
            },
            waitingAreas: {
                title: 'Áreas aguardando liberação de voo',
                viewAllButton: 'Ver Todas',
                noAreasMessage:
                    'Você não possui áreas aguardando liberação de voo. Tente criar uma nova área de interesse.',
            },
            upcomingMissions: {
                title: 'Próximas Missões',
                viewAllButton: 'Ver Todas',
                noMissionsMessage:
                    'Você não possui missões agendadas para realizar. Aguarde até que uma área de interesse tenha sua aprovação de voo concedida.',
            },
        },
        historyComponent: {
            title: 'Histórico de Áreas de Interesse',
            all: 'Todos',
            waiting: 'Aguardando Liberação',
            approved: 'Aprovadas para Voo',
            noMissionMessage:
                'Você não possui nenhuma área de interesse aguardando liberação de voo.',
            filter: {
                missionName: 'Nome da Missão',
                date: 'Data',
                payloadType: 'Tipo de Missão',
                ordering: 'Ordenação',
                status: 'Status',
            },
            header: {
                fileName: 'Nome do Arquivo',
                missionType: 'Tipo de Missão',
                reportType: 'Tipo de Relatório',
                date: 'Data',
                status: 'Status',
                actions: 'Ações',
            },
            actions: {
                viewDetails: 'Ver Detalhes',
                changeStatus: 'Alterar Status',
                downloadFile: 'Baixar Arquivo',
            },
            changeStatusModal: {
                title: 'Alterar Status',
                description:
                    'Para completar a alteração, preencha o formulário abaixo',
                selectLabel: 'Mudar para',
                save: 'Salvar',
                cancel: 'Não salvar',
            },
            missionMessage: {
                all: 'Nesta lista você terá todo o histórico das áreas de interesse demarcadas.',
                isMission:
                    'Nesta lista você terá todo o histórico das áreas de interesse demarcadas que encontram-se',
                isMissionStrong: 'Aprovadas para Voo',
                isNotMission:
                    'Nesta lista você terá todo o histórico das áreas de interesse demarcadas que encontram-se',
                isNotMissionStrong: 'Aguardando Liberação de Voo',
            },
        },
        menuComponent: {
            menu: 'Menu',
            map: 'Mapa',
            notifications: 'Notificações',
            history: 'Histórico',
            drones: 'Drones',
            reports: 'Relatórios',
            newArea: 'Nova Área',
            functionNotAvailable: 'Função não presente nesta versão!',
        },
        detailsComponent: {
            visualization: {
                title: 'Visualização:',
                type: 'Tipo de Visualização',
                mission: 'Missão',
                areaOfInterest: 'Área de Interesse',
            },
            generalInformation: {
                title: 'Informações Gerais',
                type: 'Tipo',
                report: 'Relatório',
                status: 'Status Liberação',
                statusMission: 'Status da Missão',
                dateTime: 'Data e Hora',
            },
            locationData: {
                title: 'Dados sobre o Local',
                city: 'Cidade',
                country: 'País',
                totalArea: 'Área Total (m2)',
                totalDistance: 'Distância total (KM)',
            },
            droneData: {
                title: 'Sobre o drone',
                noDataMessage: 'Sem dados até o momento.',
            },
        },
        areaComponent: {
            interestLocation: {
                title: 'Local de Interesse',
                selectOption: 'Selecione uma opção abaixo para continuar.',
            },
            drawOnMap: {
                button: {
                    label: 'Desenhar no Mapa',
                    icon: 'EditOutlined',
                    editLabel: 'Editar',
                },
                info: {
                    title: 'Desenho da Área',
                    description:
                        'Marque no mapa a área em que a missão será realizada.',
                },
            },
            savedLocations: {
                button: 'Locais Salvos',
                info: 'Locais Salvos',
                deleteNotification: 'Local removido com sucesso!',
                delete: 'Remover Local',
                loadMore: 'Carregar Mais',
            },
            importFile: {
                button: 'Importar Arquivo',
                info: {
                    title: 'Arquivo',
                    label: 'Selecione um Arquivo (.KML)',
                },
            },
            continueButtons: {
                cancel: 'Cancelar',
                continue: 'Continuar',
            },
            saveLocationModal: {
                title: 'Salvar local',
                content:
                    'Ao salvar este local, ele ficará disponível para seleção em missões futuras.',
                nameLabel: 'Nome do local',
                placeholder: 'Digite aqui um nome para o local',
                cancel: 'Não salvar',
                save: 'Salvar',
            },
        },
        paramsComponent: {
            aboutInterestArea: {
                title: 'Sobre a Área de Interesse',
                description: 'Preencha os campos abaixo para continuar.',
            },
            form: {
                areaName: {
                    title: 'Nome da Área',
                    placeholder: 'Nome para o polígono desenhado',
                },
                details: {
                    title: 'Detalhamento (Opcional)',
                    placeholder:
                        'Informe mais detalhes sobre a área de interesse.',
                },
            },
            nextSection: {
                back: 'Voltar',
                next: 'Próximo',
            },
        },
        configComponent: {
            aboutInterestArea: {
                title: 'Sobre a Área de Interesse',
                description: 'Conclua as informações abaixo para finalizar',
            },
            droneportSection: {
                title: 'Droneport',
                selectLabel: 'Selecione um Droneport',
            },
            dateSection: {
                date: 'Data',
                time: 'Hora',
            },
            missionSection: {
                title: 'Selecione o tipo de Missão',
            },
            reportsSection: {
                title: 'Selecione o tipo de Relatório',
            },
            nextSection: {
                back: 'Voltar',
                finish: 'Concluir',
            },
        },
        createAreaComponent: {
            title: 'Nova Área de Interesse',
            steps: {
                generalInformation: 'Informações Gerais',
                areaLocation: 'Local da Área',
                settings: 'Configurações',
            },
        },
        baseMapWidget: {
            title: 'Mapa base Atual',
            hybrid: 'Híbrido de Imagens',
            streets: 'Ruas',
            satellite: 'Satélite',
            topo: 'Topográfico',
        },
        measurementWidget: {
            title: 'Opções de Medição',
            measureDistance: 'Medir Distância',
            measureArea: 'Medir Área',
            unitSelection: {
                title: 'Unidade de Medida',
                placeholder: 'Selecione a unidade de medida',
            },
            clear: 'Limpar',
            unitsDistance: {
                metric: 'Métrico',
                imperial: 'Imperial',
                inches: 'Polegadas',
                feet: 'Pés',
                yards: 'Jardas',
                miles: 'Milhas',
                nauticalMiles: 'Milhas Náuticas',
                usFeet: 'Pés (EUA)',
                meters: 'Metros',
                kilometers: 'Quilômetros',
            },
            unitsArea: {
                metric: 'Métrico',
                imperial: 'Imperial',
                squareInches: 'Polegadas Quadradas',
                squareFeet: 'Pés Quadrados',
                squareYards: 'Jardas Quadradas',
                squareMiles: 'Milhas Quadradas',
                squareUsFeet: 'Pés Quadrados (EUA)',
                squareMeters: 'Metros Quadrados',
                squareKilometers: 'Quilômetros Quadrados',
                acres: 'Acres',
                ares: 'Ares',
                hectares: 'Hectares',
            },
        },
        boxesComponent: {
            label: 'no período selecionado',
            noDataMessage: 'Nenhum dado no período selecionado',
            oneDataMessage: 'Um dado no período selecionado',
        },
        notFoundComponent: {
            message: 'Página não encontrada!',
            return: 'Voltar para',
            home: 'Home',
        },
        viewComponent: {
            type: 'Tipo',
            status: 'Status',
            report: 'Relatório',
            datetime: 'Data e Hora',
            button: 'Acompanhar Status',
        },
        webMapComponent: {
            baseMap: 'Mapa Base',
            measurement: 'Medição',
            draw: 'Desenho',
            layer: 'Camadas',
            subtitle: 'Legenda',
            geo3DTiles: 'Camadas 3D',
        },
        enums: {
            mapping: 'Mapeamento',
            surveillance: 'Vigilância',
            environmental: 'Ambiental',
            rgbThermal: 'RGB ou Térmica',
            multispectral: 'Multispectral',
            none: 'Nenhum',
        },
        status: {
            approved: 'Aprovado',
            waiting: 'Aguardando',
            disapproved: 'Reprovado',
            accomplished: 'Realizado',
            accomplish: 'A Realizar',
            inProgress: 'Em Andamento',
            canceled: 'Cancelado',
            none: 'Nenhum',
        },
        sortOrder: {
            asc: 'Crescente',
            desc: 'Decrescente',
        },
        user: {
            logout: 'Sair',
        },
        drawWidget: {
            drawOnMap: {
                title: 'Desenhar no Mapa',
                label: 'Desenhar no Mapa',
                editLabel: 'Editar',
                clear: 'Limpar',
            },
            saveArea: {
                title: 'Salvar Área',
                description:
                    'Ao salvar esta área, ela ficará disponível para seleção em missões futuras.',
                placeholder: 'Digite aqui um nome para a área',
                save: 'Salvar',
                notification: 'Área salva com sucesso!',
            },
            savedArea: {
                title: 'Áreas Salvas',
                deleteNotification: 'Área removida com sucesso!',
                delete: 'Remover Área',
                loadMore: 'Carregar Mais',
                error: {
                    editing:
                        'A área não pode ser salva enquanto está em modo de edição. Pressione Enter para sair do modo de edição e tente novamente.',
                },
            },
        },
        layerWidget: {
            title: 'Camadas disponíveis',
            opacity: 'Opacidade',
            search: 'Buscar',
            selectInstitution: 'Selecione uma instituição',
            infoModal: {
                title: 'Informações da Camada',
                name: 'Nome',
                description: 'Descrição',
                layers: 'Camadas',
                keywords: 'Palavras-chave',
                lengend: 'Legenda',
            },
            favorite: 'Favoritar',
            allFavorites: 'Todos os favoritos',
            style: 'Estilo',
            dimension: 'Dimensão',
            notifications: {
                loadingLayer: {
                    title: 'A camada está sendo carregada',
                    description: 'Aguarde um momento...',
                },
                loadedLayer: {
                    title: 'Camada carregada',
                    description: 'A camada foi carregada com sucesso!',
                },
                failedLayer: {
                    title: 'Erro ao carregar camada',
                    description: 'Não foi possível carregar a camada.',
                },
                deleteLayer: {
                    title: 'Camada removida',
                    description: 'A camada foi removida com sucesso!',
                },
                failedLoadGeoServer: {
                    title: 'GeoServer indisponível',
                    description:
                        'Não foi possível carregar o serviço de GeoServer.',
                },
            },
        },
        geo3DTilesWidget: {
            loadMore: 'Carregar Mais',
            remove: 'Remover',
            notFound: 'Camada 3D não encontrada',
        },
        pdf: {
            title: 'Pré-Visualização de Relatório',
        },
        zoomWidget: {
            zoomIn: 'Aproximar mapa',
            zoomOut: 'Afastar mapa',
            currentLocation: 'Ir para localização atual',
            extent: 'Restaurar visualização completa',
            home: 'Focar na camada ativa',
            error: {
                noGraphicsOrActiveLayer:
                    'Nenhuma camada ativa ou gráficos encontrados.',
                currentLocation: 'Erro ao obter localização atual.',
            },
        },
        subtitleWidget: {
            activeLayers: 'Camadas Ativas',
            currentBaseMap: 'Mapa Base Atual',
            clearAllLayers: 'Limpar todas as camadas',
        },
        generic: {
            form: {
                required: 'Campo obrigatório',
            },
        },
    },
};
