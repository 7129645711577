import {
    CheckCircleFilled,
    CloseCircleFilled,
    ExclamationCircleFilled,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export const getIconStatus = (
    status: string,
    t: TFunction<'translation', undefined>,
) => {
    if (status == undefined || status == null) return '';

    switch (status.toLowerCase()) {
        case 'approved':
            return (
                <>
                    <CheckCircleFilled /> {t('status.approved')}
                </>
            );
        case 'waiting':
            return (
                <>
                    <ExclamationCircleFilled /> {t('status.waiting')}
                </>
            );
        case 'disapproved':
            return (
                <>
                    <CloseCircleFilled /> {t('status.disapproved')}
                </>
            );
        case 'accomplished':
            return (
                <>
                    <CheckCircleFilled /> {t('status.accomplished')}
                </>
            );
        case 'accomplish':
            return (
                <>
                    <ExclamationCircleFilled /> {t('status.accomplish')}
                </>
            );
        case 'in_progress':
            return (
                <>
                    <ExclamationCircleFilled /> {t('status.inProgress')}
                </>
            );
        case 'canceled':
            return (
                <>
                    <CloseCircleFilled /> {t('status.canceled')}
                </>
            );
        case 'none':
            return (
                <>
                    <ExclamationCircleFilled /> {t('status.none')}
                </>
            );
    }
};

export const getStatusWithoutIcon = (
    status: string,
    t: TFunction<'translation', undefined>,
) => {
    if (status == undefined || status == null) return '';

    switch (status.toLowerCase()) {
        case 'approved':
            return <>{t('status.approved')}</>;
        case 'waiting':
            return <>{t('status.waiting')}</>;
        case 'disapproved':
            return <>{t('status.disapproved')}</>;
        case 'none':
            return <>{t('status.none')}</>;
        case 'accomplished':
            return <>{t('status.accomplished')}</>;
        case 'accomplish':
            return <>{t('status.accomplish')}</>;
        case 'in_progress':
            return <>{t('status.inProgress')}</>;
        case 'canceled':
            return <> {t('status.canceled')}</>;
    }
};
