import React, { useEffect, useState } from 'react';

import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';

import './../WebMapComponent.scss';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Button, Col, Input, notification, Row, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { useLayers } from '../../../config/hooks/useLayers';
import { addLayerSettings, getSettings } from '../../../services';
import { LayerGroupsType, LayerItemType } from '../../../types';
import { debounce } from '../../../utils/Utils';
import { getOpacity, goToLayer, setOpacity } from '../../../utils/WebMapUtils';
import LayerCollapseListView from '../components/LayerCollapseListView';

interface IProps {
    map: ArcGISMap;
    view: MapView;
}

const LayerWidget = ({ map, view }: IProps) => {
    const { t } = useTranslation();
    const [notificationInstance, contextHolder] =
        notification.useNotification();

    const [favorite, setFavorite] = useState<Set<string>>(new Set());
    const [filterFavorite, setFilterFavorite] = useState<boolean>(false);

    const [filterSearch, setFilterSearch] = useState<string>('');
    const [selectOWS, setSelectOWS] = useState<string>('');

    const {
        layers,
        setLayers,
        geoServers,
        loading,
        checkedLayers,
        loadOWS,
        clearAllLayers,
        changeStyleLayer,
        changeDimensionLayer,
        changeLayer,
    } = useLayers(map, view, notificationInstance);

    const onFavorite = (item: LayerItemType) => {
        addLayerSettings({
            ...item,
            isFavorite: !favorite.has(item.layer),
        });

        if (favorite.has(item.layer)) {
            favorite.delete(item.layer);
        } else {
            favorite.add(item.layer);
        }
        setFavorite(new Set(favorite));
    };

    const loadSettings = () => {
        const settings = getSettings();
        const layersSettings = settings?.layer_map?.items_selected;
        if (layersSettings) {
            layersSettings.forEach((layerSettings) => {
                if (layerSettings.isFavorite) {
                    favorite.add(layerSettings.layer);
                }
            });
        }
    };

    const changeSearch = debounce((value) => {
        setFilterSearch(value ?? '');
    }, 200);

    const handleFilterFavorite = () => {
        setFilterFavorite(!filterFavorite);
        setSelectOWS('');
        if (filterFavorite) {
            setLayers([]);
            return;
        }

        const settings = getSettings();
        const layersSettings = settings?.layer_map?.items_selected;
        const favoriteLayers = layersSettings?.filter(
            (layer) => layer.isFavorite,
        );
        const favoriteLayersGroup = favoriteLayers?.reduce((acc, layer) => {
            const group = acc.find(
                (group) => group.workspace === layer.workspace,
            );
            if (!group) {
                acc.push({
                    workspace: layer.workspace,
                    layers: [layer],
                });
            } else {
                group.layers.push(layer);
            }
            return acc;
        }, [] as LayerGroupsType[]);

        if (favoriteLayers) {
            setLayers(favoriteLayersGroup ?? []);
        }
    };

    useEffect(() => {
        if (map) {
            loadSettings();
        }
    }, [map]);

    useEffect(() => {
        if (selectOWS) {
            loadOWS(selectOWS);
        } else if (!filterFavorite) {
            setSelectOWS(geoServers[0]?.url);
        }
    }, [selectOWS, geoServers, filterFavorite]);

    return (
        <section
            id="layerWidget"
            className="scroll"
            style={{
                overflowY: 'auto',
                height: 'calc(100vh - 139px)',
            }}
        >
            {contextHolder}
            <Col span={24} style={{ padding: 20 }}>
                <Row>
                    <Col span={22}>
                        <span>{t('layerWidget.title')}</span>
                    </Col>
                    <Col span={1}>
                        <Tooltip title={t('layerWidget.allFavorites')}>
                            <Button
                                type="text"
                                icon={
                                    filterFavorite ? (
                                        <HeartFilled />
                                    ) : (
                                        <HeartOutlined />
                                    )
                                }
                                onClick={handleFilterFavorite}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <br />
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <span>{t('layerWidget.selectInstitution')}</span>
                    </Col>
                    <Col span={24}>
                        <Select
                            style={{ width: '100%' }}
                            className="select"
                            placeholder={t('layerWidget.selectInstitution')}
                            dropdownStyle={{
                                minWidth: '700px',
                            }}
                            value={selectOWS}
                            onChange={setSelectOWS}
                            disabled={loading || filterFavorite}
                            options={geoServers.map((source) => ({
                                value: source.url,
                                label: source.name,
                            }))}
                        />
                    </Col>
                </Row>
                <br />
                <Input
                    placeholder={t('layerWidget.search')}
                    disabled={layers.length === 0}
                    onChange={({ target }) => changeSearch(target.value)}
                />
                <br />

                <LayerCollapseListView
                    layerGroups={layers}
                    favorite={favorite}
                    onFavorite={onFavorite}
                    changeLayer={changeLayer}
                    changeStyleLayer={changeStyleLayer}
                    changeDimensionLayer={changeDimensionLayer}
                    goToLayer={(layer) => goToLayer(view, layer.boundingBoxes)}
                    getOpacity={(layer) => getOpacity(layer, map)}
                    setOpacity={(layer, value) => setOpacity(layer, value, map)}
                    filterSearch={filterSearch}
                    checked={checkedLayers}
                    handleClearAllLayers={clearAllLayers}
                />
            </Col>
        </section>
    );
};

export default LayerWidget;
