import React from 'react';

import { ConfigProvider, theme } from 'antd';
import ReactDOM from 'react-dom/client';

import App from './App';

import "./index.scss";
import './i18n';

const AppLegacy = () => (
    <ConfigProvider
        theme={{
            algorithm: theme.darkAlgorithm,
            token: {
                colorPrimary: '#6AA944',
                colorPrimaryBorder: '#314f20',
                colorText: '#fff',
                controlItemBgActive: '#6AA944',
                colorFillSecondary: '#ffffff2e',
                colorBgBase: '#2A2A2A',
                colorBorder: '#ffffff0f',
                colorBorderBg: '#000',
            },
            components: {
                Modal: {
                    contentBg: '#2A2A2A',
                    footerBg: '#2A2A2A',
                    headerBg: '#2A2A2A',
                },
                Button: {
                    primaryShadow: '',
                    borderRadius: 24,
                },
            },
        }}
    >
        <App />
    </ConfigProvider>
);

export default AppLegacy;
