import React from 'react';

const Vector = () => {
    return (
        <svg
            id="vectorComponent"
            xmlns="http://www.w3.org/2000/svg"
            width="2"
            height="38"
            viewBox="0 0 2 38"
            fill="none"
        >
            <path d="M1 0.25V37.75" stroke="#454545" />
        </svg>
    );
};

export default Vector;
