import { Polygon } from '@arcgis/core/geometry';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine.js';

import axios from 'axios';

import { userLocation } from '../config/redux/user/types';
import { getLanguage, setVersion } from '../services/localStorageService';

export const getLocation = (): Promise<userLocation | null> => {
    return new Promise((resolve, reject) => {
        const navigatorInfo = navigator.geolocation;

        if (navigatorInfo) {
            navigatorInfo.getCurrentPosition(
                async (pos) => {
                    const latitude = pos.coords.latitude;
                    const longitude = pos.coords.longitude;

                    resolve(
                        await getLocationFromCoordinates({
                            latitude: latitude,
                            longitude: longitude,
                        }),
                    );
                },
                (error) => {
                    console.error('Erro ao obter localização:', error);
                    reject(new Error(error.message));
                },
            );
        } else {
            console.error('Geolocalização não é suportada pelo navegador.');
            reject(new Error('Geolocalização não suportada'));
        }
    });
};

export const getLocationFromCoordinates = async (param: {
    latitude: number;
    longitude: number;
}): Promise<userLocation | null> => {
    const { latitude, longitude } = param;
    try {
        const apiKey = 'baff63e6791e48be82c015fd7fb74769';
        const url = `https://api.opencagedata.com/geocode/v1/json?key=${apiKey}&q=${latitude}+${longitude}&pretty=1&no_annotations=1`;

        const language = getLanguage();
        const response = await axios.get(url, {
            headers: {
                'Accept-Language': language,
            },
        });

        const { city, city_district, state, country, town } =
            response.data.results[0].components;

        return {
            city: city || city_district || town,
            state: state,
            country: country,
            lat: latitude,
            long: longitude,
        };
    } catch (error) {
        console.error('Erro ao obter cidade e estado:', error);

        return {
            city: '',
            state: '',
            country: '',
            lat: latitude,
            long: longitude,
        };
    }
};

export default function calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
) {
    const raioTerra = 6371; // Raio médio da Terra em quilômetros

    const toRadian = (valor: number) => (Math.PI / 180) * valor;

    const dLat = toRadian(lat2 - lat1);
    const dLon = toRadian(lon2 - lon1);

    // Fórmula da Haversine
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadian(lat1)) *
            Math.cos(toRadian(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distância em quilômetros
    const distancia = raioTerra * c;

    return distancia;
}

export interface Coordinate {
    lat: number;
    long: number;
}

export function calculateCentroid(
    coordinates: Coordinate[],
): Coordinate | null {
    if (coordinates.length < 3) {
        return null;
    }

    let xSum = 0;
    let ySum = 0;
    let area = 0;

    for (let i = 0; i < coordinates.length - 1; i++) {
        const xi = coordinates[i].lat;
        const yi = coordinates[i].long;
        const xi1 = coordinates[i + 1].lat;
        const yi1 = coordinates[i + 1].long;

        const commonFactor = xi * yi1 - xi1 * yi;
        xSum += (xi + xi1) * commonFactor;
        ySum += (yi + yi1) * commonFactor;
        area += commonFactor;
    }

    const x0 = coordinates[0].lat;
    const y0 = coordinates[0].long;
    const xn = coordinates[coordinates.length - 1].lat;
    const yn = coordinates[coordinates.length - 1].long;

    const commonFactor = x0 * yn - xn * y0;
    xSum += (x0 + xn) * commonFactor;
    ySum += (y0 + yn) * commonFactor;
    area += commonFactor;

    area *= 0.5;

    const centroidLatitude = xSum / (2 * area);
    const centroidLongitude = ySum / (2 * area);

    return { lat: centroidLatitude, long: centroidLongitude };
}

export function calculatePolygonCenter(
    vertices: Coordinate[],
): Coordinate | null {
    if (vertices.length < 3) {
        return null;
    }

    let sumLatitude = 0;
    let sumLongitude = 0;

    for (const element of vertices) {
        sumLatitude += element.lat;
        sumLongitude += element.long;
    }

    const centerLatitude = sumLatitude / vertices.length;
    const centerLongitude = sumLongitude / vertices.length;

    return { lat: centerLatitude, long: centerLongitude };
}

export function calculatePolygonArea(coordinates: number[][]): number {
    if (coordinates.length < 3) {
        return 0;
    }

    const polygon = new Polygon({
        rings: [coordinates],
    });

    const area = geometryEngine.geodesicArea(polygon, 'square-meters');

    return Math.abs(area);
}

export function translateEnums(data: string): string {
    switch (data) {
        case 'MAPPING':
            return 'enums.mapping';
        case 'SURVEILLANCE':
            return 'enums.surveillance';
        case 'ENVIRONMENTAL':
            return 'enums.environmental';
        case 'RGB_THERMAL':
            return 'enums.rgbThermal';
        case 'MULTISPECTRAL':
            return 'enums.multispectral';
        case 'NONE':
            return 'enums.none';
        default:
            return 'enums.none';
    }
}

export const checkDate = (inputDatetimeString: string): string | null => {
    const [datePart, timePart] = inputDatetimeString.split(' ');
    const [day, month, year] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);

    const inputDatetime = new Date(year, month - 1, day, hours, minutes);

    if (isNaN(inputDatetime.getTime())) {
        return null;
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    const sevenDaysLater = new Date(currentDate);
    sevenDaysLater.setDate(currentDate.getDate() + 7);

    const fifteenDaysLater = new Date(currentDate);
    fifteenDaysLater.setDate(currentDate.getDate() + 15);

    const thirtyDaysLater = new Date(currentDate);
    thirtyDaysLater.setDate(currentDate.getDate() + 30);

    const thirtyOneDaysLater = new Date(currentDate);
    thirtyDaysLater.setDate(currentDate.getDate() + 31);

    if (
        inputDatetime.getTime() > yesterday.getTime() &&
        inputDatetime.getTime() < sevenDaysLater.getTime()
    ) {
        return 'Acontecerá em breve';
    } else if (
        inputDatetime.getTime() >= sevenDaysLater.getTime() &&
        inputDatetime.getTime() < fifteenDaysLater.getTime()
    ) {
        return 'Acontecerá em 7 dias';
    } else if (
        inputDatetime.getTime() >= fifteenDaysLater.getTime() &&
        inputDatetime.getTime() < thirtyDaysLater.getTime()
    ) {
        return 'Acontecerá em 15 dias';
    } else if (inputDatetime.getTime() == thirtyDaysLater.getTime()) {
        return 'Acontecerá em 30 dias';
    } else if (inputDatetime.getTime() >= thirtyOneDaysLater.getTime()) {
        return 'Acontecerá em +30 dias';
    } else {
        return null;
    }
};

export const debounce = (func: (_?: string) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (value?: string) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(value), delay);
    };
};

export const getVersion = (): string => {
    const version = process.env.CURRENT_RELEASE || '0.0.0';
    setVersion(version);
    return version;
};
