import { AxiosResponse } from 'axios';

import { MissionInformationType, MissionStatusType } from '../types';
import { backendAPI } from './backendAPI';

const BASE_URL = '/missions';

export async function updateStatusMission(
    id: number,
    status: MissionStatusType,
): Promise<AxiosResponse<void, any>> {
    return await backendAPI.patch<void>(`${BASE_URL}/${id}/status`, {
        status: status,
    });
}

export async function getMissionInformation(queryParams: {
    monthsBeforeNow?: number;
}): Promise<MissionInformationType> {
    const response = await backendAPI.get<MissionInformationType>(
        `${BASE_URL}/information`,
        {
            params: queryParams,
        },
    );
    return response.data;
}
