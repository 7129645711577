import React, { useEffect, useState } from 'react';

import MapMiddleware from '../../middleware/MapMiddleware';
import Basemap from '@arcgis/core/Basemap';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Measurement from '@arcgis/core/widgets/Measurement';

import {
    BlockOutlined,
    BuildOutlined,
    CloseCircleFilled,
    EditOutlined,
    EnvironmentOutlined,
    GatewayOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { Col, Divider, Layout, Menu, Row, Tooltip } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { useTranslation } from 'react-i18next';

import './WebMapComponent.scss';
import { getSettings } from '../../services/localStorageService';
import BaseMapWidget from './widgets/BaseMapWidget';
import DrawWidget from './widgets/DrawWidget';
import Geo3DTilesWidget from './widgets/Geo3DTilesWidget';
import LayerWidget from './widgets/LayerWidget';
import MeasurementWidget from './widgets/MeasurementWidget';
import NavigationButtonWidget from './widgets/NavigationButtonWidget';
import SubtitleWidget from './widgets/SubtitleWidget';
import ZoomWidget from './widgets/ZoomWidget';

import SceneView from '@arcgis/core/views/SceneView';
import WebScene from '@arcgis/core/WebScene';

interface WebMapProps {
    location?: number[];
    toolbar?: boolean;
}

interface IMenu {
    title: string;
    key: string;
    icon: React.JSX.Element;
    component: React.JSX.Element;
}

const WebMap = (props: WebMapProps) => {
    const { t } = useTranslation();
    const [map, setMap] = useState<ArcGISMap>();
    const [view, setView] = useState<MapView | null>(null);
    const [map3D, setMap3D] = useState<WebScene>();
    const [view3D, setView3D] = useState<SceneView | null>(null);
    const [viewMode, setViewMode] = useState<'2D' | '3D'>('2D');
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<IMenu>();
    const [menus, setMenus] = useState<IMenu[]>([]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
        if (collapsed) {
            setSelectedItem(undefined);
        }
    };

    useEffect(() => {
        if (!map || !view) {
            return;
        }

        const measurement = new Measurement({
            view: view,
        });

        const data: IMenu[] = [
            {
                title: 'webMapComponent.baseMap',
                key: '1',
                icon: <EnvironmentOutlined />,
                component: <BaseMapWidget map={map} />,
            },
            {
                title: 'webMapComponent.measurement',
                key: '2',
                icon: <GatewayOutlined />,
                component: <MeasurementWidget measurement={measurement} />,
            },
            {
                title: 'webMapComponent.draw',
                key: '3',
                icon: <EditOutlined />,
                component: <DrawWidget />,
            },
            {
                title: 'webMapComponent.layer',
                key: '4',
                icon: <BlockOutlined />,
                component: <LayerWidget map={map} view={view} />,
            },
            {
                title: 'webMapComponent.geo3DTiles',
                key: '6',
                icon: <BuildOutlined />,
                component: (
                    <Geo3DTilesWidget
                        setView3D={setView3D}
                        setMap3D={setMap3D}
                    />
                ),
            },
            {
                title: 'webMapComponent.subtitle',
                key: '5',
                icon: <InfoCircleOutlined />,
                component: <SubtitleWidget map={map} view={view} />,
            },
        ];
        setMenus(data);
        setSelectedItem(data.find((menu) => menu.key === selectedItem?.key));
    }, [viewMode, map, view]);

    const handleMenuClick = (item: { key: string }) => {
        if (item.key === '6') {
            setViewMode('3D');
        } else {
            setViewMode('2D');
        }
        setSelectedItem(menus.find((menu) => menu.key === item.key));
        if (item.key == selectedItem?.key || !collapsed) {
            toggleCollapsed();
        }
    };

    const loadSettings = () => {
        const settings = getSettings();
        if (settings?.web_map?.base_map && map) {
            map.basemap = Basemap.fromId(settings.web_map.base_map);
        }
    };

    useEffect(() => {
        loadSettings();
    }, [map]);

    return (
        <section id="webMapComponent">
            {props.toolbar !== false && (
                <div id="webMapComponent__Menu">
                    <Layout>
                        <Sider
                            width={60}
                            theme={'dark'}
                            style={{
                                background: '#2a2a2a',
                                height: '100vh',
                                position: 'fixed',
                            }}
                        >
                            <Menu
                                mode="vertical"
                                theme="dark"
                                style={{
                                    background: '#2a2a2a',
                                }}
                                onClick={handleMenuClick}
                                selectedKeys={
                                    selectedItem ? [selectedItem.key] : []
                                }
                            >
                                {menus.map((menu: IMenu) => (
                                    <Menu.Item
                                        key={menu.key}
                                        style={{ paddingRight: 0 }}
                                    >
                                        <Tooltip
                                            title={t(menu.title)}
                                            placement="right"
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '100%',
                                                }}
                                            >
                                                {menu.icon}
                                            </div>
                                        </Tooltip>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </Sider>
                        <Layout>
                            <Content
                                style={{
                                    marginLeft: '60px',
                                    width: collapsed ? '400px' : '0px',
                                    background: '#ffffff0f',
                                    height: 'calc(100vh - 70px)',
                                }}
                            >
                                {collapsed && selectedItem && (
                                    <>
                                        <Row style={{ padding: '24px' }}>
                                            <Col span={23}>
                                                <h2>{t(selectedItem.title)}</h2>
                                            </Col>
                                            <Col span={1}>
                                                <CloseCircleFilled
                                                    onClick={toggleCollapsed}
                                                />
                                            </Col>
                                        </Row>
                                        <Divider
                                            style={{ padding: 0, margin: 0 }}
                                        />
                                        {selectedItem.component}
                                    </>
                                )}
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            )}
            {viewMode === '2D' && (
                <>
                    <ZoomWidget view={view} map={map} />
                    <NavigationButtonWidget view={view} />
                </>
            )}
            {viewMode === '3D' && (
                <>
                    <ZoomWidget view={view3D} map={map3D} />
                    <NavigationButtonWidget view={view3D} />
                </>
            )}
            {viewMode === '2D' && (
                <MapMiddleware
                    location={props.location}
                    setMap={setMap}
                    setView={setView}
                />
            )}
            {viewMode === '3D' && <div id="viewDiv" />}
        </section>
    );
};

export default WebMap;
