import { LayerItemType } from '../types';

export type LanguageType = 'pt-BR' | 'en-US' | 'es-ES';

interface ILocalStorage {
    version?: string;
    web_map?: {
        base_map?: string;
    };
    language?: LanguageType;
    layer_map?: {
        items_selected?: LayerItemType[];
    };
}

const nameConfig = '@sama-settings';

export const getSettings = (): ILocalStorage | undefined => {
    const data = localStorage.getItem(nameConfig);
    return data ? JSON.parse(data) : undefined;
};

export const setSettings = (data: ILocalStorage): void => {
    const settingsData = getSettings() || {};
    const newData = { ...settingsData, ...data };
    localStorage.setItem(nameConfig, JSON.stringify(newData));
};

export const addLayerSettings = (layer: LayerItemType): void => {
    try {
        const settingsData = getSettings();
        const existingLayers = settingsData?.layer_map?.items_selected || [];
        const updatedLayers = existingLayers.filter(
            (item) => item.layer !== layer.layer,
        );
        const oldData = existingLayers.find(
            (item) => item.layer === layer.layer,
        );

        updatedLayers.push({
            ...layer,
            checked: layer.checked ?? oldData?.checked,
            opacity: layer.opacity ?? oldData?.opacity,
            isFavorite: layer.isFavorite ?? oldData?.isFavorite,
            boundingBoxes: layer.boundingBoxes ?? oldData?.boundingBoxes,
        });

        const newData: ILocalStorage = {
            ...settingsData,
            layer_map: { items_selected: updatedLayers },
        };

        localStorage.setItem(nameConfig, JSON.stringify(newData));
    } catch (error) {
        console.error('Erro ao adicionar camada:', error);
        removeAllLayerSettings();
    }
};

export const removeAllLayerSettings = (): void => {
    const settingsData = getSettings();
    if (settingsData) {
        const newData: ILocalStorage = {
            ...settingsData,
            layer_map: { items_selected: [] },
        };
        localStorage.setItem(nameConfig, JSON.stringify(newData));
    }
};

export const removeSettings = (): void => {
    localStorage.removeItem(nameConfig);
};

export const getLanguage = (): LanguageType => {
    const settings = getSettings();
    return settings?.language ?? 'pt-BR';
};

export const setLanguageSettings = (language: LanguageType): void => {
    setSettings({ language });
};

export const setVersion = (version: string): void => {
    const currentVersion = getSettings()?.version;
    if (currentVersion !== version) {
        removeSettings();
        setSettings({ version });
    }
};
