import React, { useEffect, useState } from 'react';

import { RootState } from '../../../config/redux/store';
import { useDispatch, useSelector } from 'react-redux';

import {
    ClearOutlined,
    DeleteFilled,
    EditOutlined,
    HighlightOutlined,
    ReloadOutlined,
    SaveFilled,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Divider,
    Form,
    FormProps,
    Input,
    List,
    notification,
    Row,
    Spin,
    Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';

import {
    addArea,
    changeDrawing,
    changeOption,
    changeUpdating,
    resetDraw,
} from '../../../config/redux/area/slice';
import {
    createSavedArea,
    deleteSavedArea,
    getAllSavedArea,
    updateSavedArea,
} from '../../../services';
import { PageType, PointFormType, SavedAreaType } from '../../../types';
import { getErrorField } from '../../../utils/ErrorField';

type FieldType = {
    name: string;
};

const DrawWidget = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm<FieldType>();
    const [savedArea, setSavedArea] = useState<SavedAreaType | null>(null);
    const [editing, setEditing] = useState(false);

    const [notificationInstance, contextHolder] =
        notification.useNotification();

    const { area_geojson, area_updating } = useSelector(
        (state: RootState) => state.area,
    );

    const [savedAreas, setSavedAreas] = useState<PageType<SavedAreaType>>();
    const [loading, setLoading] = useState(false);

    const handleClear = () => {
        dispatch(resetDraw());
        setSavedArea(null);
        setEditing(false);
        form.setFieldsValue({ name: '' });
    };

    const handleDraw = () => {
        setSavedArea(null);
        setEditing(false);
        dispatch(
            changeOption({
                area_option: 1,
            }),
        );
        dispatch(
            changeDrawing({
                area_drawing: true,
            }),
        );
    };

    const handleEditing = () => {
        setEditing(true);
        if (savedArea) {
            form.setFieldsValue({
                name: savedArea.name,
            });
        }
        dispatch(
            changeUpdating({
                area_updating: true,
            }),
        );
        dispatch(
            changeDrawing({
                area_drawing: false,
            }),
        );
    };
    const handleOpenArea = (savedAreaType: SavedAreaType) => {
        setSavedArea(savedAreaType);
        dispatch(
            changeDrawing({
                area_drawing: false,
            }),
        );
        dispatch(
            addArea({
                area_coordinates: savedAreaType.area.coordinates,
            }),
        );
    };

    const handleSaveArea: FormProps<FieldType>['onFinish'] = (values) => {
        if (!area_geojson) return;
        if (loading) return;

        if (area_updating) {
            notificationInstance.error({
                message: t('drawWidget.savedArea.error.editing'),
            });
            return;
        }

        setLoading(true);

        const points =
            area_geojson?.map((point) => {
                return {
                    x: Number(point[0]),
                    y: Number(point[1]),
                } as PointFormType;
            }) ?? [];

        if (editing && savedArea) {
            updateSavedArea(savedArea.id, {
                name: values.name,
                type: 'GIS',
                points: points,
            })
                .then(successSaveArea)
                .catch(({ response }) => getErrorField(response.data, form))
                .finally(finallySaveArea);
        } else {
            createSavedArea({
                name: values.name,
                type: 'GIS',
                points: points,
            })
                .then(successSaveArea)
                .catch(({ response }) => getErrorField(response.data, form))
                .finally(finallySaveArea);
        }
    };

    const finallySaveArea = () => {
        setLoading(false);
        loadData();
    };
    const successSaveArea = () => {
        notificationInstance.success({
            message: t('drawWidget.saveArea.notification'),
        });
        form.resetFields();
        setSavedArea(null);
        setEditing(false);
        handleClear();
    };

    const deleteArea = (id: number) => {
        setLoading(true);
        deleteSavedArea(id)
            .then(() => {
                notificationInstance.success({
                    message: t('drawWidget.savedArea.deleteNotification'),
                });
                loadData();
            })
            .finally(() => setLoading(false));
    };

    const loadData = () => {
        getAllSavedArea({
            type: 'GIS',
            size: 5,
        }).then(setSavedAreas);
    };

    const loadMore = () => {
        if (!savedAreas) return;
        if (loading) return;
        setLoading(true);
        getAllSavedArea({
            type: 'GIS',
            page: savedAreas?.number + 1,
            size: 5,
        })
            .then((response) => {
                setSavedAreas((prevState) => {
                    return {
                        ...response,
                        page: savedAreas?.number + 1,
                        content: [
                            ...(prevState?.content ?? []),
                            ...response.content,
                        ],
                    };
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <section
            id="drawWidget"
            className="scroll"
            style={{
                overflowY: 'auto',
                height: 'calc(100vh - 139px)',
            }}
        >
            {contextHolder}
            <Col style={{ padding: '24px' }}>
                <Col span={24}>
                    <span>{t('drawWidget.drawOnMap.title')}</span>
                </Col>
                <br />
                {!area_geojson && (
                    <button
                        style={{ width: '100%' }}
                        className="input text-align-left"
                        onClick={handleDraw}
                    >
                        <HighlightOutlined style={{ marginRight: '8px' }} />{' '}
                        {t('drawWidget.drawOnMap.label')}
                    </button>
                )}
                {area_geojson && (
                    <>
                        <button
                            style={{ width: '100%', marginTop: '8px' }}
                            className="input text-align-left"
                            onClick={handleEditing}
                        >
                            <EditOutlined style={{ marginRight: '8px' }} />{' '}
                            {t('drawWidget.drawOnMap.editLabel')}
                        </button>
                        <button
                            style={{ width: '100%', marginTop: '8px' }}
                            className="input text-align-left"
                            onClick={handleClear}
                        >
                            <ClearOutlined style={{ marginRight: '8px' }} />{' '}
                            {t('drawWidget.drawOnMap.clear')}
                        </button>
                    </>
                )}
            </Col>
            {area_geojson && (
                <>
                    <Divider style={{ padding: 0, margin: 0 }} />
                    <Col span={24} style={{ padding: 24 }}>
                        <Col span={24}>
                            <span>{t('drawWidget.saveArea.title')}</span>
                            <p style={{ fontSize: '13px' }}>
                                {t('drawWidget.saveArea.description')}
                            </p>
                        </Col>
                        <Form
                            name="basic"
                            onFinish={handleSaveArea}
                            form={form}
                        >
                            <Form.Item<FieldType>
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: t('generic.form.required'),
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="input"
                                    placeholder={t(
                                        'drawWidget.saveArea.placeholder',
                                    )}
                                />
                            </Form.Item>
                            <button
                                style={{ width: '100%' }}
                                className="button-green"
                            >
                                {loading ? (
                                    <ReloadOutlined
                                        style={{ marginRight: '8px' }}
                                    />
                                ) : (
                                    <SaveFilled
                                        style={{ marginRight: '8px' }}
                                    />
                                )}{' '}
                                {t('drawWidget.saveArea.save')}
                            </button>
                        </Form>
                    </Col>
                </>
            )}
            <Divider style={{ padding: 0, margin: 0 }} />
            <Col span={24} style={{ padding: 24 }}>
                <h5>{t('drawWidget.savedArea.title')}</h5>
                <br />
                <List
                    dataSource={savedAreas?.content}
                    grid={{ gutter: 16, column: 1 }}
                    loading={loading}
                    loadMore={
                        !savedAreas?.last && (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: 12,
                                    height: 32,
                                    lineHeight: '32px',
                                }}
                            >
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <Button onClick={loadMore}>
                                        {t('drawWidget.savedArea.loadMore')}
                                    </Button>
                                )}
                            </div>
                        )
                    }
                    renderItem={(item) => (
                        <List.Item>
                            <Button
                                className="input"
                                onClick={() => handleOpenArea(item)}
                            >
                                <Row>
                                    <Col span={22}>
                                        <span>{item.name}</span>
                                    </Col>
                                    <Col span={2}>
                                        <Tooltip
                                            title={t(
                                                'drawWidget.savedArea.delete',
                                            )}
                                        >
                                            <Button
                                                type="text"
                                                icon={<DeleteFilled />}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    deleteArea(item.id);
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Button>
                        </List.Item>
                    )}
                />
            </Col>
        </section>
    );
};

export default DrawWidget;
