import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TbFaceIdError } from 'react-icons/tb';

import './NotFound.scss';

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <div id="notFoundComponent">
            <TbFaceIdError />

            <br className="space" />

            <h1>{t('notFoundComponent.message')}</h1>

            <br className="small-space" />

            <Link to="/">
                {t('notFoundComponent.return')}{' '}
                <b>{t('notFoundComponent.home')}</b>
            </Link>
        </div>
    );
};

export default NotFound;
