export default {
    translation: {
        panelComponent: {
            completedMissions: {
                title: 'Completed Missions',
                newAreaButton: '+ New Area',
                all: 'All',
                lastMonth: 'Last Month',
                lastThreeMonths: 'Last 3 Months',
                lastSixMonths: 'Last 6 Months',
                noDataMessage:
                    'You do not have enough data to display on this panel.',
                occurrences: {
                    title: 'Number of Occurrences',
                    doubt: 'Number of anomalies found during missions.',
                },
                completedQuantity: {
                    title: 'Completed Quantity',
                    doubt: 'Quantity of missions completed throughout the period.',
                },
                canceledQuantity: {
                    title: 'Canceled Quantity',
                    doubt: 'Quantity of missions canceled throughout the period.',
                },
                averageTime: {
                    title: 'Average Time',
                    doubt: 'Total flight time duration of missions conducted throughout the period.',
                },
            },
            waitingAreas: {
                title: 'Areas Waiting for Flight Clearance',
                viewAllButton: 'View All',
                noAreasMessage:
                    'You do not have areas waiting for flight clearance. Try creating a new area of interest.',
            },
            upcomingMissions: {
                title: 'Upcoming Missions',
                viewAllButton: 'View All',
                noMissionsMessage:
                    'You do not have any scheduled missions. Wait until an area of interest has its flight approval granted.',
            },
        },
        historyComponent: {
            title: 'History of Areas of Interest',
            all: 'All',
            waiting: 'Waiting for Clearance',
            approved: 'Approved for Flight',
            noMissionMessage:
                'You do not have any areas of interest waiting for flight clearance.',
            filter: {
                missionName: 'Mission Name',
                date: 'Date',
                payloadType: 'Mission Type',
                ordering: 'Ordering',
                status: 'Status',
            },
            header: {
                fileName: 'File Name',
                missionType: 'Mission Type',
                reportType: 'Report Type',
                date: 'Date',
                status: 'Status',
                actions: 'Actions',
            },
            actions: {
                viewDetails: 'View Details',
                changeStatus: 'Change Status',
                downloadFile: 'Download File',
            },
            changeStatusModal: {
                title: 'Change Status',
                description: 'To complete the change, fill out the form below',
                selectLabel: 'Change to',
                save: 'Save',
                cancel: 'Do not save',
            },
            missionMessage: {
                all: 'In this list, you will have the complete history of marked areas of interest.',
                isMission:
                    'In this list, you will have the complete history of marked areas of interest that are',
                isMissionStrong: 'Approved for Flight',
                isNotMission:
                    'In this list, you will have the complete history of marked areas of interest that are',
                isNotMissionStrong: 'Waiting for Flight Clearance',
            },
        },
        menuComponent: {
            menu: 'Menu',
            map: 'Map',
            notifications: 'Notifications',
            history: 'History',
            drones: 'Drones',
            reports: 'Reports',
            newArea: 'New Area',
            functionNotAvailable: 'Function not available in this version!',
        },
        detailsComponent: {
            visualization: {
                title: 'Visualization:',
                type: 'Visualization Type',
                mission: 'Mission',
                areaOfInterest: 'Area of Interest',
            },
            generalInformation: {
                title: 'General Information',
                type: 'Type',
                report: 'Report',
                status: 'Release Status',
                statusMission: 'Mission Status',
                dateTime: 'Date and Time',
            },
            locationData: {
                title: 'Location Data',
                city: 'City',
                country: 'Country',
                totalArea: 'Total Area (m2)',
                totalDistance: 'Total Distance (KM)',
            },
            droneData: {
                title: 'About the Drone',
                noDataMessage: 'No data available at the moment.',
            },
        },
        areaComponent: {
            interestLocation: {
                title: 'Area of Interest',
                selectOption: 'Select an option below to continue.',
            },
            drawOnMap: {
                button: {
                    label: 'Draw on Map',
                    icon: 'EditOutlined',
                    editLabel: 'Edit',
                },
                info: {
                    title: 'Area Drawing',
                    description:
                        'Mark on the map the area where the mission will be conducted.',
                },
            },
            savedLocations: {
                button: 'Saved Locations',
                info: 'Saved Locations',
                deleteNotification: 'Location removed successfully!',
                delete: 'Remove Location',
                loadMore: 'Load More',
            },
            importFile: {
                button: 'Import File',
                info: {
                    title: 'File',
                    label: 'Select a File (.KML)',
                },
            },
            continueButtons: {
                cancel: 'Cancel',
                continue: 'Continue',
            },
            saveLocationModal: {
                title: 'Save Location',
                content:
                    'By saving this location, it will be available for selection in future missions.',
                nameLabel: 'Location Name',
                placeholder: 'Enter a name for the location here',
                cancel: 'Do not save',
                save: 'Save',
            },
        },
        paramsComponent: {
            aboutInterestArea: {
                title: 'About the Area of Interest',
                description: 'Fill in the fields below to continue.',
            },
            form: {
                areaName: {
                    title: 'Area Name',
                    placeholder: 'Name for the drawn polygon',
                },
                details: {
                    title: 'Details (Optional)',
                    placeholder:
                        'Provide more details about the area of interest.',
                },
            },
            nextSection: {
                back: 'Back',
                next: 'Next',
            },
        },
        configComponent: {
            aboutInterestArea: {
                title: 'About the Area of Interest',
                description: 'Complete the information below to finish',
            },
            droneportSection: {
                title: 'Droneport',
                selectLabel: 'Select a Droneport',
            },
            dateSection: {
                date: 'Date',
                time: 'Time',
            },
            missionSection: {
                title: 'Select Mission Type',
            },
            reportsSection: {
                title: 'Select Report Type',
            },
            nextSection: {
                back: 'Back',
                finish: 'Finish',
            },
        },
        createAreaComponent: {
            title: 'New Area of Interest',
            steps: {
                generalInformation: 'General Information',
                areaLocation: 'Area Location',
                settings: 'Settings',
            },
        },
        baseMapWidget: {
            title: 'Current Base Map',
            hybrid: 'Hybrid',
            streets: 'Streets',
            satellite: 'Satellite',
            topo: 'Topographic',
        },
        measurementWidget: {
            title: 'Measurement Options',
            measureDistance: 'Measure Distance',
            measureArea: 'Measure Area',
            unitSelection: {
                title: 'Unit of Measurement',
                placeholder: 'Select the unit of measurement',
            },
            clear: 'Clear',
            unitsDistance: {
                metric: 'Metric',
                imperial: 'Imperial',
                inches: 'Inches',
                feet: 'Feet',
                yards: 'Yards',
                miles: 'Miles',
                nauticalMiles: 'Nautical Miles',
                usFeet: 'Feet (US)',
                meters: 'Meters',
                kilometers: 'Kilometers',
            },
            unitsArea: {
                metric: 'Metric',
                imperial: 'Imperial',
                squareInches: 'Square Inches',
                squareFeet: 'Square Feet',
                squareYards: 'Square Yards',
                squareMiles: 'Square Miles',
                squareUsFeet: 'Square Feet (US)',
                squareMeters: 'Square Meters',
                squareKilometers: 'Square Kilometers',
                acres: 'Acres',
                ares: 'Ares',
                hectares: 'Hectares',
            },
        },
        boxesComponent: {
            label: 'in the selected period',
            noDataMessage: 'No data in the selected period',
            oneDataMessage: 'One data in the selected period',
        },
        notFoundComponent: {
            message: 'Page not found!',
            return: 'Return to',
            home: 'Home',
        },
        viewComponent: {
            type: 'Type',
            status: 'Status',
            report: 'Report',
            datetime: 'Date and Time',
            button: 'Track Status',
        },
        webMapComponent: {
            baseMap: 'Base Map',
            measurement: 'Measurement',
            draw: 'Draw',
            layer: 'Layers',
            subtitle: 'Subtitle',
            geo3DTiles: 'Layers 3D',
        },
        enums: {
            mapping: 'Mapping',
            surveillance: 'Surveillance',
            environmental: 'Environmental',
            rgbThermal: 'RGB or Thermal',
            multispectral: 'Multispectral',
            none: 'None',
        },
        status: {
            approved: 'Approved',
            waiting: 'Waiting',
            disapproved: 'Disapproved',
            accomplished: 'Accomplished',
            accomplish: 'To Be Done',
            inProgress: 'In Progress',
            canceled: 'Canceled',
            none: 'None',
        },
        sortOrder: {
            asc: 'Ascending',
            desc: 'Descending',
        },
        user: {
            logout: 'Logout',
        },
        drawWidget: {
            drawOnMap: {
                title: 'Draw on Map',
                label: 'Draw on Map',
                editLabel: 'Edit',
                clear: 'Clear',
            },
            saveArea: {
                title: 'Save Area',
                description:
                    'By saving this area, it will be available for selection in future missions.',
                placeholder: 'Enter a name for the area here',
                save: 'Save',
                notification: 'Area saved successfully!',
            },
            savedArea: {
                title: 'Saved Areas',
                deleteNotification: 'Area removed successfully!',
                delete: 'Remove Area',
                loadMore: 'Load More',
                error: {
                    editing:
                        'The area cannot be saved while in edit mode. Press Enter to exit edit mode and try again.',
                },
            },
        },
        layerWidget: {
            title: 'Available Layers',
            opacity: 'Opacity',
            search: 'Search',
            selectInstitution: 'Select an institution',
            infoModal: {
                title: 'Layer Information',
                name: 'Name',
                description: 'Description',
                layers: 'Layers',
                keywords: 'Keywords',
                lengend: 'Legend',
            },
            favorite: 'Favorite',
            allFavorites: 'All Favorites',
            style: 'Style',
            dimension: 'Dimension',
            notifications: {
                loadingLayer: {
                    title: 'Layer is being loaded',
                    description: 'Please wait...',
                },
                loadedLayer: {
                    title: 'Layer loaded',
                    description: 'The layer was loaded successfully!',
                },
                failedLayer: {
                    title: 'Error loading layer',
                    description: 'The layer could not be loaded.',
                },
                deleteLayer: {
                    title: 'Layer removed',
                    description: 'The layer was removed successfully!',
                },
                failedLoadGeoServer: {
                    title: 'GeoServer unavailable',
                    description: 'Failed to load GeoServer service.',
                },
            },
        },
        geo3DTilesWidget: {
            loadMore: 'Load More',
            remove: 'Remove',
            notFound: '3D layer not found',
        },
        pdf: {
            title: 'Report Preview',
        },
        zoomWidget: {
            zoomIn: 'Zoom in',
            zoomOut: 'Zoom out',
            currentLocation: 'Go to current location',
            extent: 'Restore full extent',
            home: 'Focus on active layer',
            error: {
                noGraphicsOrActiveLayer: 'No active layer or graphics found.',
                currentLocation: 'Error getting current location.',
            },
        },
        subtitleWidget: {
            activeLayers: 'Active Layers',
            currentBaseMap: 'Current Base Map',
            clearAllLayers: 'Clear all layers',
        },
        generic: {
            form: {
                required: 'Required field',
            },
        },
    },
};
