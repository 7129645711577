import { AxiosResponse } from 'axios';

import {
    AreaOfInterestType,
    FlightApprovalStatusType,
    MissionStatusType,
    PageFormType,
    PageType,
    PayloadTypeType,
} from '../types';
import { backendAPI } from './backendAPI';

const BASE_URL = '/area-of-interest';

export async function getAllAreaOfInterest(
    queryParams: PageFormType & {
        flightApprovalStatus?: FlightApprovalStatusType;
        payloadType?: PayloadTypeType;
        missionStatus?: MissionStatusType;
        name?: string;
        date?: string;
        isMission?: boolean;
        monthsBeforeNow?: number;
    },
): Promise<PageType<AreaOfInterestType>> {
    const response = await backendAPI.get<PageType<AreaOfInterestType>>(
        BASE_URL,
        {
            params: queryParams,
        },
    );
    return response.data;
}

export async function updateFlightApprovalStatus(
    id: number,
    flightApprovalStatus: FlightApprovalStatusType,
): Promise<AxiosResponse<void, any>> {
    return await backendAPI.patch<void>(
        `${BASE_URL}/${id}/flight-approval-status`,
        {
            flightApprovalStatus: flightApprovalStatus,
        },
    );
}

export async function getByIdAreaOfInterest(
    id: number,
): Promise<AreaOfInterestType> {
    const response = await backendAPI.get<AreaOfInterestType>(
        `${BASE_URL}/${id}`,
    );
    return response.data;
}
