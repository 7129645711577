import React, { useEffect, useState } from 'react';

import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';
import ZoomWiget from '@arcgis/core/widgets/Zoom';

import {
    AimOutlined,
    ExpandAltOutlined,
    HomeOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, notification, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { getSettings } from '../../../services';
import { getLocation } from '../../../utils/Utils';
import { getExtentLayer } from '../../../utils/WebMapUtils';

interface IProps {
    view?: MapView | SceneView | null;
    map?: ArcGISMap;
}

const ZoomWidget = ({ map, view }: IProps) => {
    const { t } = useTranslation();
    const [notificationInstance, contextHolder] =
        notification.useNotification();

    const [loading, setLoading] = useState<boolean>(false);
    const [zoom, setZoom] = useState<ZoomWiget>();

    useEffect(() => {
        if (view) {
            setZoom(
                new ZoomWiget({
                    view: view,
                }),
            );
        }
    }, [view]);

    const handleZoomIn = () => {
        zoom?.zoomIn();
    };

    const handleZoomOut = () => {
        zoom?.zoomOut();
    };

    const handleZoomExtent = () => {
        if (view) {
            setLoading(true);
            view.goTo({ zoom: 0 }, { animate: true }).finally(() =>
                setLoading(false),
            );
        }
    };

    const handleZoomCurrentLocation = async () => {
        if (!view) return;
        setLoading(true);
        try {
            const userLocation = await getLocation();
            if (!userLocation?.lat || !userLocation?.long) return;
            view.goTo(
                { center: [userLocation.long, userLocation.lat], zoom: 15 },
                { animate: true },
            ).finally(() => setLoading(false));
        } catch (error) {
            notificationInstance.error({
                message: t('zoomWidget.error.currentLocation'),
            });
        } finally {
            setLoading(false);
        }
    };

    const handleZoomHome = () => {
        if (!map || !view) return;

        const graphicsLayers = map.layers.filter(
            (layer) => layer instanceof GraphicsLayer,
        ) as unknown as GraphicsLayer[];

        const allGraphics: Graphic[] = [];
        graphicsLayers.forEach((layer) => {
            layer.graphics.forEach((graphic) => {
                allGraphics.push(graphic);
            });
        });

        if (allGraphics.length === 0) {
            const activeLayerExtent = getActiveLayerExtent(map.layers);
            if (activeLayerExtent) {
                view.goTo(activeLayerExtent, {
                    animate: true,
                });
            } else {
                notificationInstance.error({
                    message: t('zoomWidget.error.noGraphicsOrActiveLayer'),
                });
            }
            return;
        }

        const allGeometries = allGraphics.map((graphic) => graphic.geometry);
        const fullExtent = geometryEngine.union(allGeometries).extent;
        view.goTo(fullExtent, {
            animate: true,
        });
    };

    const getActiveLayerExtent = (
        layers: __esri.Collection<__esri.Layer>,
    ): __esri.Extent | undefined => {
        const settings = getSettings();

        for (const layer of layers) {
            const item = settings?.layer_map?.items_selected?.find(
                (item) => item.layer === layer.id,
            );

            if (item?.boundingBoxes) {
                return getExtentLayer(item.boundingBoxes[0]);
            }

            if (
                (layer.type === 'wms' ||
                    layer.type === 'integrated-mesh-3dtiles') &&
                layer.visible &&
                layer.fullExtent
            ) {
                return layer.fullExtent;
            }
        }
    };

    return (
        <div
            style={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '18px',
                zIndex: 999,
            }}
        >
            {contextHolder}
            <Tooltip title={t('zoomWidget.zoomIn')} placement="left">
                <Button
                    icon={<ZoomInOutlined />}
                    onClick={handleZoomIn}
                    style={{ marginBottom: '10px' }}
                />
            </Tooltip>
            <Tooltip title={t('zoomWidget.zoomOut')} placement="left">
                <Button
                    icon={<ZoomOutOutlined />}
                    onClick={handleZoomOut}
                    style={{ marginBottom: '10px' }}
                />
            </Tooltip>
            <Tooltip title={t('zoomWidget.currentLocation')} placement="left">
                <Button
                    icon={<AimOutlined />}
                    onClick={handleZoomCurrentLocation}
                    style={{ marginBottom: '10px' }}
                    disabled={loading}
                />
            </Tooltip>
            <Tooltip title={t('zoomWidget.extent')} placement="left">
                <Button
                    icon={<ExpandAltOutlined />}
                    onClick={handleZoomExtent}
                    style={{ marginBottom: '10px' }}
                    disabled={loading}
                />
            </Tooltip>
            <Tooltip title={t('zoomWidget.home')} placement="left">
                <Button
                    icon={<HomeOutlined />}
                    onClick={handleZoomHome}
                    disabled={loading}
                />
            </Tooltip>
        </div>
    );
};

export default ZoomWidget;
