import React, { useEffect, useState } from 'react';

import Basemap from '@arcgis/core/Basemap';
import ArcGISMap from '@arcgis/core/Map';

import { Avatar, Col, Divider, List, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import {
    getSettings,
    setSettings,
} from '../../../services/localStorageService';

interface IProps {
    map?: ArcGISMap;
}

interface IBaseMap {
    title: string;
    name: string;
    urlImage: string;
}

const BaseMapWidget = (props: IProps) => {
    const { t } = useTranslation();
    const [baseMapName, setBaseMapName] = useState<string>();

    const baseMaps: IBaseMap[] = [
        {
            title: 'baseMapWidget.hybrid',
            name: 'hybrid',
            urlImage:
                'https://geo.anm.gov.br/portal/sharing/rest/content/items/c861057845234ceb922331dcf4ba4a9d/info/thumbnail/imagery_labels.jpg',
        },
        {
            title: 'baseMapWidget.streets',
            name: 'streets',
            urlImage:
                'https://geo.anm.gov.br/portal/sharing/rest/content/items/47405cbf82c44c74be26122df466d1ac/info/thumbnail/world_street_map.jpg',
        },
        {
            title: 'baseMapWidget.satellite',
            name: 'satellite',
            urlImage:
                'https://geo.anm.gov.br/portal/sharing/rest/content/items/cee7a61b127d4d40bbd8a0acf878b8b7/info/thumbnail/thumbnail1591224931210.jpeg',
        },
        {
            title: 'baseMapWidget.topo',
            name: 'topo',
            urlImage:
                'https://geo.anm.gov.br/portal/sharing/rest/content/items/54441c3a08124254927c0a0536f2eea1/info/thumbnail/topo_map_2.jpg',
        },
    ];

    const isSelectItem = (item: IBaseMap) => {
        return item.title === baseMapName ? '2px solid #3a3a3a ' : 'none';
    };

    const setBaseMap = (item: IBaseMap) => {
        if (props.map) {
            props.map.basemap = Basemap.fromId(item.name);
            setBaseMapName(item.title);
            setSettings({ web_map: { base_map: item.name } });
        }
    };

    const loadSettings = () => {
        if (!props.map) return;

        const settings = getSettings();
        const getBaseMapName = (name: string) =>
            baseMaps.find((item) => item.name === name)?.title;

        if (settings?.web_map?.base_map) {
            setBaseMapName(getBaseMapName(settings.web_map?.base_map));
        } else {
            setBaseMapName(getBaseMapName(props.map?.basemap.id));
        }
    };

    useEffect(() => {
        loadSettings();
    }, []);
    return (
        <section id="baseMapWidget">
            <Row style={{ padding: '24px' }}>
                <Col span={24}>
                    <span>{t('baseMapWidget.title')}</span>
                </Col>
                <Col span={24}>
                    <h2>{baseMapName && t(baseMapName)}</h2>
                </Col>
            </Row>
            <Divider style={{ padding: 0, margin: 0 }} />
            <List
                dataSource={baseMaps}
                renderItem={(item) => (
                    <List.Item
                        key={item.name}
                        style={{
                            padding: '24px',
                            cursor: 'pointer',
                            border: isSelectItem(item),
                        }}
                        onClick={() => setBaseMap(item)}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar
                                    src={item.urlImage}
                                    alt={item.title}
                                    shape="square"
                                    size="large"
                                    style={{
                                        width: '100px',
                                        height: 'auto',
                                    }}
                                />
                            }
                            title={t(item.title)}
                        />
                    </List.Item>
                )}
            />
        </section>
    );
};

export default BaseMapWidget;
