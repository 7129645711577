import React, { Component, Dispatch } from 'react';

import { RootState } from '../config/redux/store';
import { connect, ConnectedProps } from 'react-redux';

import esriConfig from '@arcgis/core/config';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import ArcGISMap from '@arcgis/core/Map';
import {
    SimpleFillSymbol,
    SimpleLineSymbol,
    SimpleMarkerSymbol,
    TextSymbol,
} from '@arcgis/core/symbols';
import MapView from '@arcgis/core/views/MapView';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';

import { AnyAction } from '@reduxjs/toolkit';
import proj4 from 'proj4';

import {
    addArea,
    addGeoJson,
    addImg,
    changeDrawing,
    changeResetState,
    changeUpdating,
} from '../config/redux/area/slice';
import { PointFormType } from '../types';
import { getLocation } from '../utils/Utils';

const webMercatorProjection =
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs';
const wgs84Projection = '+proj=longlat +datum=WGS84 +no_defs';

proj4.defs('EPSG:3857', webMercatorProjection);
proj4.defs('EPSG:4326', wgs84Projection);

function convertCoordinates(coordinates: number[][]): number[][] {
    return coordinates.map(([x, y]) => {
        const [longitude, latitude] = proj4('EPSG:3857', 'EPSG:4326', [x, y]);
        return [longitude, latitude];
    });
}

const mapStateToProps = (state: RootState) => ({
    reduxDrawing: state.area.area_drawing,
    reduxUpdating: state.area.area_updating,
    reduxCoordinates: state.area.area_coordinates,
    reduxGeoJson: state.area.area_geojson,
    reduxReseted: state.area.area_reseted,
    reduxStage: state.area.area_stage,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    reduxDispatch: dispatch,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MapMiddlewareProps extends PropsFromRedux {
    className?: string;
    location?: number[];
    setMap?: (_: ArcGISMap) => void;
    setView?: (_: MapView | null) => void;
}

const pointSymbol = new SimpleMarkerSymbol({
    color: [0, 255, 0, 1.0],
    size: '16px',
    style: 'square',
    outline: {
        color: '#FFF',
        width: 1,
    },
});

const polygonSymbol = new SimpleFillSymbol({
    color: [106, 169, 68, 0.2],
    style: 'solid',
    outline: {
        color: '#6AA944',
        width: 2,
    },
});

const polylineSymbol = new SimpleLineSymbol({
    color: [255, 0, 0, 1.0],
    width: 4,
    style: 'short-dot',
});

class MapMiddleware extends Component<MapMiddlewareProps> {
    view: MapView | null;
    sketches: Map<string, SketchViewModel>;
    id: number;
    graphicLayers: Map<string, GraphicsLayer>;
    map: ArcGISMap;
    center: number[] | undefined;

    constructor(props: MapMiddlewareProps) {
        super(props);
        this.view = null;
        this.sketches = new Map<string, SketchViewModel>();
        this.id = 0;
        this.graphicLayers = new Map<string, GraphicsLayer>();
        this.map = new ArcGISMap({ basemap: 'hybrid' });
        this.center = props.location ? props.location : [-47.85605, -22.053135]; //[-46.838069, -23.339656]
        props.setMap?.(this.map);
    }

    zoomToLocation = async () => {
        const userLocation = await getLocation();

        if (this.props.location) {
            this.view?.goTo({
                center: this.props.location,
                zoom: 17,
            });
            return;
        }

        if (!userLocation?.lat || !userLocation?.long) return;

        this.view?.when(() => {
            this.view?.goTo(
                {
                    center: [userLocation.long, userLocation.lat],
                    zoom: 15,
                },
                { duration: 5000, easing: 'ease-in-out' },
            );
        });
    };

    addText = (graphic: Graphic, text: string) => {
        const geometry = graphic.geometry;

        if (geometry.type == 'polygon') {
            const centroid = (geometry as Polygon).centroid;

            // Criar um símbolo de texto
            const textSymbol = new TextSymbol({
                text: text,
                color: [0, 0, 0],
                haloColor: [255, 255, 255],
                haloSize: 1,
                xoffset: 0,
                yoffset: 0,
                font: {
                    size: 12,
                    family: 'Arial',
                    weight: 'bold',
                },
            });

            // Criar um gráfico com o mesmo ponto e o símbolo de texto
            const textComponent = new Graphic({
                geometry: centroid,
                symbol: textSymbol,
                attributes: {
                    name: 'Texts',
                },
            });

            // Adicionar o gráfico ao mapa
            this.view?.graphics.add(textComponent);
        }
    };

    componentDidMount() {
        esriConfig.apiKey = process.env.API_GIS_KEY || '';
        const viewDiv = document.getElementById('viewDiv');
        viewDiv && (viewDiv.innerHTML = '');
        this.view = new MapView({
            map: this.map,
            container: 'viewDiv',
            zoom: 14,
            center: this.center,
            constraints: {
                minZoom: 4,
            },
        });

        this.props.setView?.(this.view);

        if (this.props.reduxStage <= 1) this.zoomToLocation();

        const samaArea = new GraphicsLayer({
            title: 'samaArea',
        });

        this.graphicLayers.set('samaArea', samaArea);

        this.map.add(samaArea); //Adicionando layer no mapa para que seja "salvo" os polígonos
    }

    render() {
        if (this.props.reduxReseted) this.resetMap();

        if (this.props.reduxDrawing && !this.props.reduxGeoJson)
            this.startChanging();

        if (this.props.reduxUpdating) this.startChanging();

        if (this.props.reduxCoordinates) {
            const coordinates = this.props.reduxCoordinates.map((point) => {
                return {
                    y: Number(point[0]),
                    x: Number(point[1]),
                } as PointFormType;
            });
            this.addGeometryToTheMap(coordinates);
            this.props.reduxDispatch(
                addGeoJson({
                    area_geojson: this.props.reduxCoordinates,
                }),
            );
        }

        this.view?.when(() => {
            this.captureMapImage(this.view!)
                .then((base64Image) => {
                    this.props.reduxDispatch(
                        addImg({
                            area_img: base64Image,
                        }),
                    );
                })
                .catch((error) => {
                    console.error('Erro ao capturar imagem:', error);
                });
        });

        return <div id="viewDiv" className={this.props.className}></div>;
    }

    resetMap = () => {
        this.graphicLayers.get('samaArea')?.removeAll();
        this.sketches.get('samaSketches')?.cancel();

        this.view?.graphics.map((graphic) => {
            this.view?.graphics.remove(graphic);
        });

        this.props.reduxDispatch(
            changeUpdating({
                area_updating: false,
            }),
        );

        this.props.reduxDispatch(
            changeDrawing({
                area_drawing: false,
            }),
        );

        this.props.reduxDispatch(
            addArea({
                area_coordinates: null,
            }),
        );

        this.props.reduxDispatch(changeResetState());
    };

    captureMapImage = (view: MapView) => {
        return new Promise((resolve, reject) => {
            view.takeScreenshot({
                format: 'png',
                width: 800,
                height: 600,
            }).then(
                (screenshot: { dataUrl: string }) => {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = img.width;
                        canvas.height = img.height;
                        const ctx = canvas.getContext('2d');
                        ctx?.drawImage(img, 0, 0);
                        const dataUrl = canvas.toDataURL('image/jpeg', 0.7);
                        resolve(dataUrl);
                    };
                    img.onerror = (error) => {
                        reject(error);
                    };
                    img.src = screenshot.dataUrl;
                },
                (error) => {
                    reject(error);
                },
            );
        });
    };

    /*
        Capturado todos os pontos existentes em uma lista do tipo PointFormType

        Em seguida foi necessário transformar os pontos existentes em Point do arcgis

        Em seguida foi necessário transformar os Point do arcgis em Polygon, pois nesse caso só teriamos pontos na extremidade do polígono

        Em seguida foi necessário transformar o Polygon do arcgis em Graphic, para que tivessemos o polígono completo e preenchido

        Exemplo de uso comentado na função DidMounted
    */
    addGeometryToTheMap = (geometry: PointFormType[]): void => {
        if (geometry == null) return;

        if (this.view == null) return;

        if (geometry.length < 3) return;

        const graphics: Graphic[] = [];

        geometry.forEach((geo) => {
            const pointGeometry = new Point({
                latitude: geo.x,
                longitude: geo.y,
            });

            const pointGraphic = new Graphic({
                geometry: pointGeometry,
                attributes: {
                    id: this.id,
                },
                symbol: pointSymbol,
            });

            graphics.push(pointGraphic);
        });

        const gLayer = this.graphicLayers.get('samaArea');

        if (!gLayer) return;

        this.removeAll();

        const pointGeometries = graphics.map(
            (graphic) => graphic.geometry as Point,
        );

        const polygon = new Polygon({
            rings: [
                pointGeometries.map((pointGeometry) => [
                    pointGeometry.longitude,
                    pointGeometry.latitude,
                ]),
            ],
        });

        const polygonGraphic = new Graphic({
            geometry: polygon,
            symbol: polygonSymbol,
        });

        this.addText(polygonGraphic, 'Área Salva');

        gLayer.add(polygonGraphic);

        this.view?.goTo(polygonGraphic);
    };

    removeAll = () => {
        if (this.view == null) return;

        const textsTobeRemoved = this.view?.graphics

            .toArray()

            .filter((graphic) => {
                const name = graphic.attributes?.name;

                return name === 'Texts';
            });

        if (textsTobeRemoved != undefined)
            this.view?.graphics.removeMany(textsTobeRemoved);

        const gLayer = this.graphicLayers.get('samaArea');

        if (!gLayer) return;

        gLayer.removeAll();
    };

    startChanging = () => {
        this.view?.when(() => {
            if (!this.view) return;

            if (this.props.reduxDrawing) {
                this.setupSketch('create', this.view, this.handleCreateEvent);
            }

            if (this.props.reduxUpdating) {
                this.setupSketch('update', this.view, this.handleUpdateEvent);
            }
        });
    };

    setupSketch = (
        action: 'create' | 'update',
        view: MapView,
        eventHandler: (_: SketchViewModel) => void,
    ) => {
        const sketch = new SketchViewModel({
            view: view,
            layer: this.graphicLayers.get('samaArea'),
            polygonSymbol: polygonSymbol,
            polylineSymbol: polylineSymbol,
            pointSymbol: pointSymbol,
        });

        if (action === 'create') {
            sketch.create('polygon');
        }

        this.sketches.set('samaSketches', sketch);

        eventHandler(sketch);

        view.on('key-down', (event) => {
            if (['Delete', 'Backspace'].includes(event.key)) {
                event.stopPropagation();
            }

            if (event.key === 'Enter') {
                sketch.complete();
            }
        });
    };

    handleCreateEvent = (sketch: SketchViewModel) => {
        sketch.on('create', (event: __esri.SketchViewModelCreateEvent) => {
            if (event.state !== 'complete' && !this.props.reduxDrawing) {
                sketch.cancel();
                return;
            }

            const gLayer = this.graphicLayers.get('samaArea');

            if (!gLayer) return;

            if (!event?.graphic?.geometry) return;

            gLayer.remove(event.graphic);

            const graphic = new Graphic({
                symbol: polygonSymbol,
                geometry: event.graphic.geometry,
                attributes: {
                    id: this.id,
                },
            });

            if (event.state === 'complete') {
                this.addText(graphic, 'Área Criada');

                const polygonGeometry = event.graphic.toJSON();
                const coordinates = polygonGeometry.geometry.rings[0];

                if (!coordinates) return;

                const convertedCoordinates = convertCoordinates(coordinates);

                this.props.reduxDispatch(
                    addGeoJson({
                        area_geojson: convertedCoordinates,
                    }),
                );

                this.props.reduxDispatch(
                    addArea({
                        area_coordinates: convertedCoordinates,
                    }),
                );

                gLayer.add(graphic);

                this.props.reduxDispatch(
                    changeUpdating({
                        area_updating: false,
                    }),
                );

                this.props.reduxDispatch(
                    changeDrawing({
                        area_drawing: false,
                    }),
                );
                this.id++;
            }
        });
    };

    handleUpdateEvent = (sketch: SketchViewModel) => {
        sketch?.on('update', (event: __esri.SketchViewModelUpdateEvent) => {
            if (event.aborted) return;

            if (!this.props.reduxUpdating) {
                sketch.cancel();
                return;
            }

            const layer = this.graphicLayers.get('samaArea');

            if (!layer || !event.graphics.length) return;

            // Remover textos
            const textsTobeRemoved = this.view?.graphics
                .toArray()
                .filter((graphic) => {
                    const name = graphic.attributes?.name;
                    return name === 'Texts';
                });

            if (textsTobeRemoved)
                this.view?.graphics.removeMany(textsTobeRemoved);

            event.graphics.forEach((polygon: Graphic) => {
                if (polygon.symbol.type !== 'simple-fill') {
                    sketch.cancel();
                    return;
                }
                if (event.state === 'complete') {
                    //Início Extraindo Coordenadas
                    const polygonGeometry = polygon.toJSON();
                    const coordinates = polygonGeometry.geometry.rings[0];

                    if (!coordinates) return;

                    const convertedCoordinates =
                        convertCoordinates(coordinates);

                    this.props.reduxDispatch(
                        addGeoJson({
                            area_geojson: convertedCoordinates,
                        }),
                    );

                    this.props.reduxDispatch(
                        addArea({
                            area_coordinates: convertedCoordinates,
                        }),
                    );
                    this.props.reduxDispatch(
                        changeUpdating({
                            area_updating: false,
                        }),
                    );

                    this.props.reduxDispatch(
                        changeDrawing({
                            area_drawing: false,
                        }),
                    );
                }
            });

            // Adiciona textos nas posições atuais
            event.graphics.forEach((graphic) => {
                this.addText(graphic, 'Área Criada');
            });
        });
    };
}

export default connector(MapMiddleware);
