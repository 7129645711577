import React from 'react';

import './User.scss';
import { RootState } from '../../../config/redux/store';
import { useSelector } from 'react-redux';

import { LogoutOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

const User = () => {
    const { t } = useTranslation();
    const { username } = useSelector((state: RootState) => state.user);
    const keycloak = window.keycloak;

    const items: MenuProps['items'] = [
        {
            key: '1',
            danger: true,
            label: t('user.logout'),
            icon: <LogoutOutlined />,
            onClick: () => keycloak?.logout(),
        },
    ];
    return (
        <Dropdown menu={{ items }}>
            <div id="userComponent">
                {username?.split('')[0]?.toUpperCase()}
            </div>
        </Dropdown>
    );
};

export default User;
