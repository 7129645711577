import { useEffect, useState } from 'react';

import { RootState } from '../../../config/redux/store';
import { useDispatch, useSelector } from 'react-redux';

import './ConfigComponent.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, FormProps, Input, notification, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    decreaseStage,
    resetAreaCreation,
} from '../../../config/redux/area/slice';
import { setStep2 } from '../../../config/redux/mission/slice';
import { createArea } from '../../../services/';
import { PayloadTypeType, PointFormType, ReportTypeType } from '../../../types';
import { getErrorField } from '../../../utils/ErrorField';
import { translateEnums } from '../../../utils/Utils';

type FieldType = {
    reportType: ReportTypeType;
    payloadType: PayloadTypeType;
    date: string;
    time: string;
    droneportId?: string;
};

const ConfigComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm<FieldType>();

    const [notificationInstance, contextHolder] =
        notification.useNotification();
    const [loading, setLoading] = useState<boolean>(false);

    const droneportId = null;

    const { area_geojson, area_img } = useSelector(
        (state: RootState) => state.area,
    );
    const { mission } = useSelector((state: RootState) => state);

    const payloadTypes: PayloadTypeType[] = [
        'RGB_THERMAL',
        'ENVIRONMENTAL',
        'MULTISPECTRAL',
        'NONE',
    ];

    const reportTypes: ReportTypeType[] = [
        'SURVEILLANCE',
        'ENVIRONMENTAL',
        'MAPPING',
        'NONE',
    ];

    const handleToBack = () => {
        dispatch(setStep2({ ...form.getFieldsValue() }));
        dispatch(
            decreaseStage({
                area_stage: 1,
            }),
        );
    };

    const handleFinish: FormProps<FieldType>['onFinish'] = (
        values: FieldType,
    ) => {
        dispatch(setStep2({ ...values }));

        const points = area_geojson?.map((point) => {
            return {
                x: Number(point[0]),
                y: Number(point[1]),
            } as PointFormType;
        });

        setLoading(true);

        createArea({
            name: mission.name!,
            description: mission.description!,
            date: getDataTime(values.date, values.time),
            payloadType: values.payloadType,
            reportType: values.reportType,
            droneportId: droneportId!,
            points: points!,
            areaImg: area_img!,
        })
            .then(() => {
                dispatch(resetAreaCreation());
                navigate('/');
            })
            .catch(({ response }) =>
                getErrorField(response.data, form, ({ message }) =>
                    notificationInstance.error({ message: message }),
                ),
            )
            .finally(() => setLoading(false));
    };

    const getDataTime = (date: string, time: string): string => {
        const dateTimeString = `${date}T${time}:00.000Z`;
        const dateTime = new Date(dateTimeString);
        return dateTime.toISOString();
    };

    useEffect(() => {
        form.setFieldsValue({ ...mission });
    }, [form, mission]);

    return (
        <section id="configComponent">
            {contextHolder}
            <h5>{t('configComponent.aboutInterestArea.title')}</h5>
            <p>{t('configComponent.aboutInterestArea.description')}</p>

            <br className="space" />
            <Form
                form={form}
                name="form"
                layout="vertical"
                onFinish={handleFinish}
            >
                <div id="paramsComponent__Droneport" className="forms">
                    <Form.Item
                        name="droneportId"
                        className="custom-label"
                        label={t('configComponent.droneportSection.title')}
                    >
                        <Select
                            style={{ width: '100%' }}
                            className="select"
                            placeholder="Droneport #001"
                            disabled={true}
                        >
                            <Select.Option>Droneport #001</Select.Option>
                        </Select>
                    </Form.Item>

                    <div className="input-wrapper">
                        <div className="input-wrapper--content">
                            <Form.Item
                                name="date"
                                className="custom-label"
                                label={t('configComponent.dateSection.date')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('generic.form.required'),
                                    },
                                ]}
                            >
                                <Input id="date" type="date" className="time" />
                            </Form.Item>
                        </div>

                        <br className="small-space" />

                        <div className="input-wrapper--content">
                            <Form.Item
                                name="time"
                                className="custom-label"
                                label={t('configComponent.dateSection.time')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('generic.form.required'),
                                    },
                                ]}
                            >
                                <Input id="time" type="time" className="time" />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div id="paramsComponent__Mission" className="forms">
                    <Form.Item
                        name="payloadType"
                        className="custom-label"
                        label={t('configComponent.missionSection.title')}
                        rules={[
                            {
                                required: true,
                                message: t('generic.form.required'),
                            },
                        ]}
                    >
                        <div className="input-wrapper wrap">
                            {payloadTypes.map((payloadType) => (
                                <div
                                    className="input-wrapper--content hundred-percent"
                                    key={payloadType}
                                >
                                    <Input
                                        id={payloadType + '-type'}
                                        type="radio"
                                        name="category"
                                        value={payloadType}
                                    />
                                    <label
                                        className="fake-input"
                                        htmlFor={payloadType + '-type'}
                                    >
                                        {t(translateEnums(payloadType))}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Form.Item>
                </div>

                <div id="paramsComponent__Reports" className="forms">
                    <Form.Item
                        name="reportType"
                        className="custom-label"
                        label={t('configComponent.reportsSection.title')}
                        rules={[
                            {
                                required: true,
                                message: t('generic.form.required'),
                            },
                        ]}
                    >
                        <div className="input-wrapper wrap">
                            {reportTypes.map((reportType) => (
                                <div
                                    className="input-wrapper--content hundred-percent"
                                    key={reportType}
                                >
                                    <Input
                                        id={reportType + '-report'}
                                        type="radio"
                                        name="category2"
                                        value={reportType}
                                    />
                                    <label
                                        className="fake-input"
                                        htmlFor={reportType + '-report'}
                                    >
                                        {t(translateEnums(reportType))}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Form.Item>
                </div>

                <br className="space" />

                <div className="next_section">
                    <Button className="button" onClick={handleToBack}>
                        {t('configComponent.nextSection.back')}
                    </Button>

                    <Button
                        htmlType="submit"
                        className="button-green"
                        disabled={loading}
                    >
                        {loading ? (
                            <LoadingOutlined />
                        ) : (
                            t('configComponent.nextSection.finish')
                        )}
                    </Button>
                </div>
            </Form>
        </section>
    );
};

export default ConfigComponent;
