import React, { useEffect } from 'react';

import { RootState } from '../../../config/redux/store';
import { useDispatch, useSelector } from 'react-redux';

import './ParamsComponent.scss';
import { Button, Form, FormProps, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';

import {
    changeOption,
    decreaseStage,
    increaseStage,
} from '../../../config/redux/area/slice';
import { setStep1 } from '../../../config/redux/mission/slice';
import User from '../../HeaderComponent/UserComponent/User';

type FieldType = {
    name: string;
    description: string;
};

const ParamsComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm<FieldType>();

    const { username, userRoleName } = useSelector(
        (state: RootState) => state.user,
    );

    const { mission } = useSelector((state: RootState) => state);

    const handleToBack = () => {
        dispatch(
            decreaseStage({
                area_stage: 1,
            }),
        );

        dispatch(
            changeOption({
                area_option: 0,
            }),
        );
    };

    const handleFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        dispatch(setStep1({ ...values }));
        dispatch(
            increaseStage({
                area_stage: 1,
            }),
        );
    };

    useEffect(() => {
        form.setFieldsValue({ ...mission });
    }, [form, mission]);

    return (
        <section id="paramsComponent">
            <h5>{t('paramsComponent.aboutInterestArea.title')}</h5>
            <p>{t('paramsComponent.aboutInterestArea.description')}</p>

            <br className="space" />

            <div id="paramsComponent__User">
                <User />

                <div id="text">
                    <h4>{username}</h4>
                    <p>{userRoleName}</p>
                </div>
            </div>

            <br className="space" />
            <Form
                form={form}
                onFinish={handleFinish}
                name="form"
                layout="vertical"
            >
                <div id="paramsComponent__Form">
                    <Form.Item
                        label={t('paramsComponent.form.areaName.title')}
                        className="custom-label"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('generic.form.required'),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                'paramsComponent.form.areaName.placeholder',
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('paramsComponent.form.details.title')}
                        className="custom-label"
                        name="description"
                    >
                        <TextArea
                            placeholder={t(
                                'paramsComponent.form.details.placeholder',
                            )}
                        ></TextArea>
                    </Form.Item>
                </div>

                <br className="space" />

                <div className="next_section">
                    <Button className="button" onClick={() => handleToBack()}>
                        {t('paramsComponent.nextSection.back')}
                    </Button>

                    <Button className="button-green" htmlType="submit">
                        {t('paramsComponent.nextSection.next')}
                    </Button>
                </div>
            </Form>
        </section>
    );
};

export default ParamsComponent;
