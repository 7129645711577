import axios from 'axios';

import { getLanguage } from './localStorageService';

const language = getLanguage();

const keycloak = window.keycloak;

const httpClient = axios.create({
    baseURL: process.env.API_BASE_URL,
    headers: {
        'Accept-Language': language,
    },
});

httpClient.defaults.headers.common[
    'Authorization'
] = `Bearer ${keycloak?.token}`;

export const backendAPI = httpClient;