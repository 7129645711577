import './ViewComponent.scss';
import { useEffect, useState } from 'react';

import { convertDateFromString } from '../../utils/Date';
import { getIconStatus, getStatusWithoutIcon } from '../../middleware/Status';
import { checkDate, translateEnums } from '../../utils/Utils';

import { useNavigate } from 'react-router-dom';

import { getByNameFile } from '../../services';
import { useTranslation } from 'react-i18next';

interface InitialMissionWaiting {
    tips: {
        active: boolean;
        text: string;
    };
    image?: string;
    missionName: string;
    missionType: string;
    reportType: string;
    status: string;
    date: string;
    flight?: boolean;
    id: number;
}

interface ViewComponentProps {
    view: InitialMissionWaiting | null;
    approved?: boolean;
}

const View = (props: ViewComponentProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [image, setImage] = useState<string>('');
    const checkIsEmpty = () => {
        if (!props.view) return 'empty-box';
    };

    const getDate = (date: string) => {
        return checkDate(date);
    };

    const getMenssageButton = () => {
        if (!props.view) return;
        if (!props.approved) {
            return getStatusWithoutIcon(props.view.tips.text, t);
        } else {
            if (getDate(props.view.date) == null) {
                props.view.tips.active = false;
            } else {
                if (props.view.status.toLowerCase() != 'in_progress') {
                    return t(getDate(props.view.date) ?? '');
                } else {
                    return 'Em Andamento...';
                }
            }
        }
    };

    useEffect(() => {
        if (props?.view?.image) {
            getByNameFile(props.view.image).then(setImage);
        }
    }, []);

    return (
        <div id="viewComponent" className={checkIsEmpty()}>
            {props.view != null ? (
                <>
                    <header
                        style={{
                            backgroundImage: image ? `url(${image})` : `none`,
                        }}
                    >
                        {props.view?.tips.active && (
                            <button
                                className={`fake-input boxesButton ${props.view.status.toLowerCase()}`}
                            >
                                {getMenssageButton()}
                            </button>
                        )}
                    </header>

                    <main>
                        <h4>{props.view?.missionName}</h4>

                        <br className="small-space" />

                        <div id="viewComponent__row">
                            <div>
                                <p className="small tertiary-color">
                                    {t('viewComponent.type')}
                                </p>
                                <p className="medium">
                                    {t(translateEnums(props.view?.missionType))}
                                </p>
                            </div>

                            <div>
                                <p className="small tertiary-color">
                                    {t('viewComponent.status')}
                                </p>
                                <p
                                    className={`medium ${props?.view?.status.toLowerCase()}`}
                                >
                                    {getIconStatus(props.view.status, t)}
                                </p>
                            </div>
                        </div>

                        <br className="small-space" />

                        <div id="viewComponent__row">
                            <div>
                                <p className="small tertiary-color">
                                    {t('viewComponent.report')}
                                </p>
                                <p className="medium">
                                    {t(translateEnums(props.view.reportType))}
                                </p>
                            </div>

                            <div>
                                <p className="small tertiary-color">
                                    {t('viewComponent.datetime')}
                                </p>
                                <p className="medium waiting">
                                    {convertDateFromString(props.view.date)}
                                </p>
                            </div>
                        </div>

                        <br className="space" />
                        <br className="space" />

                        <button
                            onClick={() =>
                                navigate('/details/' + props.view?.id)
                            }
                            className="button-green-2"
                        >
                            {t('viewComponent.button')}
                        </button>
                    </main>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default View;
