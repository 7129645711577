import { createSlice } from '@reduxjs/toolkit';

import { PayloadTypeType, ReportTypeType } from '../../../types';

interface InitialInterface {
    name?: string;
    description?: string;
    reportType?: ReportTypeType;
    payloadType?: PayloadTypeType;
    date?: string;
    time?: string;
    droneportId?: string;
}

const initialState: InitialInterface = {};

const missionSlice = createSlice({
    name: 'mission',
    initialState,
    reducers: {
        setStep1: (state, action) => {
            state.name = action.payload.name;
            state.description = action.payload.description;
        },
        setStep2: (state, action) => {
            state.reportType = action.payload.reportType;
            state.payloadType = action.payload.payloadType;
            state.date = action.payload.date;
            state.time = action.payload.time;
            state.droneportId = action.payload.droneportId;
        },
        clearMission: (state) => {
            state.name = undefined;
            state.description = undefined;
            state.reportType = undefined;
            state.payloadType = undefined;
            state.date = undefined;
            state.time = undefined;
            state.droneportId = undefined;
        },
    },
});

export const { setStep1, setStep2, clearMission } = missionSlice.actions;

export default missionSlice.reducer;
