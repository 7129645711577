import { notification } from 'antd';

const extractNotamCoordinates = (xmlDoc: Document): number[][] | null => {
    try {
        if (xmlDoc.documentElement.nodeName === 'kml') {
            const placeMarkList = Array.from(
                xmlDoc.getElementsByTagName('Placemark'),
            );

            if (placeMarkList) {
                const areaRings: number[][] = [];
                placeMarkList.forEach((area: Element) => {
                    const coordinates =
                        area.getElementsByTagName('coordinates');

                    const rings =
                        coordinates[0].textContent !== null
                            ? coordinates[0].textContent.split(' ')
                            : [];

                    for (const ring of rings) {
                        const latLong = ring.split(',');
                        if (latLong.length > 1) {
                            areaRings.push([
                                Number(latLong[0]),
                                Number(latLong[1]),
                            ]);
                        }
                    }
                });
                return areaRings;
            }
        } else {
            throw new Error();
        }
    } catch (failure) {
        notification.error({
            message: 'Falha no upload: arquivo .kml corrompido',
        });
    }
    return null;
};

export const uploadKML = (file: any): Promise<number[][] | null> => {
    return new Promise((resolve, reject) => {
        try {
            const lastDotIndex = file.name.lastIndexOf('.');
            const fileExtension = file.name.substring(
                lastDotIndex,
                file.name.length,
            );

            if (file.type.includes('kml') || fileExtension === '.kml') {
                const fileReader = new FileReader();
                fileReader.onload = (e: ProgressEvent<FileReader>) => {
                    if (e?.target && typeof e.target.result === 'string') {
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(
                            e.target.result,
                            'text/xml',
                        );
                        const data = extractNotamCoordinates(xmlDoc);
                        resolve(data);
                    }
                };
                fileReader.readAsText(file);
            }
        } catch (error) {
            notification.error({
                message:
                    'Não foi possível realizar o upload do arquivo. Por favor, tente novamente.',
            });
            reject(error);
        }
    });
};
