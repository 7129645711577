import './HistoryComponent.scss';

import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import PdfMiddleware from '../../middleware/PdfMiddleware';
import { getIconStatus } from '../../middleware/Status';

import {
    CaretDownOutlined,
    DownloadOutlined,
    EyeOutlined,
    FileSyncOutlined,
} from '@ant-design/icons';
import { Modal, Pagination, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    changeReportModalState,
    changeModalTitle,
} from '../../config/redux/modal/slice';
import { addReport } from '../../config/redux/report/slice';
import {
    getAllAreaOfInterest,
    updateFlightApprovalStatus,
    updateStatusMission,
} from '../../services';
import {
    FlightApprovalStatusType,
    PageType,
    AreaOfInterestType,
    PayloadTypeType,
    PageFormType,
    MissionStatusType,
} from '../../types';
import { OrderingType } from '../../types/OrderingType';
import { convertDateFromString } from '../../utils/Date';
import { translateEnums, debounce } from '../../utils/Utils';
import base64Data from './../../../base64.json';

const { relatorio } = base64Data;

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsInboxFill } from 'react-icons/bs';

type FilterType = {
    flightApprovalStatus?: FlightApprovalStatusType;
    payloadType?: PayloadTypeType;
    missionStatus?: MissionStatusType;
    name?: string;
    date?: string;
    isMission?: boolean;
    sortOrder?: OrderingType;
};

const History = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [openReportModal, setOpenReportModal] = useState<boolean>(
        query.get('reports') === 'true',
    );
    const [missions, setMissions] = useState<PageType<AreaOfInterestType>>();
    const [areaOfInterest, setAreaOfInterest] = useState<AreaOfInterestType>();
    const [missionIndex, setMissionIndex] = useState<number>(0);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [flightApprovalStatus, setFlightApprovalStatus] =
        useState<FlightApprovalStatusType>('NONE');
    const [missionStatus, setMissionStatus] =
        useState<MissionStatusType>('NONE');
    const [filters, setFilters] = useState<FilterType & PageFormType>({});

    const flightApprovalStatusOption = [
        { value: 'APPROVED', label: 'status.approved' },
        { value: 'DISAPPROVED', label: 'status.disapproved' },
        { value: 'WAITING', label: 'status.waiting' },
        { value: 'CANCELED', label: 'status.canceled' },
        { value: 'NONE', label: 'status.none' },
    ];

    const missionStatusOption = [
        { value: 'ACCOMPLISHED', label: 'status.accomplished' },
        { value: 'ACCOMPLISH', label: 'status.accomplish' },
        { value: 'IN_PROGRESS', label: 'status.inProgress' },
        { value: 'CANCELED', label: 'status.canceled' },
        { value: 'NONE', label: 'status.none' },
    ];

    const missionTypeOption = [
        { value: 'RGB_THERMAL', label: 'enums.rgbThermal' },
        { value: 'ENVIRONMENTAL', label: 'enums.environmental' },
        { value: 'MULTISPECTRAL', label: 'enums.multispectral' },
        { value: 'NONE', label: 'enums.none' },
    ];

    interface FetchJsonAndExtractBase64Result {
        error: unknown;
        data: { base64: string[] } | null;
    }

    enum SelectedClasses {
        WAITING = 'waiting',
        APPROVED = 'approved',
        ALL = 'all',
    }

    const fetchJsonAndExtractBase64 =
        async (): Promise<FetchJsonAndExtractBase64Result> => {
            try {
                //TO DO: XPR API Integration
                // const response = await axios.get(
                //     import.meta.env.VITE_API_XPR_URL,
                // );
                // return { error: null, data: response.data };
                return { error: null, data: relatorio };
            } catch (err) {
                return { error: err, data: null };
            }
        };

    const handleOpenReportModal = async (area?: AreaOfInterestType) => {
        if (!missions) return;

        const missionsFilter = missions?.content.filter(
            (area) => area.mission?.status == 'ACCOMPLISHED',
        );

        if (!area && missionsFilter.length > 0) {
            area = missionsFilter[missionIndex];
        }

        if (area?.mission?.status !== 'ACCOMPLISHED') {
            return;
        }

        const base64PDF = await fetchJsonAndExtractBase64();

        dispatch(
            addReport({
                // reportBase64: base64PDF.data?.base64[id],
                reportBase64: base64PDF.data?.base64[0],
            }),
        );

        dispatch(changeModalTitle({ title: area?.name ?? '' }));

        dispatch(
            changeReportModalState({
                reportModal: true,
            }),
        );
    };

    const handleNextAreaModal = () => {
        if (!missions) return;
        const missionsFilter = missions?.content.filter(
            (area) => area.mission?.status == 'ACCOMPLISHED',
        );
        if (missionIndex + 1 < missionsFilter?.length) {
            setMissionIndex(missionIndex + 1);
            const area = missionsFilter[missionIndex + 1];
            dispatch(changeModalTitle({ title: area?.name ?? '' }));
        }
    };

    const handlePreviousAreaModal = () => {
        if (!missions) return;
        if (missionIndex - 1 >= 0) {
            setMissionIndex(missionIndex - 1);
            const area = missions?.content.filter(
                (area) => area.mission?.status == 'ACCOMPLISHED',
            )[missionIndex - 1];
            dispatch(changeModalTitle({ title: area?.name ?? '' }));
        }
    };

    const handleCloseReportModal = () => {
        setOpenReportModal(false);
    };

    const changeFilterOrdering = (orderingType?: string): void => {
        setFilters({
            ...filters,
            sortOrder: orderingType as OrderingType,
        });
    };

    const handlePageChange = (pageNumber: number, pageSize?: number): void => {
        setFilters({
            ...filters,
            page: pageNumber - 1,
            size: pageSize,
        });
    };

    const handleUpdateFlightApprovalStatus = (id: number) => {
        setConfirmLoading(true);
        updateFlightApprovalStatus(id, flightApprovalStatus)
            .then(() => {
                loadData();
            })
            .finally(() => {
                setOpenChangeStatusModal(false);
                setConfirmLoading(false);
            });
    };

    const handleUpdateMissionStatus = (id: number) => {
        setConfirmLoading(true);
        updateStatusMission(id, missionStatus)
            .then(() => {
                loadData();
            })
            .finally(() => {
                setOpenChangeStatusModal(false);
                setConfirmLoading(false);
            });
    };

    const handleCancelUpdateStatus = () => {
        setOpenChangeStatusModal(false);
    };

    const showUpdateFlightApprovalStatus = (
        areaOfInterest: AreaOfInterestType,
    ) => {
        setAreaOfInterest(areaOfInterest);
        setOpenChangeStatusModal(true);
    };

    const changeFilterFlightApprovalStatus = (status?: string): void => {
        setFilters({
            ...filters,
            flightApprovalStatus: status as FlightApprovalStatusType,
            missionStatus: undefined,
        });
    };

    const changeFilterPayloadType = (payloadType?: string): void => {
        setFilters({
            ...filters,
            payloadType: payloadType as PayloadTypeType,
        });
    };
    const changeFilterMissionStatus = (missionStatus?: string): void => {
        setFilters({
            ...filters,
            missionStatus: missionStatus as MissionStatusType,
            flightApprovalStatus: undefined,
        });
    };

    const changeFilterName = debounce((value?: string) => {
        setFilters({
            ...filters,
            name: value,
        });
    }, 500);

    const changeFilterDate = (date?: string): void => {
        setFilters({
            ...filters,
            date: date,
        });
    };

    const removeSelectedClassName = (): void => {
        const objects = ['waiting', 'approved', 'all'];

        objects.forEach((id) => {
            const element = document.getElementById(id);
            if (element) {
                element.classList.remove('selected-filter');
            }
        });
    };

    const addSelectedClassName = (id: SelectedClasses): void => {
        const element = document.getElementById(id);
        if (element) {
            element.classList.add('selected-filter');
        }
    };

    const changeFilterMissionState = (isMission?: boolean): void => {
        removeSelectedClassName();

        switch (isMission) {
            case undefined:
                addSelectedClassName(SelectedClasses.ALL);
                break;
            case true:
                addSelectedClassName(SelectedClasses.APPROVED);
                break;
            case false:
                addSelectedClassName(SelectedClasses.WAITING);
                break;
        }

        setFilters({
            ...filters,
            isMission: isMission,
            flightApprovalStatus: !isMission
                ? filters.flightApprovalStatus
                : undefined,
            missionStatus: isMission ? filters.missionStatus : undefined,
        });
    };

    const loadData = () => {
        getAllAreaOfInterest({
            flightApprovalStatus: filters.flightApprovalStatus,
            payloadType: filters.payloadType,
            missionStatus: filters.missionStatus,
            name: filters.name,
            date: filters.date,
            isMission: filters.isMission,
            sortOrder: filters.sortOrder,
            page: filters.page,
            size: filters.size,
        }).then((data) => setMissions(data));
    };

    const pushToDetails = (id: number) => {
        navigate(`/details/${id}`);
    };

    const getStatus = (areaOfInterest: AreaOfInterestType) => {
        return areaOfInterest.mission
            ? areaOfInterest.mission.status.toString()
            : areaOfInterest.flightApprovalStatus.toString();
    };

    const getIsMissionMessage = () => {
        if (filters.isMission == undefined) {
            return <>{t('historyComponent.missionMessage.all')}</>;
        }

        if (filters.isMission) {
            return (
                <>
                    {t('historyComponent.missionMessage.isMission')}{' '}
                    <strong>
                        {t('historyComponent.missionMessage.isMissionStrong')}
                    </strong>
                    .
                </>
            );
        } else {
            return (
                <>
                    {t('historyComponent.missionMessage.isNotMission')}{' '}
                    <strong>
                        {t(
                            'historyComponent.missionMessage.isNotMissionStrong',
                        )}
                    </strong>
                    .
                </>
            );
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!openReportModal) return;
        handleOpenReportModal();
    }, [openReportModal, missions]);

    useEffect(() => {
        loadData();
    }, [filters]);

    return (
        <section id="historyComponent">
            <PdfMiddleware
                onNext={handleNextAreaModal}
                onPrevious={handlePreviousAreaModal}
                OnClose={handleCloseReportModal}
            />

            <div className="panel-wrapper">
                <header>
                    <h1>{t('historyComponent.title')}</h1>
                </header>

                <br className="space" />

                <div className="panel-wrapper--filters">
                    <button
                        onClick={() => changeFilterMissionState()}
                        className="button transparent selected-filter"
                        id="all"
                    >
                        {t('historyComponent.all')}
                    </button>
                    <button
                        onClick={() => changeFilterMissionState(false)}
                        className="button transparent"
                        id="waiting"
                    >
                        {t('historyComponent.waiting')}
                    </button>
                    <button
                        onClick={() => changeFilterMissionState(true)}
                        className="button transparent"
                        id="approved"
                    >
                        {t('historyComponent.approved')}
                    </button>
                </div>

                <br className="space" />

                <p>{getIsMissionMessage()}</p>

                <br className="space" />

                <div className="panel-wrapper-filter filters-inline">
                    <div className="input-wrapper--content">
                        <h5>{t('historyComponent.filter.missionName')}</h5>

                        <br className="small-space" />

                        <input
                            type="text"
                            defaultValue={filters.name}
                            onChange={(value) =>
                                changeFilterName(value.currentTarget.value)
                            }
                        />
                    </div>

                    <div className="input-wrapper--content">
                        <h5>{t('historyComponent.filter.date')}</h5>

                        <br className="small-space" />

                        <input
                            id="date"
                            type="date"
                            className="time"
                            value={filters.date}
                            onChange={(value) =>
                                changeFilterDate(value.currentTarget.value)
                            }
                        />
                    </div>
                    <div className="input-wrapper--content">
                        <h5>{t('historyComponent.filter.payloadType')}</h5>

                        <br className="small-space" />

                        <div className="select-container">
                            <select
                                value={filters.payloadType ?? 'NONE'}
                                onChange={({ target }) =>
                                    changeFilterPayloadType(target.value)
                                }
                            >
                                {missionTypeOption.map(({ value, label }) => (
                                    <option
                                        value={value}
                                        key={`${value}-filter`}
                                    >
                                        {t(label)}
                                    </option>
                                ))}
                            </select>
                            <button
                                className="clear-button"
                                onClick={() => changeFilterPayloadType()}
                            >
                                <AiOutlineCloseCircle />
                            </button>
                        </div>
                    </div>

                    <div className="input-wrapper--content">
                        <h5>{t('historyComponent.filter.ordering')}</h5>

                        <br className="small-space" />

                        <select
                            value={filters.sortOrder ?? 'ASC'}
                            onChange={({ target }) => {
                                changeFilterOrdering(target.value);
                            }}
                        >
                            <option value="ASC" key={`asc-filter`}>
                                {t('sortOrder.asc')}
                            </option>

                            <option value="DESC" key={`desc-filter`}>
                                {t('sortOrder.desc')}
                            </option>
                        </select>
                    </div>

                    {filters.isMission === false && (
                        <div className="input-wrapper--content">
                            <h5>{t('historyComponent.filter.status')}</h5>

                            <br className="small-space" />
                            <div className="select-container">
                                <select
                                    value={
                                        filters.flightApprovalStatus ?? 'NONE'
                                    }
                                    onChange={({ target }) =>
                                        changeFilterFlightApprovalStatus(
                                            target.value,
                                        )
                                    }
                                >
                                    {flightApprovalStatusOption.map(
                                        ({ value, label }) => (
                                            <option
                                                value={value}
                                                key={`${value}-filter`}
                                            >
                                                {t(label)}
                                            </option>
                                        ),
                                    )}
                                </select>
                                <button
                                    className="clear-button"
                                    onClick={() =>
                                        changeFilterFlightApprovalStatus()
                                    }
                                >
                                    <AiOutlineCloseCircle />
                                </button>
                            </div>
                        </div>
                    )}

                    {filters.isMission === true && (
                        <div className="input-wrapper--content">
                            <h5>Status</h5>

                            <br className="small-space" />
                            <div className="select-container">
                                <select
                                    value={filters.missionStatus ?? 'NONE'}
                                    onChange={({ target }) =>
                                        changeFilterMissionStatus(target.value)
                                    }
                                >
                                    {missionStatusOption.map(
                                        ({ value, label }) => (
                                            <option
                                                value={value}
                                                key={`${value}-filter`}
                                            >
                                                {t(label)}
                                            </option>
                                        ),
                                    )}
                                </select>
                                <button
                                    className="clear-button"
                                    onClick={() => changeFilterMissionStatus()}
                                >
                                    <AiOutlineCloseCircle />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <br className="space" />
            <br className="space" />
            <div className="panel-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>{t('historyComponent.header.fileName')}</th>
                            <th>{t('historyComponent.header.missionType')}</th>
                            <th>{t('historyComponent.header.reportType')}</th>
                            <th>{t('historyComponent.header.date')}</th>
                            <th>{t('historyComponent.header.status')}</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {missions ? (
                            <>
                                {missions?.content.map((areaOfInterest) => (
                                    <tr key={areaOfInterest.id}>
                                        <td>{areaOfInterest.name}</td>
                                        <td>
                                            {t(
                                                translateEnums(
                                                    areaOfInterest.payloadType,
                                                ),
                                            )}
                                        </td>

                                        <td>
                                            {t(
                                                translateEnums(
                                                    areaOfInterest.report
                                                        .reportType,
                                                ),
                                            )}
                                        </td>

                                        <td>
                                            {convertDateFromString(
                                                areaOfInterest.date,
                                            )}
                                        </td>

                                        <td
                                            className={`${getStatus(
                                                areaOfInterest,
                                            ).toLowerCase()}`}
                                        >
                                            {getIconStatus(
                                                getStatus(areaOfInterest),
                                                t,
                                            )}
                                        </td>

                                        <td>
                                            <div className="button">
                                                {t(
                                                    'historyComponent.header.actions',
                                                )}
                                                <CaretDownOutlined />
                                                <div className="div">
                                                    <button
                                                        onClick={() =>
                                                            pushToDetails(
                                                                areaOfInterest.id,
                                                            )
                                                        }
                                                    >
                                                        <EyeOutlined />{' '}
                                                        {t(
                                                            'historyComponent.actions.viewDetails',
                                                        )}
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            showUpdateFlightApprovalStatus(
                                                                areaOfInterest,
                                                            )
                                                        }
                                                    >
                                                        <FileSyncOutlined />{' '}
                                                        {t(
                                                            'historyComponent.actions.changeStatus',
                                                        )}
                                                    </button>
                                                    {areaOfInterest.mission
                                                        ?.status ===
                                                        'ACCOMPLISHED' && (
                                                        <button
                                                            onClick={() => {
                                                                handleOpenReportModal(
                                                                    areaOfInterest,
                                                                );
                                                            }}
                                                        >
                                                            <DownloadOutlined />{' '}
                                                            {t(
                                                                'historyComponent.actions.downloadFile',
                                                            )}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr className="merged-table">
                                <td colSpan={6}>
                                    <div>
                                        <BsInboxFill />
                                        {t('historyComponent.noMissionMessage')}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <br />
                {missions && (
                    <Pagination
                        current={missions.number + 1}
                        total={missions.totalElements}
                        pageSize={missions.size}
                        onChange={handlePageChange}
                    />
                )}
            </div>
            {areaOfInterest && (
                <Modal
                    title={t('historyComponent.changeStatusModal.title')}
                    open={openChangeStatusModal}
                    onOk={() => {
                        if (areaOfInterest.mission) {
                            handleUpdateMissionStatus(
                                areaOfInterest.mission.id,
                            );
                        } else {
                            handleUpdateFlightApprovalStatus(areaOfInterest.id);
                        }
                    }}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancelUpdateStatus}
                    cancelText={t('historyComponent.changeStatusModal.cancel')}
                    okText={t('historyComponent.changeStatusModal.save')}
                    destroyOnClose
                >
                    <>
                        <p>
                            {t(
                                'historyComponent.changeStatusModal.description',
                            )}
                        </p>
                        <Typography.Title level={5}>
                            {t(
                                'historyComponent.changeStatusModal.selectLabel',
                            )}
                        </Typography.Title>
                        {areaOfInterest.mission ? (
                            <select
                                defaultValue={areaOfInterest.mission.status}
                                value={missionStatus}
                                //// TODO: Add placeholder
                                // placeholder={t(
                                //     'historyComponent.changeStatusModal.selectLabel',
                                // )}
                                onChange={({ target }) => {
                                    setMissionStatus(
                                        target.value as MissionStatusType,
                                    );
                                }}
                            >
                                {missionStatusOption.map(({ value, label }) => (
                                    <option
                                        value={value}
                                        key={`${value}-mission-status`}
                                    >
                                        {t(label)}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <select
                                defaultValue={
                                    areaOfInterest.flightApprovalStatus
                                }
                                value={flightApprovalStatus}
                                //// TODO: Add placeholder
                                // placeholder={t(
                                //     'historyComponent.changeStatusModal.selectLabel',
                                // )}
                                onChange={({ target }) => {
                                    setFlightApprovalStatus(
                                        target.value as FlightApprovalStatusType,
                                    );
                                }}
                            >
                                {flightApprovalStatusOption.map(
                                    ({ value, label }) => (
                                        <option
                                            value={value}
                                            key={`${value}-flight-approval-status`}
                                        >
                                            {t(label)}
                                        </option>
                                    ),
                                )}
                            </select>
                        )}
                    </>
                </Modal>
            )}
        </section>
    );
};

export default History;
