import Extent from '@arcgis/core/geometry/Extent';
import WMSLayer from '@arcgis/core/layers/WMSLayer';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';

import { NotificationInstance } from 'antd/lib/notification/interface';
import { TFunction } from 'i18next';

import { addLayerSettings, getSettings } from '../services/localStorageService';
import { BoundingBoxType, LayerItemType } from '../types';

export const getOpacity = (
    item: LayerItemType,
    map: ArcGISMap | undefined,
): number => {
    if (!map) return 100;

    const settings = getSettings();
    const layerSettings = settings?.layer_map?.items_selected?.find(
        (layer) => layer.layer === item.layer,
    );

    const opacityLayer = map.layers?.find(
        (layer) => layer.id === item.layer,
    )?.opacity;

    if (layerSettings?.opacity != undefined) {
        setOpacity(item, layerSettings?.opacity, map);
    }
    const opacity = (opacityLayer ?? 1) * 100;
    return layerSettings?.opacity ?? opacity;
};

export const setOpacity = (
    item: LayerItemType,
    value: number,
    map: ArcGISMap | undefined,
) => {
    if (!map) return;
    const layer = map.layers?.find((layer) => layer.id === item.layer);
    if (layer) {
        layer.opacity = value / 100;
    }
    addLayerSettings({ ...item, opacity: value });
};

export const goToLayer = (
    view?: MapView | null,
    boundingBoxes?: BoundingBoxType[],
) => {
    const extent = boundingBoxes?.[0] ? getExtentLayer(boundingBoxes[0]) : null;

    if (extent && view) {
        view.goTo(extent, { duration: 2000, easing: 'ease-in-out' });
    }
};

export const getExtentLayer = (boundingBox: BoundingBoxType): Extent => {
    return new Extent({
        xmin: boundingBox.minx,
        ymin: boundingBox.miny,
        xmax: boundingBox.maxx,
        ymax: boundingBox.maxy,
    });
};

export const noficationStatusLoadLayer = (
    layer: WMSLayer,
    notificationInstance: NotificationInstance,
    t: TFunction<'translation', undefined>,
) => {
    const loadStatusHandler = layer.watch('loadStatus', function (loadStatus) {
        switch (loadStatus) {
            case 'loading':
                notificationInstance.info({
                    message: t('layerWidget.notifications.loadingLayer.title'),
                    description: t(
                        'layerWidget.notifications.loadingLayer.description',
                    ),
                });
                break;
            case 'loaded':
                notificationInstance.success({
                    message: t('layerWidget.notifications.loadedLayer.title'),
                    description: t(
                        'layerWidget.notifications.loadedLayer.description',
                    ),
                });
                loadStatusHandler.remove();
                break;
            case 'failed':
                notificationInstance.error({
                    message: t('layerWidget.notifications.failedLayer.title'),
                    description: t(
                        'layerWidget.notifications.failedLayer.description',
                    ),
                });
                loadStatusHandler.remove();
                break;
            default:
                break;
        }
    });
};
