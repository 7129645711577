import React, { useEffect, useState } from 'react';

import WMSLayer from '@arcgis/core/layers/WMSLayer';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';

import {
    AimOutlined,
    ClearOutlined,
    DeleteFilled,
    InfoCircleFilled,
} from '@ant-design/icons';
import { Button, Col, Divider, Image, List, Row, Slider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import {
    addLayerSettings,
    getSettings,
    removeAllLayerSettings,
} from '../../../services';
import { LayerItemType } from '../../../types';
import { getOpacity, goToLayer, setOpacity } from '../../../utils/WebMapUtils';
import LayerMetadataComponent from '../components/LayerMetadataComponent';

interface IProps {
    map?: ArcGISMap;
    view?: MapView | null;
}

const SubtitleWidget = ({ map, view }: IProps) => {
    const { t } = useTranslation();
    const [layers, setLayers] = useState<LayerItemType[]>([]);
    const [baseMapName, setBaseMapName] = useState<string>();
    const [selectedLayer, setSelectedLayer] = useState<LayerItemType>();
    const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);

    const removeLayer = (item: LayerItemType): void => {
        addLayerSettings(item);

        layers.splice(
            layers.findIndex(
                (layer) => layer.layer === item.layer && layer.url === item.url,
            ),
            1,
        );
        setLayers([...layers]);
        addLayerSettings({ ...item, checked: false });

        const layer = map?.layers?.find((layer) => layer.id === item.layer);
        if (!layer) return;
        layer.destroy();
    };

    const addLayer = (item: LayerItemType): void => {
        const existLayer = map?.layers?.find(
            (layer) => layer.id === item.layer,
        );

        if (!layers.some((layer) => layer.layer === item.layer)) {
            layers.push(item);
            setLayers([...layers]);
        }

        if (existLayer) return;

        const layer = new WMSLayer({
            url: item.url,
            id: item.layer,
            title: item.title,
            sublayers: [{ name: item.layer }],
        });
        map?.add(layer);
    };

    const handleClearAllLayers = () => {
        if (map) {
            setLayers([]);
            removeAllLayerSettings();
            map.layers.removeAll();
        }
    };

    const loadSettings = (): void => {
        setBaseMapName(map?.basemap.id);
        const settings = getSettings();
        const layersSettings = settings?.layer_map?.items_selected;
        if (layersSettings) {
            layersSettings.forEach((layerSettings) => {
                if (layerSettings.checked === true) {
                    addLayer(layerSettings);
                }
            });
        }
    };

    const handleInfoModal = (item: LayerItemType) => {
        setOpenInfoModal(!openInfoModal);
        setSelectedLayer(item);
    };

    useEffect(() => {
        if (map) {
            loadSettings();
        }
    }, [map]);

    return (
        <section
            id="layerWidget"
            className="scroll"
            style={{
                overflowY: 'auto',
                height: 'calc(100vh - 139px)',
            }}
        >
            <Col span={24}>
                <Row style={{ padding: '24px' }}>
                    <Col span={24}>
                        <span>{t('subtitleWidget.currentBaseMap')}</span>
                    </Col>
                    <Col span={24}>
                        <h2>
                            {baseMapName && t(`baseMapWidget.${baseMapName}`)}
                        </h2>
                    </Col>
                </Row>
                <Divider style={{ padding: 0, margin: 0 }} />
                <Row
                    style={{
                        paddingTop: '24px',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                    }}
                >
                    <Col span={22}>
                        <span>{t('subtitleWidget.activeLayers')}</span>
                    </Col>
                    <Col span={1}>
                        <Tooltip title={t('subtitleWidget.clearAllLayers')}>
                            <Button
                                type="text"
                                icon={<ClearOutlined />}
                                onClick={handleClearAllLayers}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <br />
                <List
                    dataSource={layers}
                    style={{ paddingLeft: '24px', paddingRight: '24px' }}
                    renderItem={(item) => (
                        <div
                            style={{
                                padding: '5px',
                                border: '2px solid #3a3a3a ',
                                marginBottom: '10px',
                            }}
                        >
                            <List.Item
                                key={item.layer + item.url}
                                style={{ marginBottom: '5px' }}
                            >
                                <List.Item.Meta
                                    title={item.title}
                                    description={item.description}
                                />

                                <Button
                                    type="text"
                                    icon={<AimOutlined />}
                                    onClick={() =>
                                        goToLayer(view, item.boundingBoxes)
                                    }
                                />
                                <Button
                                    type="text"
                                    icon={<InfoCircleFilled />}
                                    onClick={() => handleInfoModal(item)}
                                />
                                <Button
                                    type="text"
                                    icon={<DeleteFilled />}
                                    onClick={() => removeLayer(item)}
                                />
                            </List.Item>

                            <div style={{ padding: '8px' }}>
                                <span style={{ marginLeft: '8px' }}>
                                    {t('layerWidget.opacity')}
                                </span>
                                <Slider
                                    defaultValue={getOpacity(item, map)}
                                    onChange={(value) =>
                                        setOpacity(item, value, map)
                                    }
                                />
                                {item.style[0] && (
                                    <>
                                        <span style={{ marginLeft: '8px' }}>
                                            Legenda
                                        </span>
                                        <br />
                                        <div style={{ textAlign: 'center' }}>
                                            <Tooltip
                                                title={item.style[0]?.name}
                                            >
                                                <Image
                                                    style={{
                                                        paddingTop: '8px',
                                                        width: '100%',
                                                        height: '150px',
                                                        display: 'block',
                                                        margin: 'auto',
                                                    }}
                                                    alt={item.style[0]?.name}
                                                    src={
                                                        item.style[0]?.legendURL
                                                            .url
                                                    }
                                                />
                                            </Tooltip>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                />
            </Col>
            {selectedLayer && (
                <LayerMetadataComponent
                    layer={selectedLayer}
                    open={openInfoModal}
                    cancel={() => setOpenInfoModal(false)}
                />
            )}
        </section>
    );
};

export default SubtitleWidget;
