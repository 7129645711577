import { createSlice } from '@reduxjs/toolkit';

interface InitialInterface {
    version: string | null;
}

const initialState: InitialInterface = {
    version: null,
};

const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        changeProjectVersion: (state, action) => {
            state.version = action.payload.version;
        },
    },
});

export const { changeProjectVersion } = projectSlice.actions;

export default projectSlice.reducer;
