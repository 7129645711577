import { createSlice } from '@reduxjs/toolkit';

import { userLocation } from './types';

interface InitialInterface {
    id: number | null;
    username: string | null;
    userRoleName: string | null;
    userLocation: userLocation | null;
}

const initialState: InitialInterface = {
    id: null,
    username: null,
    userRoleName: null,
    userLocation: null,
};

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.id = action.payload.id;
            state.username = action.payload.username;
            state.userRoleName = action.payload.userRoleName;
            state.userLocation = action.payload.userLocation;
        },
    },
});

export const { addUser } = userSlice.actions;

export default userSlice.reducer;
