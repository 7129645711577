import { FormInstance } from 'antd';

import { ErrorType } from '../types';

type onErrorType = (_: { name: string; message: string }) => void;

export const getErrorField = (
    data: ErrorType,
    form?: FormInstance,
    onError?: onErrorType,
) => {
    const fieldsToUpdate =
        data?.errors?.map((error) => {
            const name = error.name;
            const message = error.message;

            if (onError) {
                onError({
                    name: name,
                    message: message,
                });
            }

            return {
                name: name,
                errors: [message],
            };
        }) || [];

    form?.setFields(fieldsToUpdate);
};
