export default {
    translation: {
        panelComponent: {
            completedMissions: {
                title: 'Misiones Completadas',
                newAreaButton: '+ Nueva Área',
                all: 'Todos',
                lastMonth: 'Mes Pasado',
                lastThreeMonths: 'Últimos 3 Meses',
                lastSixMonths: 'Últimos 6 Meses',
                noDataMessage:
                    'No tienes suficientes datos para mostrar en este panel.',
                occurrences: {
                    title: 'Número de Ocurrencias',
                    doubt: 'Número de anomalías encontradas durante las misiones.',
                },
                completedQuantity: {
                    title: 'Cantidad Completada',
                    doubt: 'Cantidad de misiones completadas durante todo el período.',
                },
                canceledQuantity: {
                    title: 'Cantidad Cancelada',
                    doubt: 'Cantidad de misiones canceladas durante todo el período.',
                },
                averageTime: {
                    title: 'Tiempo Promedio',
                    doubt: 'Duración total del tiempo de vuelo de las misiones realizadas durante todo el período.',
                },
            },
            waitingAreas: {
                title: 'Áreas Esperando Autorización de Vuelo',
                viewAllButton: 'Ver Todas',
                noAreasMessage:
                    'No tienes áreas esperando autorización de vuelo. Intenta crear una nueva área de interés.',
            },
            upcomingMissions: {
                title: 'Próximas Misiones',
                viewAllButton: 'Ver Todas',
                noMissionsMessage:
                    'No tienes misiones programadas para realizar. Espera a que se otorgue la aprobación de vuelo a un área de interés.',
            },
        },
        historyComponent: {
            title: 'Historial de Áreas de Interés',
            all: 'Todos',
            waiting: 'Esperando Autorización',
            approved: 'Aprobadas para Vuelo',
            noMissionMessage:
                'No tienes ninguna área de interés esperando autorización de vuelo.',
            filter: {
                missionName: 'Nombre de la Misión',
                date: 'Fecha',
                payloadType: 'Tipo de Misión',
                ordering: 'Ordenando',
                status: 'Estado',
            },
            header: {
                fileName: 'Nombre del Archivo',
                missionType: 'Tipo de Misión',
                reportType: 'Tipo de Informe',
                date: 'Fecha',
                status: 'Estado',
                actions: 'Acciones',
            },
            actions: {
                viewDetails: 'Ver Detalles',
                changeStatus: 'Cambiar Estado',
                downloadFile: 'Descargar Archivo',
            },
            changeStatusModal: {
                title: 'Cambiar Estado',
                description:
                    'Para completar el cambio, completa el formulario a continuación',
                selectLabel: 'Cambiar a',
                save: 'Guardar',
                cancel: 'No guardar',
            },
            missionMessage: {
                all: 'En esta lista, tendrás el historial completo de las áreas de interés marcadas.',
                isMission:
                    'En esta lista, tendrás el historial completo de las áreas de interés marcadas que están',
                isMissionStrong: 'Aprobadas para Vuelo',
                isNotMission:
                    'En esta lista, tendrás el historial completo de las áreas de interés marcadas que están',
                isNotMissionStrong: 'Esperando Autorización de Vuelo',
            },
        },
        menuComponent: {
            menu: 'Menú',
            map: 'Mapa',
            notifications: 'Notificaciones',
            history: 'Historial',
            drones: 'Drones',
            reports: 'Informes',
            newArea: 'Nueva Área',
            functionNotAvailable: 'Función no disponible en esta versión!',
        },
        detailsComponent: {
            visualization: {
                title: 'Visualización:',
                type: 'Tipo de Visualización',
                mission: 'Misión',
                areaOfInterest: 'Área de Interés',
            },
            generalInformation: {
                title: 'Información General',
                type: 'Tipo',
                report: 'Informe',
                status: 'Estado de Liberación',
                statusMission: 'Estado de la Misión',
                dateTime: 'Fecha y Hora',
            },
            locationData: {
                title: 'Datos de Ubicación',
                city: 'Ciudad',
                country: 'País',
                totalArea: 'Área Total (m2)',
                totalDistance: 'Distancia Total (KM)',
            },
            droneData: {
                title: 'Sobre el Drone',
                noDataMessage: 'No hay datos disponibles en este momento.',
            },
        },
        areaComponent: {
            interestLocation: {
                title: 'Área de Interés',
                selectOption:
                    'Selecciona una opción a continuación para continuar.',
            },
            drawOnMap: {
                button: {
                    label: 'Dibujar en el Mapa',
                    icon: 'EditOutlined',
                    editLabel: 'Editar',
                },
                info: {
                    title: 'Dibujo del Área',
                    description:
                        'Marca en el mapa el área donde se realizará la misión.',
                },
            },
            savedLocations: {
                button: 'Ubicaciones Guardadas',
                info: 'Ubicaciones Guardadas',
                deleteNotification: '¡Ubicación eliminada correctamente!',
                delete: 'Eliminar Ubicación',
                loadMore: 'Cargar Más',
            },
            importFile: {
                button: 'Importar Archivo',
                info: {
                    title: 'Archivo',
                    label: 'Selecciona un Archivo (.KML)',
                },
            },
            continueButtons: {
                cancel: 'Cancelar',
                continue: 'Continuar',
            },
            saveLocationModal: {
                title: 'Guardar Ubicación',
                content:
                    'Al guardar esta ubicación, estará disponible para su selección en futuras misiones.',
                nameLabel: 'Nombre de la Ubicación',
                placeholder: 'Ingresa un nombre para la ubicación aquí',
                cancel: 'No guardar',
                save: 'Guardar',
            },
        },
        paramsComponent: {
            aboutInterestArea: {
                title: 'Sobre el Área de Interés',
                description:
                    'Completa los campos a continuación para continuar.',
            },
            form: {
                areaName: {
                    title: 'Nombre del Área',
                    placeholder: 'Nombre para el polígono dibujado',
                },
                details: {
                    title: 'Detalles (Opcional)',
                    placeholder:
                        'Proporciona más detalles sobre el área de interés.',
                },
            },
            nextSection: {
                back: 'Volver',
                next: 'Siguiente',
            },
        },
        configComponent: {
            aboutInterestArea: {
                title: 'Sobre el Área de Interés',
                description:
                    'Completa la información a continuación para finalizar',
            },
            droneportSection: {
                title: 'Droneport',
                selectLabel: 'Selecciona un Droneport',
            },
            dateSection: {
                date: 'Fecha',
                time: 'Hora',
            },
            missionSection: {
                title: 'Selecciona el Tipo de Misión',
            },
            reportsSection: {
                title: 'Selecciona el Tipo de Informe',
            },
            nextSection: {
                back: 'Volver',
                finish: 'Finalizar',
            },
        },
        createAreaComponent: {
            title: 'Nueva Área de Interés',
            steps: {
                generalInformation: 'Información General',
                areaLocation: 'Ubicación del Área',
                settings: 'Configuraciones',
            },
        },
        baseMapWidget: {
            title: 'Mapa Base Actual',
            hybrid: 'Híbrido de Imágenes',
            streets: 'Calles',
            satellite: 'Satélite',
            topo: 'Topográfico',
        },
        measurementWidget: {
            title: 'Opciones de Medición',
            measureDistance: 'Medir Distancia',
            measureArea: 'Medir Área',
            unitSelection: {
                title: 'Unidad de Medida',
                placeholder: 'Seleccion a la unidad de medida',
            },
            clear: 'Limpiar',
            unitsDistance: {
                metric: 'Métrico',
                imperial: 'Imperial',
                inches: 'Pulgadas',
                feet: 'Pies',
                yards: 'Yardas',
                miles: 'Millas',
                nauticalMiles: 'Millas Náuticas',
                usFeet: 'Pies (EE. UU.)',
                meters: 'Metros',
                kilometers: 'Kilómetros',
            },
            unitsArea: {
                metric: 'Métrico',
                imperial: 'Imperial',
                squareInches: 'Pulgadas Cuadradas',
                squareFeet: 'Pies Cuadrados',
                squareYards: 'Yardas Cuadradas',
                squareMiles: 'Millas Cuadradas',
                squareUsFeet: 'Pies Cuadrados (EE. UU.)',
                squareMeters: 'Metros Cuadrados',
                squareKilometers: 'Kilómetros Cuadrados',
                acres: 'Acres',
                ares: 'Ares',
                hectares: 'Hectáreas',
            },
        },
        boxesComponent: {
            label: 'en el período seleccionado',
            noDataMessage: 'No hay datos en el período seleccionado',
            oneDataMessage: 'Un dato en el período seleccionado',
        },
        notFoundComponent: {
            message: '¡Página no encontrada!',
            return: 'Volver a',
            home: 'Inicio',
        },
        viewComponent: {
            type: 'Tipo',
            status: 'Estado',
            report: 'Informe',
            datetime: 'Fecha y Hora',
            button: 'Seguir Estado',
        },
        webMapComponent: {
            baseMap: 'Mapa Base',
            measurement: 'Medición',
            draw: 'Dibujo',
            layer: 'Capas',
            subtitle: 'Subtítulo',
            geo3DTiles: 'Capas 3D',
        },
        enums: {
            mapping: 'Mapeo',
            surveillance: 'Vigilancia',
            environmental: 'Ambiental',
            rgbThermal: 'RGB o Térmico',
            multispectral: 'Multiespectral',
            none: 'Ninguno',
        },
        status: {
            approved: 'Aprobado',
            waiting: 'Esperando',
            disapproved: 'Desaprobado',
            accomplished: 'Realizado',
            accomplish: 'A Realizar',
            inProgress: 'En Progreso',
            canceled: 'Cancelado',
            none: 'Ninguno',
        },
        sortOrder: {
            asc: 'Creciente',
            desc: 'Decreciente',
        },
        user: {
            logout: 'Cerrar Sesión',
        },
        drawWidget: {
            drawOnMap: {
                title: 'Dibujar en el Mapa',
                label: 'Dibujar en el Mapa',
                editLabel: 'Editar',
                clear: 'Limpiar',
            },
            saveArea: {
                title: 'Guardar Área',
                description:
                    'Al guardar esta área, estará disponible para su selección en futuras misiones.',
                placeholder: 'Ingresa un nombre para el área aquí',
                save: 'Guardar',
                notification: 'Área guardada con éxito',
            },
            savedArea: {
                title: 'Áreas Guardadas',
                deleteNotification: '¡Área eliminada correctamente!',
                delete: 'Eliminar Área',
                loadMore: 'Cargar Más',
                error: {
                    editing:
                        'No se puede guardar el área mientras está en modo de edición. Presione Enter para salir del modo de edición e intente nuevamente.',
                },
            },
        },
        layerWidget: {
            title: 'Capas Disponibles',
            opacity: 'Opacidad',
            search: 'Buscar',
            selectInstitution: 'Select an institution',
            infoModal: {
                title: 'Información de la Capa',
                name: 'Nombre',
                description: 'Descripción',
                layers: 'Capas',
                keywords: 'Palabras Clave',
                lengend: 'Leyenda',
            },
            favorite: 'Favorito',
            allFavorites: 'Todos los Favoritos',
            style: 'Estilo',
            dimension: 'Dimensión',
            notifications: {
                loadingLayer: {
                    title: 'La capa se está cargando',
                    description: 'Por favor, espere...',
                },
                loadedLayer: {
                    title: 'Capa cargada',
                    description: '¡La capa se ha cargado con éxito!',
                },
                failedLayer: {
                    title: 'Error al cargar la capa',
                    description: 'No se pudo cargar la capa.',
                },
                deleteLayer: {
                    title: 'Capa eliminada',
                    description: '¡La capa se ha eliminado con éxito!',
                },
                failedLoadGeoServer: {
                    title: 'GeoServer no disponible',
                    description: 'No se pudo cargar el servicio de GeoServer.',
                },
            },
        },
        geo3DTilesWidget: {
            loadMore: 'Cargar Más',
            remove: 'Eliminar',
            notFound: 'Capa 3D no encontrada',
        },
        pdf: {
            title: 'Vista Previa del Informe',
        },
        zoomWidget: {
            zoomIn: 'Zoom in',
            zoomOut: 'Zoom out',
            currentLocation: 'Go to current location',
            extent: 'Restore full extent',
            home: 'Focus on active layer',
            error: {
                noGraphicsOrActiveLayer:
                    'No se encontraron capas activas o gráficos.',
                currentLocation: 'No se pudo obtener la ubicación actual.',
            },
        },
        subtitleWidget: {
            activeLayers: 'Capas Activas',
            currentBaseMap: 'Mapa Base Actual',
            clearAllLayers: 'Borrar todas las capas',
        },
        generic: {
            form: {
                required: 'Campo obligatorio',
            },
        },
    },
};
