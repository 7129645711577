import React from 'react';

import { Descriptions, Image, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { LayerItemType } from '../../../types';

interface IProps {
    layer: LayerItemType;
    open: boolean;
    cancel: () => void;
}

const LayerMetadataComponent = ({ layer, open, cancel }: IProps) => {
    const { t } = useTranslation();
    return (
        <Modal
            title={t('layerWidget.infoModal.title')}
            open={open && layer !== null && layer !== undefined}
            footer={null}
            onCancel={cancel}
        >
            <p>{layer.title}</p>
            <br />
            <Descriptions bordered column={1}>
                <Descriptions.Item label={t('layerWidget.infoModal.name')}>
                    {layer.title}
                </Descriptions.Item>
                <Descriptions.Item
                    label={t('layerWidget.infoModal.description')}
                    span={2}
                >
                    {layer.description}
                </Descriptions.Item>
                <Descriptions.Item label={t('layerWidget.infoModal.layers')}>
                    {layer.layer}
                </Descriptions.Item>
                <Descriptions.Item label={t('layerWidget.infoModal.keywords')}>
                    {layer.keywords.join(', ')}
                </Descriptions.Item>
                <Descriptions.Item label={t('layerWidget.infoModal.lengend')}>
                    {layer.style.map((style) => (
                        <div style={{ textAlign: 'center' }} key={style.name}>
                            <p>{style.name}</p>
                            <Tooltip title={style.name}>
                                <Image
                                    style={{
                                        paddingTop: '8px',
                                        width: '100%',
                                        height: '100px',
                                        display: 'block',
                                        margin: 'auto',
                                    }}
                                    alt={style.name}
                                    src={style.legendURL.url}
                                />
                            </Tooltip>
                        </div>
                    ))}
                </Descriptions.Item>
                <Descriptions.Item label="URL">
                    <a href={layer.url} target="_blank" rel="noreferrer">
                        {layer.url}
                    </a>
                </Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default LayerMetadataComponent;
