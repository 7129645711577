export const convertDate = (timestamp: string) => {
    const data = new Date(parseInt(timestamp));

    // Use métodos do objeto Date para obter partes específicas da data e hora
    const ano = data.getFullYear();
    const mes = data.getMonth() + 1; // Os meses são indexados de 0 a 11
    const dia = data.getDate();
    const horas = data.getHours();
    const minutos = data.getMinutes();

    const mesString = mes < 10 ? `0${mes}` : mes;

    return `${dia}/${mesString}/${ano} • ${horas}:${minutos}`;
};

export function convertDateFromString(dateTime: string) {
    const [datePart, timePart] = dateTime.split(' ');
    const [day, month, year] = datePart.split('-');
    const newDate = `${day}/${month}/${year}`;
    const [hours, minutes] = timePart.split(':');
    return `${newDate} • ${hours}:${minutes}`;
}

export function convertDateFromISO8601(dateTime: string) {
    const [datePart] = dateTime.split('T');
    const [year, month, day] = datePart.split('-');
    const newDate = `${day}/${month}/${year}`;
    return `${newDate}`;
}
