import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './Boxes.scss';

interface BoxesProps {
    title: string;
    doubt: string;
    quantity: number;
    diff: number | null;
    buttonType: boolean;
}

const Boxes = (props: BoxesProps) => {
    const { t } = useTranslation();

    const getMenssageDiff = (diff: number) => {
        if (diff === 0) return t('boxesComponent.noDataMessage');

        if (diff == 1) return t('boxesComponent.oneDataMessage');

        const limits = [
            { max: 5, label: '+1' },
            { max: 10, label: '+5' },
            { max: 15, label: '+10' },
            { max: 20, label: '+15' },
            { max: Infinity, label: '+20' },
        ];

        for (const limit of limits) {
            if (diff <= limit.max) {
                return `${limit.label} ${t('boxesComponent.label')}`;
            }
        }
    };

    return (
        <div id="boxesComponent">
            <header>
                <h5>{props.title}</h5>
                <div id="boxesComponent__Doubts">
                    <QuestionCircleOutlined />
                    <div>{props.doubt}</div>
                </div>
            </header>

            <br className="small-space" />

            <main>
                <h1>
                    {props.quantity > 10
                        ? props.quantity
                        : `0${props.quantity}`}
                </h1>

                <br className="small-space" />

                {props.diff != null && (
                    <button
                        className={`fake-input boxesButton ${
                            props.buttonType ? 'greenButton' : 'redButton'
                        }`}
                    >
                        {getMenssageDiff(props.diff)}
                    </button>
                )}
            </main>
        </div>
    );
};

export default Boxes;
