import React from 'react';

import { store } from './config/redux/store';
import { Provider } from 'react-redux';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import CreateArea from './components/CreateAreaComponent/CreateAreaComponent';
import DetailsComponent from './components/DetailsComponent/DetailsComponent';
import History from './components/HistoryComponent/HistoryComponent';
import Menu from './components/MenuComponent/Menu';
import NotFound from './components/NotFoundComponent/NotFound';
import Panel from './components/PanelComponent/PanelComponent';
import WebMap from './components/WebMapComponent/WebMapComponent';
import "./App.scss";

const App = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <Menu />
                    <br className="header-space" />

                    <section id="bodyComponent">
                        <div className="container">
                            <Routes>
                                <Route path="/" element={<Panel />} />

                                <Route
                                    path="/create-area"
                                    element={<CreateArea />}
                                />

                                <Route path="/history" element={<History />} />

                                <Route
                                    path="/details/:id"
                                    element={<DetailsComponent />}
                                />

                                <Route
                                    path="/not-found"
                                    element={<NotFound />}
                                />

                                <Route path="/map" element={<WebMap />} />

                                <Route
                                    path="*"
                                    element={<Navigate to="/not-found" />}
                                />
                            </Routes>
                        </div>
                    </section>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );
};

export default App;
