import React, { useEffect, useState } from 'react';

import { RootState } from '../../../config/redux/store';
import { useDispatch, useSelector } from 'react-redux';

import {
    CaretDownOutlined,
    DeleteFilled,
    EditOutlined,
    ImportOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    FormProps,
    Input,
    List,
    Modal,
    notification,
    Row,
    Spin,
    Tooltip,
    Typography,
    Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './AreaComponent.scss';

import {
    increaseStage,
    changeOption,
    changeDrawing,
    changeUpdating,
    resetDraw,
    addArea,
} from '../../../config/redux/area/slice';
import {
    createSavedArea,
    deleteSavedArea,
    getAllSavedArea,
} from '../../../services/';
import { uploadKML } from '../../../services/kmlService';
import {
    AreaType,
    PageType,
    PointFormType,
    SavedAreaType,
} from '../../../types';
import { getErrorField } from '../../../utils/ErrorField';

type FieldType = {
    name: string;
};

const AreaComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [form] = Form.useForm<FieldType>();

    const [notificationInstance, contextHolder] =
        notification.useNotification();

    const [savedAreas, setSavedAreas] = useState<PageType<SavedAreaType>>();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const { area_option, area_geojson } = useSelector(
        (state: RootState) => state.area,
    );

    const handleNextStage = () => {
        dispatch(
            increaseStage({
                area_stage: 1,
            }),
        );
    };

    const handleCancel = () => {
        dispatch(
            changeOption({
                area_option: 0,
            }),
        );

        dispatch(resetDraw());
        navigate('/');
    };

    const handleChangeOption = (option: number, toDraw?: boolean) => {
        dispatch(
            changeOption({
                area_option: option,
            }),
        );

        if (toDraw) handleDrawingState(toDraw);
        else dispatch(resetDraw());
    };

    const handleDrawingState = (boolean: boolean) => {
        dispatch(
            changeDrawing({
                area_drawing: boolean,
            }),
        );
    };

    const handleUpdatingState = (boolean: boolean) => {
        dispatch(
            changeUpdating({
                area_updating: boolean,
            }),
        );
    };

    const handleSaveArea: FormProps<FieldType>['onFinish'] = (values) => {
        setConfirmLoading(true);
        createSavedArea({
            name: values.name,
            type: 'NO_GIS',
            points:
                area_geojson?.map((point) => {
                    return {
                        x: Number(point[0]),
                        y: Number(point[1]),
                    } as PointFormType;
                }) ?? [],
        })
            .then(() => {
                form.resetFields();
                handleNextStage();
                setOpen(false);
            })
            .catch(({ response }) => getErrorField(response.data, form))
            .finally(() => {
                setConfirmLoading(false);
            });
    };

    const handleCancelSaveArea = () => {
        setOpen(false);
        handleNextStage();
    };

    const handleUploadKML = (file: any) => {
        uploadKML(file)
            .then((result) => {
                dispatch(
                    addArea({
                        area_coordinates: result,
                    }),
                );
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const showSaveAreaModal = () => {
        setOpen(true);
    };

    const openSavedArea = (area: AreaType) => {
        dispatch(
            addArea({
                area_coordinates: area.coordinates,
            }),
        );
    };

    const loadData = () => {
        getAllSavedArea({
            type: 'NO_GIS',
            size: 3,
        }).then(setSavedAreas);
    };

    const deleteArea = (id: number) => {
        deleteSavedArea(id).then(() => {
            notificationInstance.success({
                message: t('areaComponent.savedLocations.deleteNotification'),
            });
            loadData();
        });
    };

    const loadMore = () => {
        if (!savedAreas) return;
        if (confirmLoading) return;
        setConfirmLoading(true);
        getAllSavedArea({
            type: 'NO_GIS',
            page: savedAreas?.number + 1,
            size: 3,
        })
            .then((response) => {
                setSavedAreas((prevState) => {
                    return {
                        ...response,
                        page: savedAreas?.number + 1,
                        content: [
                            ...(prevState?.content ?? []),
                            ...response.content,
                        ],
                    };
                });
            })
            .finally(() => setConfirmLoading(false));
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <section id="areaComponent">
            {contextHolder}
            <h5>{t('areaComponent.interestLocation.title')}</h5>
            <p>{t('areaComponent.interestLocation.selectOption')}</p>

            <br className="space" />

            <button
                onClick={() => {
                    handleChangeOption(1, true);
                }}
                className="input text-align-left"
            >
                <EditOutlined /> {t('areaComponent.drawOnMap.button.label')}
                {area_geojson && (
                    <a
                        onClick={() => {
                            handleUpdatingState(true);
                        }}
                        className="inline-button"
                    >
                        {t('areaComponent.drawOnMap.button.editLabel')}
                    </a>
                )}
            </button>

            <br className="small-space" />

            <button
                onClick={() => {
                    handleChangeOption(2, false);
                }}
                className="input text-align-left"
            >
                <SaveOutlined /> {t('areaComponent.savedLocations.button')}
            </button>

            <br className="small-space" />

            <button
                onClick={() => {
                    handleChangeOption(3, false);
                }}
                className="input text-align-left"
            >
                <ImportOutlined /> {t('areaComponent.importFile.button')}
            </button>

            <br className="space" />

            {area_option == 1 && (
                <div id="areaComponent__Drawing">
                    <h5>{t('areaComponent.drawOnMap.info.title')}</h5>
                    <p>{t('areaComponent.drawOnMap.info.description')}</p>
                </div>
            )}

            {area_option == 2 && (
                <div id="areaComponent__Choosing">
                    <h5>{t('areaComponent.savedLocations.info')}</h5>
                    <div id="areaComponent__Saved" className="scroll">
                        <List
                            dataSource={savedAreas?.content}
                            grid={{ gutter: 16, column: 1 }}
                            loading={confirmLoading}
                            loadMore={
                                !savedAreas?.last && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            marginTop: 12,
                                            height: 32,
                                            lineHeight: '32px',
                                        }}
                                    >
                                        {confirmLoading ? (
                                            <Spin />
                                        ) : (
                                            <Button onClick={loadMore}>
                                                {t(
                                                    'areaComponent.savedLocations.loadMore',
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                )
                            }
                            renderItem={(item) => (
                                <List.Item>
                                    <Button
                                        className="input"
                                        onClick={() => openSavedArea(item.area)}
                                    >
                                        <Row>
                                            <Col span={22}>
                                                <span>{item.name}</span>
                                            </Col>
                                            <Col span={2}>
                                                <Tooltip
                                                    title={t(
                                                        'areaComponent.savedLocations.delete',
                                                    )}
                                                >
                                                    <Button
                                                        type="text"
                                                        icon={<DeleteFilled />}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            deleteArea(item.id);
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Button>
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            )}

            {area_option == 3 && (
                <div id="areaComponent__Uploading">
                    <h5>{t('areaComponent.importFile.info.title')}</h5>
                    <label htmlFor="file" className="fake-input">
                        {t('areaComponent.importFile.info.label')}
                        <CaretDownOutlined />
                    </label>
                    <Upload
                        style={{ width: '100%' }}
                        id="kml-upload"
                        beforeUpload={() => false}
                        onChange={({ file }) => {
                            handleUploadKML(file);
                        }}
                        showUploadList={false}
                        accept=".kml"
                    >
                        <input
                            id="file"
                            type="button"
                            placeholder={t(
                                'areaComponent.importFile.info.label',
                            )}
                            name="file"
                        />
                    </Upload>
                </div>
            )}

            <br className="space" />

            <div className="next_section">
                <Button className="button" onClick={() => handleCancel()}>
                    {t('areaComponent.continueButtons.cancel')}
                </Button>
                <Button
                    className="button-green"
                    onClick={() => area_geojson && showSaveAreaModal()}
                >
                    {t('areaComponent.continueButtons.continue')}
                </Button>
            </div>
            <Modal
                title={t('areaComponent.saveLocationModal.title')}
                open={open}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancelSaveArea}
                cancelText={t('areaComponent.saveLocationModal.cancel')}
                okText={t('areaComponent.saveLocationModal.save')}
                maskClosable={false}
            >
                <>
                    <p>{t('areaComponent.saveLocationModal.content')}</p>
                    <Typography.Title level={5}>
                        {t('areaComponent.saveLocationModal.nameLabel')}
                    </Typography.Title>
                    <Form name="basic" onFinish={handleSaveArea} form={form}>
                        <Form.Item<FieldType>
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t('generic.form.required'),
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                className="input"
                                placeholder={t(
                                    'paramsComponent.form.areaName.placeholder',
                                )}
                            />
                        </Form.Item>
                    </Form>
                </>
            </Modal>
        </section>
    );
};

export default AreaComponent;
