import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import Keycloak from 'keycloak-js';

declare global {
    interface Window {
        keycloak: Keycloak;
    }
}

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        // Customize the root error boundary for your microfrontend here.
        return <></>;
    },
});

export const { bootstrap, mount, unmount } = lifecycles;
