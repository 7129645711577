import React, { useState } from 'react';

import Point from '@arcgis/core/geometry/Point';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';

import {
    DownOutlined,
    LeftOutlined,
    RightOutlined,
    UpOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

interface IProps {
    view: MapView | SceneView | null;
}

const NavigationButtonWidget = ({ view }: IProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    function handleUp() {
        moveTo('up');
    }

    function handleLeft() {
        moveTo('left');
    }

    function handleRight() {
        moveTo('right');
    }

    function handleDown() {
        moveTo('down');
    }

    const calculateMoviment = (currentZoom: number): number => {
        return currentZoom / 10;
    };

    const moveTo = async (direction: 'up' | 'down' | 'left' | 'right') => {
        if (!view) return;

        setLoading(true);
        const { center, spatialReference } = view;
        const distance = calculateMoviment(view.scale); // Distância padrão para mover o mapa em metros ou outras unidades

        let newCenter: Point;

        switch (direction) {
            case 'up':
                newCenter = new Point({
                    x: center.x,
                    y: center.y + distance,
                    spatialReference,
                });
                break;
            case 'down':
                newCenter = new Point({
                    x: center.x,
                    y: center.y - distance,
                    spatialReference,
                });
                break;
            case 'left':
                newCenter = new Point({
                    x: center.x - distance,
                    y: center.y,
                    spatialReference,
                });
                break;
            case 'right':
                newCenter = new Point({
                    x: center.x + distance,
                    y: center.y,
                    spatialReference,
                });
                break;
            default:
                return;
        }

        try {
            await view.goTo({ target: newCenter, animate: false });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                position: 'fixed',
                top: 70,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '18px',
                zIndex: 999,
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    background: 'rgba(240, 240, 240, 0.3)',
                    padding: '8px',
                    borderRadius: '50%',
                }}
            >
                <Button
                    icon={<UpOutlined />}
                    size="middle"
                    style={{ marginBottom: '0px' }}
                    disabled={loading}
                    onClick={handleUp}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        icon={<LeftOutlined />}
                        size="middle"
                        style={{ marginRight: '30px' }}
                        disabled={loading}
                        onClick={handleLeft}
                    />
                    <Button
                        icon={<RightOutlined />}
                        size="middle"
                        disabled={loading}
                        onClick={handleRight}
                    />
                </div>
                <Button
                    icon={<DownOutlined />}
                    size="middle"
                    style={{ marginTop: '0px' }}
                    disabled={loading}
                    onClick={handleDown}
                />
            </div>
        </div>
    );
};

export default NavigationButtonWidget;
